/* global angular */

require('aem-libs-angularjs/src/directives/track-element.directive.js')
var helperService = require('aem-libs-angularjs/src/services/helper.service.js').helperService

angular.module('orderCmp', ['TrackElementCmp', 'ngSanitize'])
  .factory('helperService', helperService)
  .component('order', {
    template: require('./order.component.tpl.html'),
    controller: OrderCtrl,
    controllerAs: 'vm',
    scope: true
  })

require('./component.css')
require('./components/order-form/order-form.component.js')

OrderCtrl.$inject = ['appLoader', 'placesService', 'qmaticService', '$filter', '$q', '$window', '$timeout', '$route', '$routeParams', '$location', 'userTracking']
function OrderCtrl (appLoader, placesService, qmaticService, $filter, $q, $window, $timeout, $route, $routeParams, $location, userTracking) {
  var vm = this
  vm.goToForm = goToForm
  vm.back = back
  vm.getTimeRange = getTimeRange
  vm.selectInterval = selectInterval
  vm.unselectInterval = unselectInterval
  vm.selectTime = selectTime
  vm.nextDay = nextDay
  vm.prevDay = prevDay
  vm.branchId = $routeParams.id
  vm.showCalendar = !vm.showServices && !vm.showForm
  vm.showForm = false

  appLoader.show()

  $window.scrollTo(0, 0)

  var weekdays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

  vm.currentUrl = '#' + $location.$$path
  vm.showServices = !$routeParams.service
  vm.showCalendar = !vm.showServices

  if ($routeParams.service) {
    if ($routeParams.service === 'hotovost' || $routeParams.service === 'cash') {
      vm.serviceId = 0
    }

    if ($routeParams.service === 'bezhotovost' || $routeParams.service === 'cashless') {
      vm.serviceId = 1
    }
  }

  placesService.branchDetail(vm.branchId)
    .then(function (response) {
      vm.isErrorBackend = false
      vm.detail = response.data
      userTracking.onResponse(vm.detail)
      vm.detail = placesService.processBranchObject(vm.detail)
      vm.detailUrl = placesService.getDetailUrl(vm.detail, $filter('translate')('PLACES.URL.' + vm.detail.type.toUpperCase()))
      if (!vm.detail.qmatic) {
        vm.showError = true
      }
      if (placesService.isLocalStorageEnabled()) {
        window.localStorage.setItem('LAST_BRANCH', vm.detail.id)
      }
      if (angular.isDefined(vm.serviceId)) {
        getAvailabilities(0)
      }
    })
    .catch(function () {
      vm.isErrorBackend = true
    })
    .finally(function () {
      if (angular.isUndefined(vm.serviceId)) {
        vm.loaded = true
        appLoader.hide()
      }
    })

  angular.element($window).on('resize', function () {
    vm.width = $window.innerWidth
    var size = Math.floor(vm.width / 240)
    setTableClasses(size)
  })

  function setTableClasses (size) {
    if (size > 5) {
      size = 5
    }
    vm.daysLimit = size
    var tableElement = angular.element(document.getElementsByClassName('csas-reserve-table'))
    switch (size) {
      case 1:
        tableElement.addClass('csas-rt-xs')
        tableElement.removeClass('csas-rt-sm')
        tableElement.removeClass('csas-rt-md')
        tableElement.removeClass('csas-rt-lg')
        break
      case 2:
        tableElement.addClass('csas-rt-xs')
        tableElement.removeClass('csas-rt-sm')
        tableElement.removeClass('csas-rt-md')
        tableElement.removeClass('csas-rt-lg')
        break
      case 3:
        tableElement.addClass('csas-rt-sm')
        tableElement.removeClass('csas-rt-xs')
        tableElement.removeClass('csas-rt-md')
        tableElement.removeClass('csas-rt-lg')
        break
      case 4:
        tableElement.addClass('csas-rt-md')
        tableElement.removeClass('csas-rt-sm')
        tableElement.removeClass('csas-rt-xs')
        tableElement.removeClass('csas-rt-lg')
        break
      default:
        tableElement.addClass('csas-rt-lg')
        tableElement.removeClass('csas-rt-sm')
        tableElement.removeClass('csas-rt-md')
        tableElement.removeClass('csas-rt-xs')
    }

    angular.forEach(angular.element(document.getElementsByClassName('csas-reserve-day')), function (element, index) {
      angular.element(element).removeClass('csas-hide')
      if (index >= size) {
        angular.element(element).addClass('csas-hide')
      }

      var popupClass = ''
      if (size < 3) {
        popupClass = 'csas-rp-bottom'
      } else {
        if (index < (size - 1)) {
          popupClass = 'csas-rp-right'
        } else {
          popupClass = 'csas-rp-left'
        }
      }

      angular.forEach(angular.element(element).find('.csas-reservation-popup'), function (popup) {
        angular.element(popup).removeClass('csas-rp-bottom')
        angular.element(popup).removeClass('csas-rp-left')
        angular.element(popup).removeClass('csas-rp-right')
        angular.element(popup).addClass(popupClass)
      })
    })
  }

  function selectInterval (item, date) {
    if (vm.selected !== item) {
      vm.selectedDate = date
      vm.selected = item
    }
  }

  function unselectInterval ($event) {
    delete vm.selected
    $event.stopPropagation()
  }

  function selectTime (time, state, $event) {
    $event.stopPropagation()
    userTracking.onClick('selectTime')
    if (state === 'FREE_APPT_SLOTS') {
      goToForm(time)
    }
  }

  function nextDay () {
    if ((vm.availabilities[vm.availabilities.length - 1].index + 1) < 30 && !vm.loading) {
      getDayAvailability(vm.availabilities[vm.availabilities.length - 1].index + 1)
    }
  }

  function prevDay () {
    if ((vm.availabilities[0].index - 1) >= 0 && !vm.loading) {
      getDayAvailability(vm.availabilities[0].index - 1, true)
    }
  }

  function getDayAvailability (index, before) {
    var indexes = [index]
    vm.loading = true

    $q.all(indexes.map(function (index) {
      var day = new Date()
      day.setDate(day.getDate() + index)
      var dateString = $filter('date')(day, 'yyyy-MM-dd')
      return loadAvailability(day, dateString, index, 5)
    })).then(function (res) {
      var array = angular.copy(vm.availabilities)
      if (before) {
        array.pop()
        array.reverse()
        array.push(res[0])
        array.reverse()
      } else {
        array.push(res[0])
        array.reverse()
        array.pop()
        array.reverse()
      }
      vm.availabilities = angular.copy(array)
      vm.startIndex = vm.availabilities[0].index
      vm.loading = false
    })
  }

  function loadAvailability (day, dateString, index, times) {
    return qmaticService.availabilities(vm.branchId, vm.serviceId, dateString)
      .then(function (response) {
        var ranges = []
        ranges.push(getTimeRange('MORNING', 8, 10, response.data))
        ranges.push(getTimeRange('NOON', 10, 12, response.data))
        ranges.push(getTimeRange('AFTERNOON', 12, 16, response.data))
        ranges.push(getTimeRange('EVENING', 16, 20, response.data))
        return { data: ranges, date: day, index: index, weekday: weekdays[day.getDay()] }
      })
      .catch(function (error) {
        if (error.status === 500) {
          if (times > 0) {
            return loadAvailability(day, dateString, index, (times - 1))
          } else {
            return []
          }
        }
      })
  }

  function getAvailabilities (startIndex) {
    var indexes = []
    for (var i = startIndex; i < startIndex + 5; i++) {
      indexes.push(i)
    }

    $q.all(indexes.map(function (index) {
      var day = new Date()
      day.setDate(day.getDate() + index)
      var dateString = $filter('date')(day, 'yyyy-MM-dd')
      return loadAvailability(day, dateString, index, 5)
    })).then(function (res) {
      vm.availabilities = res
    }).finally(function () {
      vm.startIndex = vm.availabilities[0].index
      vm.loaded = true
      appLoader.hide()
      $timeout(function () {
        vm.width = $window.innerWidth
        vm.daysLimit = Math.floor(vm.width / 240)
        setTableClasses(vm.daysLimit)
      }, 10)
    })
  }

  function goToForm (time) {
    $window.scrollTo(0, 0)
    vm.showCalendar = false
    vm.showForm = true
    vm.selectedTime = time
  }

  function back () {
    if (vm.showForm) {
      $route.reload()
    }
    $window.scrollTo(0, 0)
  }

  vm.goToDetail = function () {
    userTracking.onClick('detailBranch', vm.showForm ? 'lead' : 'reservation')
  }

  function getTimeRange (title, start, end, list) {
    var result = {}
    var items = []
    var available = false
    angular.forEach(list, function (item) {
      if (angular.isDefined(item) && parseInt(item.time.split(':')[0]) >= start && parseInt(item.time.split(':')[0]) < end) {
        if (item.state === 'TIME_NOT_FEASIBLE') {
          item.title = $filter('translate')('PLACES.ORDER.STATE.NOT_FEASIBLE')
        } else if (item.state === 'FREE_APPT_SLOTS') {
          item.title = $filter('translate')('PLACES.ORDER.STATE.FREE')
          available = true
        }
        items.push(item)
      }
    })
    result.title = $filter('translate')('PLACES.ORDER.' + title)
    result.items = items
    result.availability = available
    if (!available) {
      result.title2 = $filter('translate')('PLACES.ORDER.FULL')
    } else {
      var endTime = parseInt(items[items.length - 1].time.split(':')[0]) + 1
      result.title2 = items.length === 0 ? start + ':00' + ' - ' + end + ':00' : items[0].time + ' - ' + endTime + ':00'
    }
    result.opened = false
    return result
  }
}
