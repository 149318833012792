var inlet = require('aem-libs/src/initInlet.js').initInlet(
  'AemBranches',
  /* global WEBPACK_INLET_VERSION */ WEBPACK_INLET_VERSION
)
var angular = require('angular')
require('angular-route')
require('angular-sanitize')
require('aem-libs-places-angularjs/src/components/list-item/list-item.component.js')
require('aem-libs-places-angularjs/src/components/places-list/places-list.component.js')
require('aem-libs-places-angularjs/src/components/places-detail/places-detail.component.js')
require('aem-libs-places-angularjs/src/components/order/order.component.js')
require('aem-libs-places-angularjs/src/components/order/cancel.component.js')
require('aem-libs-places-angularjs/src/components/places-search/places-search.component.js')
require('aem-libs-places-angularjs/src/components/last/last.component.js')
require('aem-libs-places-angularjs/src/components/map/map.component.js')
require('aem-libs-places-angularjs/src/components/gallery/gallery.component.js')
require('aem-libs-places-angularjs/src/components/marker-content/marker-content.component.js')
require('aem-libs-angularjs/src/components/backend-error/backend-error.component.js')
var dropdown = require('angular-ui-bootstrap/src/dropdown')
require('js-marker-clusterer')
require('aem-libs-angularjs/src/filters/currency-amount-formatter.filter.js')

var placesService = require('aem-libs-places-angularjs/src/services/places.service.js').placesService
var qmaticService = require('aem-libs-angularjs/src/services/qmatic.service.js').qmaticService
var helperService = require('aem-libs-angularjs/src/services/helper.service.js').helperService
var googleMapsService = require('aem-libs-places-angularjs/src/services/google-maps.service.js').googleMapsService
var appointmentsTrackingDataservice = require('aem-libs-places-angularjs/src/services/appointments-tracking.dataservice').appointmentsTrackingDataservice

angular.module('AemBranches', ['ngRoute', 'siteMapCmp', 'markerContentCmp',
  'listItemCmp', 'PlacesListCmp', 'PlacesDetailCmp', 'orderCmp', 'cancelCmp',
  'PlacesSearchCmp', 'mapCmp', 'lastCmp', 'galleryCmp', dropdown,
  'currencyAmountFormatterCmp', 'BackEndErrorComp'])
  .factory('placesService', placesService)
  .factory('qmaticService', qmaticService)
  .factory('helperService', helperService)
  .factory('googleMapsService', googleMapsService)
  .factory('appointmentsTrackingDataservice', appointmentsTrackingDataservice)

require('./inlet.css')
require('./route.config.js')
require('./inlet.controller.js')
require('./components/sitemap/sitemap.component.js')

require('aem-libs-angularjs/src/bootstrap').bootstrap({
  element: inlet.element,
  appName: 'AemBranches',
  mainCtrlName: 'aemBranchesCtrl',
  basePath: inlet.basePath,
  config: /* global WEBPACK_INLET_CONFIG */ WEBPACK_INLET_CONFIG,
  supportedLanguages: {
    cs: require('./i18n/cs.js').cs,
    en: require('./i18n/en.js').en
  },
  html: require('./inlet.html')
})
