/* global Blob */

var angular = require('angular')

placesService.$inject = ['$http', 'apiBase', 'i18n', '$window', 'qmaticService', 'wgService']

function placesService ($http, apiBase, i18n, $window, qmaticService, wgService) {
  var endpointPath = apiBase + 'v3/places'

  function processDistance (data) {
    if (data.distance !== undefined && !data.distance.distance) {
      var dist = data.distance
      if (data.distance > 1000) {
        dist = dist / 1000
        data.distance = { distance: dist.toFixed(2), mark: 'km' }
      } else {
        data.distance = { distance: dist, mark: 'm' }
      }
    }
    return data
  }

  function processServices (data) {
    var ATM_DEFAULT = ['ATM_ACCESSIBILITY', 'WITHDRAW_ATM', 'DEPOSIT_ATM',
      'FOR_BLIND_ATM', 'TRANSFER_ATM', 'ACTIVATION_CODE', 'BAR_CODE_READER_ATM',
      'QR_CODE_READER_ATM', 'CONTACTLESS_SYSTEM_PILSEN']
    var BRANCH_EQUIPMENTS = [1, 6, 7, 9, 12, 11, 17, 20, 13, 16, 23, 24, 25]
    var BRANCH_DEFAULT = ['BARRIER_FREE', 'ATM', 'CASH_DESK', 'EXCHANGE', 'EQUIPMENT_12',
      'EQUIPMENT_11', 'WEEKEND_OFFICE', 'EQUIPMENT_17', 'HEARING_IMPAIR',
      'NEVIDOMI', 'EQUIPMENT_6', 'EQUIPMENT_1', 'EQUIPMENT_7', 'EQUIPMENT_9', 'AJ']
    var ENCS_DEFAULT = ['BARRIER_FREE', 'BARRIER_PARTIALY_FREE']
    var ENCS_EQUIPMENTS = [7, 9, 18]

    var services = []
    angular.forEach(data.services, function (item) {
      if ((item.type === 'BRANCH_SERVICE' || item.type === 'ATM_SERVICE' || item.type === 'ATM_TYPE') &&
          (item.flag !== 'INVOKER' && item.flag !== 'ORDER_TO_BRANCH' && item.flag !== 'SELF_SERVICE')) {
        services.push(item)
      } else if (item.flag === 'ORDER_TO_BRANCH') {
        if (data.state === 'OPEN') {
          data.qmatic = true
          qmaticService.services(data.id, i18n.langCode).then(function (response) {
            data.qmaticServices = []
            angular.forEach(response.data, function (item) {
              if (item.qmatic === true) {
                data.qmaticServices.push(item)
                if (item.id === 0) {
                  data.hotovost = true
                }
                if (item.id === 1) {
                  data.bezhotovost = true
                }
              }
            })
          }, function (error) {
            if (error.status === 500) {
              console.error('Error in queuing - ' + error.statusText)
              data.qmatic = false
            }
          })
        }
      }
    })

    if (wgService.getAppName() === 'AemEncsBranches') {
      var filteredServices = []
      angular.forEach(services, function (service) {
        if (ENCS_DEFAULT.indexOf(service.flag) !== -1) {
          filteredServices.push(service)
        }
      })
      services = filteredServices

      angular.forEach(data.equipment, function (item) {
        var temp = item
        if (ENCS_EQUIPMENTS.indexOf(item.id) !== -1) {
          temp.flag = 'EQUIPMENT_' + item.id
          temp.type = 'EQUIPMENT'
          services.push(temp)
        }
      })
    } else {
      if (data.equipment) {
        angular.forEach(data.equipment, function (item) {
          var temp = item
          if (BRANCH_EQUIPMENTS.indexOf(item.id) !== -1) {
            temp.flag = 'EQUIPMENT_' + item.id
            temp.type = 'EQUIPMENT'
            services.push(temp)
          }
        })
      }

      if (data.products) {
        angular.forEach(data.products, function (item) {
          var tempProduct = item
          tempProduct.flag = item.name
          tempProduct.type = item.name
          services.push(tempProduct)
        })
      }

      if (data.type === 'ATM') {
        angular.forEach(services, function (item) {
          var index = ATM_DEFAULT.indexOf(item.flag)
          if (index > -1) {
            item.index = index
          }
        })
      }

      if (data.type === 'BRANCH') {
        angular.forEach(services, function (item) {
          var index = BRANCH_DEFAULT.indexOf(item.flag)
          if (index > -1) {
            item.index = index
          }
        })
      }
    }

    data.sortedServices = services
    return data
  }

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }
  function appendTransform (defaults, transform) {
    defaults = angular.isArray(defaults) ? defaults : [defaults]
    return defaults.concat(transform)
  }

  function toImageUrlTransformResponse (data, headerGetter) {
    var header = headerGetter()

    if (!data) { return null }
    var arr = new Uint8Array(data)
    var blob = new Blob([arr], { type: header['content-type'] })
    var urlCreator = window.URL || window.webkitURL
    var imageUrl = urlCreator.createObjectURL(blob)

    return imageUrl
  }

  function toBlobUrlTransformResponse (blob, headerGetter) {
    var urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(blob)
  }

  function replaceInterpuction (string) {
    var original = 'aáäàãâbcćčdďeéěëèêfghiíïìîjklĺľmnńňñoóôòõöőpqrŕřsśštťuúùůüũûvwxyýzźž'
    var replaced = 'aaaaaabcccddeeeeeefghiiiiijklllmnnnnooooooopqrrrsssttuuuuuuuvwxyyzzz'

    string = string.toLowerCase()
    var result = ''
    for (var index = 0; index < string.length; index++) {
      var character = string[index]
      if (original.indexOf(character) > -1) {
        result += replaced[original.indexOf(character)]
      } else {
        if (character === ' ' || character === '/') {
          result += '_'
        } else if (character === '.') {
          result += ''
        } else {
          result += character
        }
      }
    }
    return result
  }

  return {
    getGoogleMetadata: function (data) {
      var result = {}
      result.image = 'http://www.csas.cz/stat/csie/banka/framework/skins/csas_default_2009/images/logo.gif'
      result['@context'] = 'http://www.schema.org'
      result['@type'] = 'LocalBusiness'
      result['@id'] = $window.location.href
      result.name = data.name

      result.address = {}
      result.address['@type'] = 'PostalAddress'
      result.address.streetAddress = data.address
      result.address.addressLocality = data.city
      result.address.postalCode = data.postCode
      result.address.addressCountry = data.country

      result.geo = {}
      result.geo['@type'] = 'GeoCoordinates'
      result.geo.latitude = data.location.lat
      result.geo.longitude = data.location.lng

      result.url = $window.location.href

      result.openingHoursSpecification = []

      angular.forEach(data.openingHours, function (day) {
        angular.forEach(day.intervals, function (interval) {
          var spec = {}
          spec['@type'] = 'OpeningHoursSpecification'
          spec.dayOfWeek = day.weekday.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })
          spec.opens = interval.from
          spec.closes = interval.to
          result.openingHoursSpecification.push(spec)
        })
      })
      return '<script type="application/ld+json">' + JSON.stringify(result) + '</script>'
    },
    replaceInterpunction: function (string) {
      return replaceInterpuction(string)
    },
    isLocalStorageEnabled: function () {
      var storage = window.localStorage
      try {
        storage.setItem('test', '1')
        storage.removeItem('test')
        return true
      } catch (error) {
        console.info('Unable to use localStorage... You are using anonymous mode.')
        return false
      }
    },
    getDetailUrl: function (data, type) {
      var url = '#'
      url += '/' + data.id + '/' + type
      url += '/' + replaceInterpuction(data.address) + '_' + replaceInterpuction(data.city)
      return url
    },
    search: function (params) {
      return $http({
        url: endpoint(),
        method: 'GET',
        params: params
      })
    },
    processATMObject: function (data) {
      data = processDistance(data)
      data = processServices(data)
      return data
    },
    processBranchObject: function (data) {
      data = processDistance(data)
      data = processServices(data)
      return data
    },
    streetViewUrl: function (lat, lng, address) {
      if (address) {
        return 'https://maps.googleapis.com/maps/api/streetview?size=500x500&location=' + address + '&pitch=20'
      }
      return 'https://maps.googleapis.com/maps/api/streetview?size=500x500&location=' + lat + '%2C' + lng + '&pitch=20&sensor=false'
    },
    streetView: function (url) {
      return $http({
        url: url,
        method: 'GET'
      })
    },
    signUrl: function (url, googleMapsApiKey) {
      var request = { key: googleMapsApiKey, urlToSign: url }
      return $http({
        url: endpoint('maps/urlSigner'),
        method: 'GET',
        params: request
      })
    },
    branchDetail: function (id) {
      return $http({
        url: endpoint('branches/' + id),
        method: 'GET',
        params: { showSpecialists: true, showProducts: true, showPhotos: true }
      })
    },
    branchPhotos: function (id) {
      return $http({
        url: endpoint('branches/' + id + '/photos'),
        method: 'GET',
        params: { type: 'BRANCH', blackAndWhite: false }
      })
    },
    branchPhoto: function (id, photoId) {
      return $http({
        url: endpoint('branches/' + id + '/photos/' + photoId),
        method: 'GET',
        responseType: 'arraybuffer',
        cache: true,
        transformResponse: appendTransform($http.defaults.transformResponse, toImageUrlTransformResponse)
      })
    },
    branchManagerPhoto: function (id) {
      return $http({
        url: apiBase + 'v1/phonebook-ext/employees/' + id + '/photo/redim',
        method: 'GET',
        responseType: 'blob',
        cache: true,
        transformResponse: appendTransform($http.defaults.transformResponse, toBlobUrlTransformResponse),
        logToLogglyCondition: function(rejection) {
          return rejection && rejection.config && rejection.config.url && !~rejection.config.url.indexOf('loggly.com') && ~rejection.status && rejection.status !== 404
        }
      })
    },
    branchSpecialists: function (id) {
      return $http({
        url: endpoint('branches/' + id + '/specialists'),
        method: 'GET'
      })
    },
    branchProducts: function (id) {
      return $http({
        url: endpoint('branches/' + id + '/products'),
        method: 'GET',
        cache: true
      })
    },
    branchOutage: function (id) {
      return $http({
        url: endpoint('branches/' + id + '/outage'),
        method: 'GET'
      })
    },
    globalOutage: function (id) {
      return $http({
        url: endpoint('branches/globalOutages'),
        method: 'GET'
      })
    },
    narketPlaceBranchDetail: function (id) {
      return $http({
        url: endpoint('branches/marketPlaceId/' + id),
        method: 'GET'
      })
    },
    atmDetail: function (id) {
      return $http({
        url: endpoint('atms/' + id),
        method: 'GET'
      })
    },
    atmServices: function (id) {
      return $http({
        url: endpoint('atms/' + id + '/services'),
        method: 'GET',
        cache: true
      })
    },
    atmPhoto: function (id) {
      return $http({
        url: endpoint('atms/' + id + '/photo'),
        method: 'GET',
        responseType: 'arraybuffer',
        cache: true,
        transformResponse: appendTransform($http.defaults.transformResponse, toImageUrlTransformResponse)
      })
    },
    getHolidays: function () {
      return $http({
        url: endpoint('holidays'),
        method: 'GET',
        cache: true
      })
    }
  }
}

exports.placesService = placesService
