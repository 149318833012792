var angular = require('angular')
var exchangeWebService = require('aem-libs-angularjs/src/services/exchange-web.service').exchangeWebService

angular
  .module('PlacesListCmp')
  .factory('exchangeWebService', exchangeWebService)
  .component('placesListFilter', {
    template: require('./places-list-filter.component.tpl.html'),
    controller: PlacesListFilterCtrl,
    controllerAs: 'vm',
    bindings: {
      type: '<',
      selectedFlags: '<',
      onChanged: '&',
      selectedCurrency: '<',
      currencyChanged: '&'
    }
  })

PlacesListFilterCtrl.$inject = ['$filter', 'exchangeWebService', 'widgetParamsService', 'wgService']

function PlacesListFilterCtrl($filter, exchangeWebService, widgetParamsService, wgService) {
  var vm = this
  var typeFlags = [
    'HC',
    'PB',
    'POBOCKY',
    'CSP',
    'KC',
    'COMPANY',
    'EXCHANGE_SERVICE',
    'ATM',
    'TRANSFER_ATM',
    'DEPOSIT_ATM'
  ]
  var defaultFilter = {
    items: [
      {
        title: 'PLACES_LIST.FILTER.BRANCH.OPEN_NOW',
        flag: 'OPEN_NOW'
      },
      {
        title: 'PLACES_LIST.FILTER.BRANCH.OPEN_WEEKEND',
        flag: 'WEEKEND_OFFICE'
      },
      {
        title: 'PLACES_LIST.FILTER.BRANCH.HANDICAP',
        items: [
          {
            title: 'PLACES_LIST.FILTER.BRANCH.BARRIER_FREE',
            flag: 'BARRIER_FREE_OR_BARRIER_PARTIALY_FREE'
          },
          {
            title: 'PLACES_LIST.FILTER.BRANCH.HEARING_IMPAIR',
            flag: 'HEARING_IMPAIR'
          },
          {
            title: 'PLACES_LIST.FILTER.BRANCH.DIGITAL_VOICE',
            flag: 'DIGITAL_VOICE'
          }
        ]
      },
      {
        title: 'PLACES_LIST.FILTER.BRANCH.CASH_DESK',
        flag: 'CASH_DESK',
        items: [
          {
            title: 'PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW',
            items: []
          }
        ]
      },
      {
        title: 'PLACES_LIST.FILTER.BRANCH.GOLD',
        flag: 'SAFE_BOX'
      }
    ]
  }
  var options = {
    BRANCH: defaultFilter,
    PERSONAL_SENIOR: defaultFilter,
    ATM: {
      items: [
        {
          title: 'PLACES_LIST.FILTER.ATM.AVAILABLE_NOW',
          flag: 'OPEN_NOW'
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.HANDICAP',
          items: [
            {
              title: 'PLACES_LIST.FILTER.ATM.ATM_ACCESSIBILITY',
              flag: 'ATM_ACCESSIBILITY'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.FOR_BLIND_ATM',
              flag: 'FOR_BLIND_ATM'
            }
          ]
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.DEPOSIT',
          flag: 'DEPOSIT_ATM'
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.PAYMENT',
          items: [
            {
              title: 'PLACES_LIST.FILTER.ATM.TRANSFER_ATM',
              flag: 'TRANSFER_ATM'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.BAR_CODE_READER_ATM',
              flag: 'BAR_CODE_READER_ATM'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.QR_CODE_READER_ATM',
              flag: 'QR_CODE_READER_ATM'
            }
          ]
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.FEATURES',
          items: [
            {
              title: 'PLACES_LIST.FILTER.ATM.CONTACTLESS_SYSTEM_PILSEN',
              flag: 'CONTACTLESS_SYSTEM_PILSEN'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.CONTACTLESS_ATM',
              flag: 'CONTACTLESS_ATM'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.ACTIVATION_CODE',
              flag: 'ACTIVATION_CODE'
            }
          ]
        }
      ]
    },
    DEPOSIT_ATM: {
      items: [
        {
          title: 'PLACES_LIST.FILTER.ATM.AVAILABLE_NOW',
          flag: 'OPEN_NOW'
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.HANDICAP',
          items: [
            {
              title: 'PLACES_LIST.FILTER.ATM.ATM_ACCESSIBILITY',
              flag: 'ATM_ACCESSIBILITY'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.FOR_BLIND_ATM',
              flag: 'FOR_BLIND_ATM'
            }
          ]
        }
      ]
    },
    TRANSFER_ATM: {
      items: [
        {
          title: 'PLACES_LIST.FILTER.ATM.AVAILABLE_NOW',
          flag: 'OPEN_NOW'
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.HANDICAP',
          items: [
            {
              title: 'PLACES_LIST.FILTER.ATM.ATM_ACCESSIBILITY',
              flag: 'ATM_ACCESSIBILITY'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.FOR_BLIND_ATM',
              flag: 'FOR_BLIND_ATM'
            }
          ]
        },
        {
          title: 'PLACES_LIST.FILTER.ATM.PAYMENT_METHOD',
          items: [
            {
              title: 'PLACES_LIST.FILTER.ATM.BAR_CODE_READER_ATM',
              flag: 'BAR_CODE_READER_ATM'
            },
            {
              title: 'PLACES_LIST.FILTER.ATM.QR_CODE_READER_ATM',
              flag: 'QR_CODE_READER_ATM'
            }
          ]
        }
      ]
    },
    EXCHANGE: {
      items: [
        {
          title: 'PLACES_LIST.FILTER.BRANCH.OPEN_NOW',
          flag: 'OPEN_NOW'
        },
        {
          title: 'PLACES_LIST.FILTER.EXCHANGES.CURRENCIES',
          flag: 'CURRENCIES',
          items: []
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.HANDICAP',
          items: [
            {
              title: 'PLACES_LIST.FILTER.BRANCH.BARRIER_FREE',
              flag: 'BARRIER_FREE_OR_BARRIER_PARTIALY_FREE'
            },
            {
              title: 'PLACES_LIST.FILTER.BRANCH.HEARING_IMPAIR',
              flag: 'HEARING_IMPAIR'
            },
            {
              title: 'PLACES_LIST.FILTER.BRANCH.DIGITAL_VOICE',
              flag: 'DIGITAL_VOICE'
            }
          ]
        }
      ]
    },
    COMPANY: {
      items: [
        {
          title: 'PLACES_LIST.FILTER.BRANCH.OPEN_NOW',
          flag: 'OPEN_NOW'
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.OPEN_WEEKEND',
          flag: 'WEEKEND_OFFICE'
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.HANDICAP',
          items: [
            {
              title: 'PLACES_LIST.FILTER.BRANCH.BARRIER_FREE',
              flag: 'BARRIER_FREE_OR_BARRIER_PARTIALY_FREE'
            },
            {
              title: 'PLACES_LIST.FILTER.BRANCH.HEARING_IMPAIR',
              flag: 'HEARING_IMPAIR'
            },
            {
              title: 'PLACES_LIST.FILTER.BRANCH.DIGITAL_VOICE',
              flag: 'DIGITAL_VOICE'
            }
          ]
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.CASH_DESK',
          flag: 'CASH_DESK'
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.GOLD',
          flag: 'SAFE_BOX'
        }
      ]
    },
    ENCS: {
      items: [
        {
          title: 'PLACES_LIST.FILTER.BRANCH.OPEN_NOW',
          flag: 'OPEN_NOW'
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.OPEN_WEEKEND',
          flag: 'WEEKEND_OFFICE'
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.VIDEOCONFERENCE',
          flag: 'VIDEOCONFERENCE'
        },
        {
          title: 'PLACES_LIST.FILTER.BRANCH.NONFINANCE',
          flag: 'NONFINANCE'
        }
      ]
    }
  }

  vm.$onInit = function () {
    vm.isOpened = false
    vm.isSubMenuOpened = false
    vm.createCurrencyList = createCurrencyList
    vm.isFirstLevelOpened = false

    if (vm.type === 'BRANCH') {
      widgetParamsService.get(wgService.getAppName()).then(function (res) {
        vm.allCurrencies = []
        vm.topCurrenciesList = res.data.currencies.topCurrenciesList
        vm.allowedCurrenciesList = res.data.currencies.allowedCurrenciesList
        getCurrencies()
      })
    }
    vm.flags = (options[vm.type] || {}).items
    vm.changeItem = changeItem
    vm.changeCurrency = changeCurrency
    vm.isCurrency = isCurrency
    vm.isAnySubitemSelected = isAnySubitemSelected
    vm.isCashDeskChecked = isCashDeskChecked
    vm.handleFirstLevelClick = handleFirstLevelClick
    vm.isCashDeskSelected = isCashDeskSelected
    vm.checkedFilters = []
    vm.getFirstLevelClass = getFirstLevelClass
    vm.top4 = []
    vm.isFlagsListVisible = isFlagsListVisible
    setFilterLength()
  }

  function getFirstLevelClass() {
    if (vm.isFirstLevelOpened) {
      return 'csas-selectbox dropdown open'
    }

    return 'csas-selectbox dropdown'
  }

  function createCurrencyList() {
    let curencyArray = []

    for (let i = 0; i < vm.allowedCurrenciesList.length; i++) {
      curencyArray.push({
        title: vm.allowedCurrenciesList[i],
        flag: vm.allowedCurrenciesList[i]
      })
    }
    return curencyArray
  }

  function isCashDeskSelected(item) {
    vm.isFirstLevelOpened = true
    vm.changeItem(item)

    if (!document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CASH_DESK').checked) {
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW').checked = false
    }
  }

  function isCashDeskChecked() {
    if (document.location.href.indexOf('type=BRANCH') && document.location.href.indexOf('CASH_DESK') > -1) {
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CASH_DESK').checked = true
    } else {
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CASH_DESK').checked = false
    }
  }

  function handleFirstLevelClick() {
    vm.isFirstLevelOpened = true
  }

  function setFilterLength() {
    var l = vm.selectedFlags.length + vm.selectedCurrency.length
    for (var i = 0; i < l; i++) {
      if (typeFlags.indexOf(vm.selectedFlags[i]) === -1) {
        vm.checkedFilters.push(vm.selectedFlags[i])
      }
    }
  }

  function isFlagsListVisible() {
    if (
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW') &&
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW').checked
    ) {
      return true
    }
    return false
  }

  function changeItem(item) {
    vm.isFirstLevelOpened = true

    var flagIndex = vm.selectedFlags.indexOf(item.flag)
    if (item.title && item.title === 'PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW') {
      if (vm.isSubMenuOpened) {
        document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CASH_DESK').checked = true
      }
    }

    if (isCurrency(item.flag)) {
      changeCurrency(item)
    } else {
      if (~flagIndex) {
        vm.selectedFlags.splice(flagIndex, 1)
      } else {
        vm.selectedFlags.push(item.flag)
      }
    }

    let flagItems = []
    for (let i = 0; i < options.BRANCH.items.length; i++) {
      if (options.BRANCH.items[i].title === 'PLACES_LIST.FILTER.BRANCH.CASH_DESK') {
        for (let j = 0; j < options.BRANCH.items[i].items.length; j++) {
          if (options.BRANCH.items[i].items[j].title === 'PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW') {
            for (let k = 0; k < options.BRANCH.items[i].items[j].items.length; k++) {
              flagItems.push(options.BRANCH.items[i].items[j].items[k].flag)
            }
          }
        }
      }
    }

    function isFlagItem(selectedCurrency) {
      for (let i = 0; i < flagItems.length; i++) {
        if (flagItems[i] === selectedCurrency) {
          return true
        }
      }
      return false
    }

    for (let i = 0; i < vm.selectedCurrency.length; i++) {
      if (isFlagItem(vm.selectedCurrency[i])) {
        if (!vm.selectedFlags.includes('CASH_DESK')) {
          vm.selectedFlags.push('CASH_DESK')
        }
      }
    }

    setFilterLength()
    vm.onChanged({
      flags: vm.selectedFlags
    })

    // clearing all currencies on disable cash desks
    if (
      item.flag === 'CASH_DESK' &&
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CASH_DESK') &&
      document.getElementById('field_PLACES_LIST.FILTER.BRANCH.CASH_DESK').checked === true
    ) {
      vm.selectedCurrency = []
      vm.currencyChanged({
        currencies: vm.selectedCurrency
      })
    }
  }

  function isCurrency(currencyFlag) {
    return options.BRANCH.items
      .find(function (object) {
        return object.title === 'PLACES_LIST.FILTER.BRANCH.CASH_DESK'
      })
      .items.find(function (el) {
        return el.title === 'PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW'
      })
      .items.some(function (e) {
        return e.flag === currencyFlag
      })
  }

  function changeCurrency(item) {
    var currencyIndex = vm.selectedCurrency.indexOf(item.flag)
    if (~currencyIndex) {
      vm.selectedCurrency.splice(currencyIndex, 1)
    } else {
      vm.selectedCurrency.push(item.flag)
    }
    setFilterLength()
    vm.currencyChanged({
      currencies: vm.selectedCurrency
    })
  }

  function isAnySubitemSelected(item) {
    if (item.items && vm.selectedFlags.length) {
      for (var i = 0, len = vm.selectedFlags.length; i < len; i++) {
        var found = item.items.filter(function (element) {
          return element.flag === vm.selectedFlags[i]
        })[0]
        if (found) {
          return true
        }
      }
    }
    if (item.items && vm.selectedCurrency.length) {
      for (var x = 0, leng = vm.selectedCurrency.length; x < leng; x++) {
        var f = item.items.filter(function (element) {
          return element.flag === vm.selectedCurrency[x]
        })[0]
        if (f) {
          return true
        }
      }
    }
    return false
  }

  function getCurrencies() {
    return exchangeWebService
      .listAllCurrency(vm.lang)
      .then(function (res) {
        vm.allCurrencies = res['data']
        vm.allCurrencies = vm.allCurrencies.filter(function (curr) {
          return (
            createCurrencyList()
              .map(function (e) {
                return e.flag
              })
              .indexOf(curr.code) !== -1
          )
        })

        let currencies = []
        createCurrencyList().forEach(function (elem) {
          currencies.push(
            vm.allCurrencies.filter(function (el) {
              return el.code === elem.flag
            })
          )
        })
        vm.allCurrencies = []

        currencies.forEach(function (curr) {
          vm.allCurrencies.push(curr[0])
        })

        // filtr pro currencies
        for (var i = 0; i < vm.allCurrencies.length; i++) {
          var menuItem = {
            title: vm.allCurrencies[i].code + ' - ' + vm.allCurrencies[i].longName,
            flag: vm.allCurrencies[i].code
          }
          let shortMenuItem = {
            title: vm.allCurrencies[i].longName.charAt(0).toUpperCase() + vm.allCurrencies[i].longName.slice(1),
            flag: vm.allCurrencies[i].code
          }
          if (vm.topCurrenciesList.indexOf(vm.allCurrencies[i].code) !== -1) {
            vm.top4.push(menuItem)
          }
          if (vm.allowedCurrenciesList.indexOf(vm.allCurrencies[i].code) !== -1) {
            options.EXCHANGE.items[1].items.push(menuItem)
            options.BRANCH.items
              .find(function (object) {
                return object.title === 'PLACES_LIST.FILTER.BRANCH.CASH_DESK'
              })
              .items.find(function (el) {
                return el.title === 'PLACES_LIST.FILTER.BRANCH.CURR_WITHDRAW'
              })
              .items.push(shortMenuItem)
          }
        }
        options.EXCHANGE.items[1].items.sort(function (a, b) {
          return a.title.localeCompare(b.title)
        })
        vm.isErrorBackend = false
      })
      .catch(function () {
        vm.isErrorBackend = true
      })
  }
}
