/* global angular */

angular.module('orderCmp')
  .component('orderFormCancel', {
    template: require('./order-form-cancel.component.tpl.html'),
    controller: OrderFormCancelCtrl,
    controllerAs: 'vm',
    bindings: {
      plannedDatetime: '<',
      email: '<',
      branchDetailUrl: '<'
    }
  })

OrderFormCancelCtrl.$inject = ['basePath']

function OrderFormCancelCtrl (basePath) {
  var vm = this

  vm.$onInit = function () {
    vm.noIco = basePath + 'img/order-no.svg'
    vm.emailStyle = {
      background: '0px center no-repeat',
      'background-image': 'url(' + basePath + 'img/order-ico-email.svg)',
      'background-size': '60px 60px'
    }
  }
}
