exports.cs = {
  PLACES_TODAY_CLOSING_IN_HOURS: {
    BRANCH: {
      CLOSING_SOON: 'Pobočka <strong>brzy zavírá</strong>',
      CLOSED: 'Dnes je pobočka <strong>zavřená</strong>',
      CLOSED_NOW: 'Pobočka <strong>je nyní zavřená</strong>',
      PAUSED_NOW: 'Pobočku <strong>zanedlouho znovu otevřeme</strong>',
      CLOSED_SHORT: 'Zavřeno',
      EARLY_CLOSING: 'Pobočka <strong>bude za chvíli zavírat..</strong>',
      WILL_BE_OPEN_STILL:
        'Dnes <strong>otevřeno ještě %{smart_count} hodinu</strong>. |||| Dnes <strong>otevřeno ještě %{smart_count} hodiny</strong>. |||| Dnes <strong>otevřeno ještě %{smart_count} hodin</strong>.',
      TODAY: 'Dnes otevřeno'
    },
    ATM: {
      CLOSING_SOON: 'Bankomat bude brzy nedostupný',
      CLOSED: 'Bankomat je nedostupný',
      CLOSED_NOW: 'Bankomat je nyní nedostupný',
      PAUSED_NOW: 'Bankomat bude zanedlouho dostupný',
      CLOSED_SHORT: 'Nedostupný',
      EARLY_CLOSING: 'Bankomat <strong>bude brzy nedostupný</strong>',
      AVAILABLE_NONSTOP: 'Dostupný <strong>Nonstop</strong>',
      WILL_BE_OPEN_STILL:
        'Dostupný ještě <strong>%{smart_count} hodinu</strong>. |||| Dostupný ještě <strong>%{smart_count} hodiny</strong>. |||| Dostupný ještě <strong>%{smart_count} hodin</strong>.',
      TODAY: 'Dnes dostupný'
    },
    CASHDESK: {
      CLOSING_SOON: 'Pokladna <strong>brzy zavírá</strong>',
      CLOSED: 'Pokladna <strong>je zavřená</strong>',
      CLOSED_NOW: 'Pokladna <strong>je nyní zavřená</strong>',
      PAUSED_NOW: 'Pokladnu <strong>zanedlouho znovu otevřeme</strong>',
      CLOSED_SHORT: 'Zavřeno',
      EARLY_CLOSING: 'Pokladna <strong>bude za chvíli zavírat..</strong>',
      WILL_BE_OPEN_STILL:
        'Pokladna <strong>otevřena ještě %{smart_count} hodinu</strong>. |||| Pokladna <strong>otevřena ještě %{smart_count} hodiny</strong>. |||| Pokladna <strong>otevřena ještě %{smart_count} hodin</strong>.',
      TODAY: 'Dnes otevřeno'
    }
  }
}
