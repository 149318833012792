var angular = require('angular')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en

angular.module('markerContentCmp', [])
  .component('markerContent', {
    template: require('./marker-content.component.tpl.html'),
    controller: MarkerContentCtrl,
    controllerAs: 'vm',
    bindings: {
      branch: '<',
      fullDetail: '<'
    }
  })

require('./component.css')

MarkerContentCtrl.$inject = ['placesService', '$filter', 'qmaticService', '$window',
  'i18n', 'wgService', 'widgetParamsService', 'placesOpeningHoursService']

function MarkerContentCtrl (placesService, $filter, qmaticService, $window,
  i18n, wgService, widgetParamsService, placesOpeningHoursService) {
  var vm = this

  vm.$onInit = function () {
    vm.encs = wgService.getAppName() === 'AemEncsBranches'
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
    vm.branchAppointmentsCrmUrl = null
    vm.placeOpeningHours = placesOpeningHoursService.normalize(vm.branch.openingHours, vm.branch.outages, vm.branch.accessType)

    if ($window.innerWidth < 360) {
      vm.totalWidthStyle = { 'max-width': '160px' }
    } else if ($window.innerWidth < 540) {
      vm.totalWidthStyle = { 'min-width': '160px', 'max-width': '250px' }
    } else {
      vm.totalWidthStyle = vm.branch.qmatic ? { 'min-width': '370px' } : { 'min-width': '330px' }
    }
    vm.url = placesService.getDetailUrl(vm.branch, $filter('translate')('PLACES.URL.' + vm.branch.type.toUpperCase()))
    vm.hotovost = false
    vm.bezhotovost = false
    if (vm.fullDetail) {
      vm.branch.qmatic && qmaticService.services(vm.branch.id, i18n.langCode).then(function (res) {
        angular.forEach(res.data, function (item) {
          if (item.qmatic === true) {
            if (item.id === 0) {
              vm.hotovost = true
            }
            if (item.id === 1) {
              vm.bezhotovost = true
            }
          }
        })
      })
    }

    widgetParamsService.get(wgService.getAppName()).then(function (res) {
      var branchAppointmentsCrmUrl = res.data.branchAppointmentsCrmUrl
      var urlToBranchAppointments = branchAppointmentsCrmUrl && branchAppointmentsCrmUrl[i18n.langCode]
      if (urlToBranchAppointments) {
        urlToBranchAppointments += 'branch/' + vm.branch.id
      }

      vm.branchAppointmentsCrmUrl = isBranchAppointmentAvailable() && urlToBranchAppointments
    })
  }

  function isBranchAppointmentAvailable () {
    var hasPobockyFlag = vm.branch.services && vm.branch.services.some(function (service) {
      return service.flag === 'POBOCKY'
    })
    return vm.branch.orderToBranch && hasPobockyFlag && vm.branch.marketPlaceId
  }
}
