var angular = require('angular')
require('angular-route')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en
require('aem-libs-angularjs/src/directives/track-element.directive.js')
var placesService = require('../../services/places.service.js').placesService
var qmaticService = require('aem-libs-angularjs/src/services/qmatic.service.js').qmaticService
var dropdown = require('angular-ui-bootstrap/src/dropdown')
var placesStateService = require('../../services/places-state.service.js').placesStateService
var appointmentsTrackingDataservice = require('aem-libs-places-angularjs/src/services/appointments-tracking.dataservice').appointmentsTrackingDataservice

angular.module('PlacesSearchCmp', ['TrackElementCmp', 'ngRoute', dropdown])
  .factory('placesService', placesService)
  .factory('qmaticService', qmaticService)
  .factory('placesStateService', placesStateService)
  .factory('appointmentsTrackingDataservice', appointmentsTrackingDataservice)
  .component('placesSearch', {
    template: ['wgService', function (wgService) {
      if (~wgService.getAppName().indexOf('Ecb')) {
        return require('./places-search-ecb.component.tpl.html')
      } else if (~wgService.getAppName().indexOf('Csps')) {
        return require('./places-search-pfcs.component.tpl.html')
      } else if (~wgService.getAppName().indexOf('Encs')) {
        return require('./places-search-encs.component.tpl.html')
      } else {
        return require('./places-search.component.tpl.html')
      }
    }],
    controller: PlacesSearchCtrl,
    controllerAs: 'vm',
    bindings: {
      onSearch: '&'
    }
  })

require('./components/places-search-types/places-search-types.component.js')

PlacesSearchCtrl.$inject = ['wgService', 'placesService', '$timeout', '$filter', '$location', '$route', 'userTracking', 'i18n', 'placesStateService']

function PlacesSearchCtrl (wgService, placesService, $timeout, $filter, $location, $route, userTracking, i18n, placesStateService) {
  var vm = this
  var isEcb = !!~wgService.getAppName().indexOf('Ecb')

  vm.$onInit = function () {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
    vm.snippet = wgService.getParams() && wgService.getParams().widgetName
    vm.showExchange = wgService.getParams() && wgService.getParams().exchangePoint
    vm.businessSearch = wgService.getParams() && wgService.getParams().businessSearch
    vm.showExchange = vm.showExchange === undefined ? true : vm.showExchange

    if (vm.showExchange) {
      vm.lowTypes = vm.businessSearch ? ['TRANSFER_ATM', 'HC', 'EXCHANGE', 'CSP', 'LKC', 'PB'] : ['TRANSFER_ATM', 'HC', 'EXCHANGE', 'CSP', 'PB', 'KC', 'COMPANY', 'LKC', 'PERSONAL_SENIOR']
      vm.lowTypesEcb = vm.businessSearch ? ['DEPOSIT_ATM', 'TRANSFER_ATM', 'HC', 'EXCHANGE', 'CSP', 'LKC', 'PB'] : ['DEPOSIT_ATM', 'TRANSFER_ATM', 'HC', 'EXCHANGE', 'CSP', 'PB', 'LKC', 'COMPANY']
    } else {
      vm.lowTypes = vm.businessSearch ? ['TRANSFER_ATM', 'HC', 'CSP', 'LKC', 'PB'] : ['TRANSFER_ATM', 'HC', 'CSP', 'PB', 'KC', 'COMPANY', 'LKC', 'PERSONAL_SENIOR']
      vm.lowTypesEcb = vm.businessSearch ? ['DEPOSIT_ATM', 'TRANSFER_ATM', 'HC', 'CSP', 'LKC', 'PB'] : ['DEPOSIT_ATM', 'TRANSFER_ATM', 'HC', 'CSP', 'PB', 'LKC', 'COMPANY']
    }
    vm.topTypes = vm.businessSearch ? ['BRANCH', 'KC', 'COMPANY', 'ATM', 'DEPOSIT_ATM'] : ['BRANCH', 'ATM', 'DEPOSIT_ATM']
    vm.topTypesEcb = vm.businessSearch ? ['BRANCH', 'ATM', 'COMPANY', 'KC'] : ['BRANCH', 'ATM', 'KC']
    vm.addressRequiredError = false
    vm.address = null
    vm.lat = null
    vm.lng = null
    vm.outageDaysString = null
    vm.publicNote = null
    vm.outages = []
    vm.searchType = placesStateService.getState().searchType

    vm.getMyLocation = getMyLocation
    vm.keyPress = keyPress
    vm.searchButton = searchButton
    vm.onSelectSearchType = onSelectSearchType

    init()
  }

  function init () {
    var address = placesStateService.getAddress()
    placesService.globalOutage().then(function (response) {
      vm.outages = response.data || []
      if (vm.outages.length) {
        setClosestOutageInfo()
      }
    })

    if (address === 'nejblizsi' || address === 'closest') {
      getMyLocation()
    } else {
      vm.address = address
      var autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('address'), {
        componentRestrictions: {
          country: 'cz'
        }
      })
      autocomplete.addListener('place_changed', function () {
        var place = autocomplete.getPlace()
        if (place.formatted_address) {
          vm.address = place.formatted_address
          vm.lat = place.geometry.location.lat()
          vm.lng = place.geometry.location.lng()
        }
        $timeout(search)
        userTracking.onClick('placesAutocomplete')
      })
    }

    if (!vm.snippet && $route.current && ~$route.current.$$route.originalPath.search('/' + $filter('translate')('PLACES.URL.SEARCH'))) {
      search()
    }
  }

  function getMyLocation () {
    navigator.geolocation.getCurrentPosition(function (position) {
      setGeolocation(position.coords.latitude, position.coords.longitude)
    }, function (error) {
      console.error('Unable to get current geolocation: ' + error.message)
    })
  }

  function setGeolocation (lat, lng) {
    var geocoder = new window.google.maps.Geocoder()
    var latlng = new window.google.maps.LatLng(lat, lng)
    geocoder.geocode({
      'latLng': latlng
    }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        $timeout(function () {
          vm.address = results[0]['formatted_address']
          vm.lat = results[0].geometry.location.lat()
          vm.lng = results[0].geometry.location.lng()
          $timeout(search)
        })
      } else {
        vm.lat = null
        vm.lng = null
        $timeout(search)
      }
      placesStateService.setAddress(vm.address)
    })
  }

  function searchButton () {
    vm.lat = null
    vm.lng = null
    placesStateService.setAddress(vm.address)
    $timeout(search)
  }

  function search () {
    if (!vm.address || (vm.address && vm.address.length === 0)) {
      vm.addressRequiredError = true
      $timeout(function () {
        userTracking.onRequest({
          address: vm.address,
          lat: vm.lat,
          lng: vm.lng
        })
      })
      return
    }

    placesStateService.setAddress(vm.address)
    vm.addressRequiredError = false

    if (!vm.snippet) {
      $location.path('/' + $filter('translate')('PLACES.URL.SEARCH').toLowerCase())
    }
    $timeout(function () {
      userTracking.onRequest({
        address: vm.address,
        lat: vm.lat,
        lng: vm.lng
      })
      vm.onSearch()
    })
  }

  function keyPress (event) {
    if (event.keyCode === 13) {
      userTracking.onEnterPressed('search')
    }
  }

  function setClosestOutageInfo () {
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    }

    var closestOutage = groupBy(vm.outages, 'publicNoteCs')[vm.outages[0].publicNoteCs]

    var arr = Object.keys(closestOutage).map(function (k) {
      return new Date(closestOutage[k].outageDate).getTime()
    })
    var j = 0
    vm.outageDays = arr.reduce(function (stack, b) {
      var cur = stack[j]
      var a = cur ? cur[cur.length - 1] : 0
      if (b - a > 86400000) {
        j++
      }
      if (!stack[j]) {
        stack[j] = []
      }
      stack[j].push(new Date(b))
      return stack
    }, [])
    vm.outageDays.shift()

    vm.outageDaysString = vm.outageDays.map(function (day) {
      return day.map(function (date) {
        return $filter('date')(date)
      }).join(' - ')
    }).join(', ')

    vm.publicNote = i18n.langCode === 'cs' ? closestOutage[0].publicNoteCs : closestOutage[0].publicNoteEn
  }

  function onSelectSearchType (type) {
    if ((type === 'KC' || type === 'LKC') && !isEcb) {
      // In case of RKC redirect to RKC page.
      window.location = wgService.getParams().ecbPlacesUrl[i18n.langCode] + '?type='+ type + '&flags=' + type
    } else {
      placesStateService.setStateBySearchParamsType(type)
    }
  }
}
