exports.en = {
  PLACES_LIST: {
    ITEM: {
      NAVIGATE: {
        BRANCH: 'Navigate<br>to branch',
        ATM: 'Navigate<br>to ATM'
      }
    },
    FILTER: {
      BRANCH: {
        OPEN_NOW: 'Open right now',
        OPEN_WEEKEND: 'Open at weekends',
        HANDICAP: 'For the disabled',
        BARRIER_FREE: 'Barrier-free access',
        HEARING_IMPAIR: 'Special application for the hearing-impaired',
        DIGITAL_VOICE: 'Voice navigation for the visually impaired',
        CASH_DESK: 'With cash desk',
        CURR_WITHDRAW: 'Deposit/withdraw of foreign currency',
        GOLD: 'With safety deposit boxes',
        NONFINANCE: 'Non-financial specialist',
        VIDEOCONFERENCE: 'Videoconference'
      },
      ATM: {
        AVAILABLE_NOW: 'Available right now',
        HANDICAP: 'For the disabled',
        ATM_ACCESSIBILITY: 'Barrier-free access',
        FOR_BLIND_ATM: 'ATM for the visually impaired',
        DEPOSIT: 'I’d like to make a deposit',
        PAYMENT: 'I’d like to make a payment',
        PAYMENT_METHOD: 'Payment method',
        TRANSFER_ATM: 'ATM for transfers',
        BAR_CODE_READER_ATM: 'Barcode reader',
        QR_CODE_READER_ATM: 'QR code reader',
        FEATURES: 'More features',
        CONTACTLESS_SYSTEM_PILSEN: 'Transport card credit',
        CONTACTLESS_ATM: 'Contactless',
        ACTIVATION_CODE: 'Activation Code for George klíč'
      },
      EXCHANGES: {
        COUNTRY: 'Czech Republic',
        LONG_NAME: 'Czech koruna',
        NAME: 'koruna',
        CURRENCIES: 'Currency you want to exchange',
        USD: 'USD',
        CHF: 'CHF',
        HKD: 'HKD',
        EUR: 'EUR',
        DKK: 'DKK',
        CAD: 'CAD',
        BGN: 'BGN',
        ZAR: 'ZAR',
        NOK: 'NOK',
        TND: 'TND',
        AUD: 'AUD',
        RON: 'RON',
        JPY: 'JPY',
        PLN: 'PLN',
        GBP: 'GBP',
        HUF: 'HUF',
        SEK: 'SEK',
        NZD: 'NZD',
        TRY: 'TRY',
        RUB: 'RUB'
      },
      COUNT: 'Filter'
    },
    TAB: {
      BRANCH: 'List of branches',
      ATM: 'List of ATMs',
      DEPOSIT_ATM: 'List of Deposit ATMs',
      TRANSFER_ATM: 'List of Payment ATMs',
      HC: 'List of Home loan centres',
      KC: 'List of Regional corporate centres',
      EXCHANGE: 'List of Exchange offices',
      OMC: 'List of Headquarters – sales points',
      COMPANY: 'List of Branches with company banker',
      CSP: 'List of Erste Premier centres',
      MAP: 'Map',
      PB: 'List of Erste Private Banking',
      ENCS: 'List of branches',
      MOBILE: 'List',
      LKC: 'List of branches',
      PERSONAL_SENIOR: 'List of branches with Exclusive banking'
    }
  }
}
