var angular = require('angular')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en
var tooltip = require('angular-ui-bootstrap/src/tooltip')
var format = require('date-fns/fp/format').default
require('../places-today-closing-in-hours/places-today-closing-in-hours.component.js')

angular.module('PlacesWeekOpeningHoursCmp', [tooltip, 'PlacesTodayClosingInHoursCmp'])
  .component('placesWeekOpeningHours', {
    template: require('./places-week-opening-hours.component.tpl.html'),
    controller: PlacesWeekOpeningHoursCtrl,
    controllerAs: 'vm',
    bindings: {
      placeType: '<',
      placeOpeningHours: '<'
    }
  })

PlacesWeekOpeningHoursCtrl.$inject = ['i18n']

function PlacesWeekOpeningHoursCtrl (i18n) {
  var vm = this

  vm.$onInit = function () {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
    vm.todayUpperCaseDayName = format('iiii')(new Date()).toUpperCase()
    vm.getWeekDayClasses = getWeekDayClasses
  }

  function getWeekDayClasses (dayName) {
    return {
      'csas-oh-today': dayName === vm.todayUpperCaseDayName,
      'csas-oh-week': !!~['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'].indexOf(dayName),
      'csas-oh-weekend': !!~['SATURDAY', 'SUNDAY'].indexOf(dayName)
    }
  }
}
