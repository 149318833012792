/* global angular */

angular.module('TooltipPositionerCmp', [])
  .directive('tooltipPositioner', tooltipPositioner)

tooltipPositioner.$inject = ['$window', '$interval']

function tooltipPositioner ($window, $interval) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var direction = getTooltipDirection(element[0])
      setDirectionClass(element[0], direction)

      $interval(function () {
        var direction = getTooltipDirection(element[0])
        setDirectionClass(element[0], direction)
      }, 500)
    }
  }

  function getTooltipDirection (element) {
    var clientRect = element.getBoundingClientRect()
    var vector = []
    vector.push({
      direction: 'left',
      size: clientRect.left
    })
    vector.push({
      direction: 'right',
      size: $window.innerWidth - clientRect.right
    })
    var sortedVector = vector.sort(function (a, b) {
      return b.size - a.size
    })

    if (sortedVector[0].size < getTooltipWidthPixels(element)) {
      return 'bottom'
    } else {
      return sortedVector[0].direction
    }
  }

  function setDirectionClass (element, direction) {
    var allDirections = ['left', 'right', 'top', 'bottom']
    allDirections.splice(allDirections.indexOf(direction), 1)
    allDirections.forEach(function (item) {
      element.classList.remove('csas-tooltip--' + item)
    })

    if (!element.classList.contains('csas-tooltip--' + direction)) {
      element.classList.add('csas-tooltip--' + direction)
    }
  }

  function getTooltipWidthPixels (element) {
    return parseFloat($window.getComputedStyle(element, ':after').getPropertyValue('width'))
  }
}
