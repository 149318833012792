exports.en = {
  PLACES_SEARCH: {
    TYPES: {
      BRANCH: 'Branches',
      ATM: 'ATMs',
      DEPOSIT_ATM: 'Deposit ATMs',
      TRANSFER_ATM: 'Payment ATMs',
      HC: 'Home loan centres',
      KC: 'Regional corporate centre',
      EXCHANGE: 'Exchange offices',
      OMC: 'Headquarters – sales points',
      COMPANY: 'Branches with business consulting services',
      CSP: 'Erste Premier centres',
      PB: 'Erste Private Banking',
      LKC: 'Large corporates',
      PERSONAL_SENIOR: 'Branches with Exclusive banking'
    },
    PLACEHOLDER: 'Enter street, town or post code',
    SEARCH: 'Search',
    TEXT_SEARCH: 'Search',
    ERROR_REQUIRED: 'Please fill required data'
  }
}
