var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesBranchOrder', {
    template: require('./places-branch-order.component.tpl.html'),
    controller: PlacesBranchOrderCtrl,
    controllerAs: 'vm',
    bindings: {
      phones: '<',
      cash: '<',
      desk: '<',
      branchRelativeUrl: '<',
      branchAppointmentsCrmUrl: '<',
      branchAppointmentsGeorgeUrl: '<'
    }
  })

PlacesBranchOrderCtrl.$inject = []

function PlacesBranchOrderCtrl () {
  var vm = this

  vm.$onInit = function () {
  }
}
