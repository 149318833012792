var angular = require('angular')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en
var _ = require('lodash')
//var tooltip = require('angular-ui-bootstrap/src/tooltip')

require('../../../places-today-closing-in-hours/places-today-closing-in-hours.component.js')
require('./places-opening-hours.compnent.css')

angular.module('PlacesDetailCmp').component('placesOpeningHours', {
  template: require('./places-opening-hours.component.tpl.html'),
  controller: PlacesOpeningHoursCtrl,
  controllerAs: 'vm',
  bindings: {
    branchOpeningHours: '<',
    cashdeskOpeningHours: '<',
    isCashdeskAvailable: '<',
    fullOpeningHours: '<',
    cashdeskFullOpeningHours: '<',
    isCashDeskOpeningHoursDifferent: '<'
  }
})

PlacesOpeningHoursCtrl.$inject = ['i18n']

const PREVIEW_OPENING_HOURS_LENGTH = 3
const PLACE_TYPE = {
  BRANCH: 'BRANCH',
  CASHDESK: 'CASHDESK'
}

function PlacesOpeningHoursCtrl(i18n) {
  var vm = this
  vm.activeTab = 'branchOpeningHours'
  vm.placeType = PLACE_TYPE.BRANCH
  vm.showOpeningHours = PREVIEW_OPENING_HOURS_LENGTH // +1 (array iteration)

  vm.$onInit = function() {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
  }

  vm.changeActiveTab = function(tab) {
    vm.activeTab = tab
    if (tab === 'branchOpeningHours') {
      vm.placeType = PLACE_TYPE.BRANCH
    } else {
      vm.placeType = PLACE_TYPE.CASHDESK
    }
  }

  vm.getDayRowClass = function(index) {
    if (index === 0) {
      return 'is-actual'
    }
    {
      return 'non-actual'
    }
  }

  vm.getDayClass = function(openingHoursLength) {
    if (!openingHoursLength) {
      return 'oh-current__day--holiday'
    } else {
      return 'oh-current__day'
    }
  }

  vm.showMore = function() {
    vm.showOpeningHours = vm[vm.activeTab].length
  }

  vm.showLess = function() {
    vm.showOpeningHours = PREVIEW_OPENING_HOURS_LENGTH
  }
}
