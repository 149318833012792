exports.cs = {
  PLACES_WEEK_OPENING_HOURS: {
    DAY_NAME: {
      MONDAY: 'Pondělí',
      TUESDAY: 'Úterý',
      WEDNESDAY: 'Středa',
      THURSDAY: 'Čtvrtek',
      FRIDAY: 'Pátek',
      SATURDAY: 'Sobota',
      SUNDAY: 'Neděle'
    },
    BRANCH: {
      CLOSED: 'Zavřeno'
    },
    SHOW_MORE: "Zobrazit více dní",
    SHOW_LESS: "Zobrazit méně dní",
    TAB_BRANCH: "Otevírací doba pobočky",
    TAB_CASHDESK: "Provozní doba pokladny"
  }
}
