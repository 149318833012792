export const en = {
  PLACES: {
    NAVIGATE_HTML: {
      BRANCH: 'Navigate<br>to branch',
      ATM: 'Navigate<br>to ATM'
    },
    PFCS: {
      TITLE: 'Pobočky České spořitelny s nabídkou Doplňkového penzijního spoření'
    },
    RKC: {
      LABEL: {
        MANAGER: 'ECB Director',
        MAIN: 'Kde nás najdete',
        SNIPPET_H1: 'Najděte nejbližší',
        SNIPPET_IN: 'v',
        CONTACT: 'Na koho se můžete přímo obrátit',
        PHONE: 'Univerzální číslo na pobočku'
      },
      OPENING: {
        OPEN_SHORT: 'Máme otevřeno',
        OPEN: 'Právě máme otevřeno'
      },
      BTN: {
        DETAIL: 'Zobrazit detail'
      }
    },
    ERROR: {
      LOCATION: 'The requested location was not found',
      REQUIRED: 'Please fill required data',
      BACKEND:
        'We apologise, but the application is momentarily unavailable. Please contact us toll free on 800 207 207.'
    },
    APPT: {
      ALREADY_ORDERED: 'Today you have already made an appointment at a different branch',
      NOT_CREATED: 'The appointment cannot be created'
    },
    URL: {
      SEARCH: 'search',
      ATM: 'ATM',
      BRANCH: 'branch',
      ORDER: 'appointment',
      CASH: 'cash',
      CASHLESS: 'cashless'
    },
    TITLE: 'Branches and ATMs',
    PLACEHOLDER: 'Enter street, town or post code',
    SERVICE: {
      ACTIVATION_CODE: 'Activation code print',
      CONTACTLESS_ATM: 'Contactless',
      CONTACTLESS_SYSTEM_PILSEN: 'Topping up transport cards',
      WEEKEND_OFFICE: 'Weekend hours',
      WITHDRAW_ATM: 'ATM for withdrawals',
      DEPOSIT_ATM: 'ATM for deposits',
      TRANSFER_ATM: 'ATM for transfers',
      BAR_CODE_READER_ATM: 'Barcode reader',
      QR_CODE_READER_ATM: 'QR code reader',
      FOR_BLIND_ATM: 'For the visually impaired',
      ATM_ACCESSIBILITY: 'Barrier-free access',
      HEARING_IMPAIR: 'Equipment for the hearing impaired',
      BARRIER_PARTIALY_FREE: 'Partially barrier-free access',
      BARRIER_FREE: 'Barrier-free access',
      ATM: 'ATM in building',
      SELF_SERVICE: 'Self-service zone',
      CASH_DESK: 'Branch with a cash desk',
      EQUIPMENT_17: 'Mincomat coin deposit machine',
      EQUIPMENT_13: 'Special application for the hearing-impaired',
      EQUIPMENT_24: 'ATM for deposits at the branch',
      EQUIPMENT_12: 'Dual ATM (deposits and withdrawals)',
      EQUIPMENT_23: 'ATM for withdrawals at the branch',
      EQUIPMENT_11: 'ATM for payments at the branch',
      EQUIPMENT_25: 'Dual ATM (deposits and withdrawals)',
      EQUIPMENT_9: 'Children’s corner',
      EQUIPMENT_7: 'WiFi',
      EQUIPMENT_6: 'Night safe',
      EQUIPMENT_1: 'Safety deposit box',
      EQUIPMENT_20: 'Offers English language',
      EQUIPMENT_16: 'For the visually impaired',
      EXCHANGE: 'Exchange',
      EXCHANGE_TOOLTIP: 'At this branch, we exchange these currencies: ',
      DEPOSIT_TOOLTIP: 'At this branch you can deposits to / withdrawals from your accounts these currencies: ',
      DEPOSITS: 'Foreign Currency Deposits/Withdrawals',
      'Akcie, dluhopisy a služby CDCP': 'Shares, Bonds and CDCP Services',
      'Bezpečnostní schránky': 'Safe Deposit Boxes',
      'Dětské a studentské produkty': 'Products for Children and Students',
      'Dětské a studentské produkty (mimo vkladních knížek)':
        'Products for Children and Students (excluding passbooks)',
      'Hypotéka Premier': 'Premier Home Loan',
      Hypotéky: 'Mortgages',
      'Investiční produkty': 'Investment Products',
      'Karty na nákupy': 'Cards for Purchases',
      'Letištní salónky': 'Airport Lounges',
      Mincomat: 'Mincomat Coin Deposit Machine',
      'Moje zdravé finance': 'My Healthy Finances',
      'Účet Premier': 'Premier Account',
      'Osobní účty': 'Personal Accounts',
      'Penzijní připojištění': 'Supplementary Pension Scheme',
      'Platební karty': 'Payment Cards',
      'Platební karty /Visa Infinit, MC World Elite/': 'Payment Cards (Visa Infinite, MC World Elite)',
      'Podnikatelské termínované vklady': 'Business Term Deposits',
      'Podnikatelské účty': 'Business Accounts',
      'Podnikatelské úvěry': 'Business Loans',
      'Pojistné produkty': 'Insurance Products',
      'Premier benefit club': 'Premier Benefit Club',
      Půjčky: 'Loans',
      'Směnárenské služby': 'Foreign Exchange Services',
      'Spoření Premier': 'Premier Savings Account',
      'Spořicí a vkladové produkty': 'Savings and Deposit Products',
      'Spořicí a vkladové produkty (mimo vkladních knížek)': 'Savings and Deposit Products (excluding passbooks)',
      'Stavební spoření': 'Building Savings Account',
      'Úvěr Premier': 'Premier Loan',
      'Vklady a výběry cizí měny z účtu': 'Foreign Currency Deposits/Withdrawals',
      'Vklady a výběry hotovosti': 'Cash Deposits and Withdrawals',
      'Vklady a výběry pouze prostřednictvím bankomatu': 'Cash deposit and withdrawal via ATMs only',
      'Vklady a výběry hotovosti pouze prostřednictvím bankomatu': 'Cash deposit and withdrawal via ATMs only',
      'Výběry hotovosti pouze prostřednictvím bankomatu': 'Cash withdrawals Only via ATM',
      'Výběry pouze prostřednictvím bankomatu': 'Cash withdrawals Only via ATM',
      'Životní pojištění': 'Life Insurance',
      'Vklad Z-obalem': 'Cash deposit in sealed packages'
    },
    QUICK: {
      BRANCH: {
        OPEN_NOW: 'Open right now',
        CASH_DESK: 'With cash desk',
        NO_CASH_DESK: 'Without cash desk',
        UNTIL: 'until',
        NEWLY: 'Newly',
        HANDICAP: 'For the disabled',
        BARRIER_FREE: 'Barrier-free access',
        HEARING_IMPAIR: 'Special application for the hearing-impaired',
        DIGITAL_VOICE: 'Voice navigation for the visually impaired',
        OPEN_WEEKEND: 'Open at weekends',
        PARKING: 'Convenient parking in the area',
        DEPOSIT: 'I’d like to make a deposit',
        PAYMENT: 'I’d like to make a payment',
        GOLD: 'With safety deposit boxes',
        OUT_OF_SERVICE: 'Out of service'
      },
      ATM: {
        NEW_ATM: 'New ATM',
        AVAILABLE_NOW: 'Available right now',
        HANDICAP: 'For the disabled',
        BARRIER_FREE: 'Barrier-free access',
        ATM_ACCESSIBILITY: 'Barrier-free access',
        FOR_BLIND_ATM: 'ATM for the visually impaired',
        DEPOSIT: 'I’d like to make a deposit',
        PAYMENT: 'I’d like to make a payment',
        TRANSFER_ATM: 'ATM for transfers',
        QR_CODE_READER_ATM: 'QR code reader',
        BAR_CODE_READER_ATM: 'Barcode reader',
        CONTACTLESS_SYSTEM_PILSEN: 'Transport card credit'
      }
    },
    ORDER: {
      NOT_FOUND: 'Appointment not found',
      FORM: {
        TITLE: 'Chosen date',
        TITLE_CASH: 'Appointment at cash desk',
        TITLE_CASHLESS: 'Appointment with a banker',
        TITLE_FORM: 'Enter your contact information so we identify you:',
        AT: 'at',
        SERVICE: 'Service',
        NAME: 'Name',
        LASTNAME: 'Surname',
        PHONE: 'Telephone',
        PHONE_INFO: 'To send your queue number.',
        EMAIL: 'E-mail',
        EMAIL_INFO: 'To send confirmation of appointment or cancellation.',
        PHONE_PLACEHOLDER: 'XXX XXX XXX',
        PHONE_ERROR: 'Phone Number format XXX XXX XXX',
        FORM_BTN: 'Make an appointment at a branch',
        SUCCESS: {
          TITLE: 'Your appointment has been arranged successfully. What now?',
          PLANNED_AT: 'Selected date and time',
          ORDER_NUMBER_ON_PHONE:
            'We will send a <strong>queue number</strong> in a text message no later than 30 minutes in advance of your visit to your phone %{phone}. You do not need to generate a new queue number.',
          ORDER_CONFIRMATION_ON_EMAIL:
            'Confirmation of your appointment has been sent to your e-mail <strong>%{email}</strong>. The message also contains a link to cancel the appointment.',
          CORRECT_MISTAKE:
            'If you have selected the wrong time or place, you can cancel your appointment by following the link below:',
          CANCEL: 'Cancel appointment'
        },
        CANCEL: {
          TITLE: 'You have successfully cancelled your appointment.',
          ORDER_CANCEL_ON_EMAIL:
            'We have sent confirmation of cancellation of your appointment to your e-mail <strong>%{email}</strong>.',
          BRANCH_DETAIL: 'Back to the branch details'
        }
      },
      CANCEL: {
        TITLE: 'Appointment cancellation',
        SUCCESS: 'Appointment cancellation was successful',
        BUTTON: 'Cancel appointment'
      },
      TITLE: 'Arrange an appointment at a branch on-line',
      SELECT_TIME: 'Choose a time:',
      FULL: 'Full',
      MORNING: 'Morning',
      NOON: 'Mid-morning',
      AFTERNOON: 'Afternoon',
      EVENING: 'Evening',
      STATE: {
        FREE: 'Free',
        NOT_FEASIBLE: 'Unavailable',
        TIME_NOT_FEASIBLE: 'Time not feasible',
        FREE_APPT_SLOTS: 'Free',
        NO_APPT_SLOTS: 'Full',
        BRANCH_CLOSED: 'Closed'
      }
    },
    TEXT: {
      LIST: {
        ACTIVE: 'is active |||| are active |||| are active',
        FILTER: 'filter |||| filters |||| filters',
        LAST: 'You last searched for',
        RESULT_FOUND: 'Found',
        RESULT_LOCALITY: 'at the location',
        RESULT_ITEMS: 'item |||| items |||| items',
        NOT_FOUND_FILTERS: 'No ATMs match the filter'
      },
      DETAIL: {
        NOTE: 'Poznámka',
        STREET_VIEW: 'Street view',
        GALLERY: 'Gallery',
        MANAGER: 'Branch Manager',
        READ_MORE: 'read more...',
        MAP: 'Show on map',
        ORDER1: 'Save time',
        ORDER2: 'at a branch',
        ORDER_CASH: 'I’d like to see a customer service officer',
        ORDER_DESK: 'Arrange to see a banker',
        CASH_LIMIT: 'Cash withdrawal limit',
        CASH_LIMIT_EXCESS1: 'In order to withdraw a higher amount, please notify the branch',
        CASH_LIMIT_EXCESS_LINK: '',
        CASH_LIMIT_EXCESS2: '',
        CASH_LIMIT_EXCESS3: ' in advance of your branch visit.',
        PRODUCTS: 'products',
        PRE_PRODUCTS: 'We will advise you at a branch about these',
        PRODUCTS_LIMIT: 'List all products',
        CALL_BOX:
          'We will also be happy <strong>to advise you</strong> by <span class="csas-nobr">e-mail</span> or telephone.',
        PRE_CALL: 'Do you need advice?',
        CALL_INFO: 'We are available 24 hours a day, 7 days a week.',
        CALL: 'Call',
        EMAIL: 'or contact us via e-mail',
        NEXT: 'Show next',
        SPECIALISTS: 'Specialists at the branch',
        ATM_PRODUCTS: 'This ATM can help you with:',
        ATM_NR: 'ATM number:',
        DISTANCE: 'Distance',
        TODAY: 'Today',
        OPEN: 'open',
        STATUS: {
          BRANCH: {
            CLOSING: 'The branch closes soon',
            CLOSING_SHORT: 'The branch will close shortly',
            CLOSED: 'The branch is closed now',
            CLOSED_SHORT: 'Closed',
            OPEN: 'Open',
            OPEN_FOR:
              'Still open <strong>%{smart_count} hour</strong>. |||| Still open <strong>%{smart_count} hours</strong>.',
            OPEN_FOR_SHORT:
              'Still <strong>%{smart_count} hour</strong>. |||| Still <strong>%{smart_count} hours</strong>.',
            TODAY: 'Open today for',
            CASH_DESK_OPEN: 'Cashdesk operation for'
          },
          ATM: {
            CLOSING: 'The ATM unavailable soon',
            CLOSING_SHORT: 'The ATM is unavailable shortly',
            CLOSED: 'The ATM is unavailable now',
            CLOSED_SHORT: 'Unavailable',
            OPEN: 'Available',
            OPEN_FOR:
              'Still available <strong>%{smart_count} hour</strong>. |||| Still available <strong>%{smart_count} hours</strong>.',
            OPEN_FOR_SHORT:
              'Still <strong>%{smart_count} hour</strong>. |||| Still <strong>%{smart_count} hours</strong>.',
            TODAY: 'Available toady for'
          },
          EMPLOYEE: 'Only <strong>for employees</strong>',
          OUT_OF_ORDER: 'Out of order'
        },
        BACK: {
          TO_HOME: 'Back to branch search',
          TO_DETAIL: 'Back to branch details',
          TO_LIST: 'Back to list',
          TO_ORDER: 'Back to time selection'
        },
        DB: {
          'bezhotovostní pobočka': 'cashless branch',
          '2 dny, s výčetkou 3 dny': '2 days, 3 days with a bill of cash',
          '1': '1',
          '2': '2',
          '4': '4',
          '3': '3',
          '5': '5',
          '1 týden': '1 week',
          '48 hodin': '48 hours',
          '1 pracovní den': '1 business day',
          '2 pracovní dny': '2 business days',
          '3 pracovní dny': '3 business days',
          '4 pracovní dny': '4 business days',
          '5 pracovních dnů': '5 business days',
          '2 dny předem': '2 days in advance',
          '1 den': '1 day',
          '2 dny': '2 days',
          '3 dny': '3 days',
          '4 dny': '4 days',
          '5 dnů': '5 days',
          'individualně/mail na pobočku': 'individually'
        }
      }
    },
    BTN: {
      CANCEL_FILTER: 'Cancel filters',
      NAVIGATE: {
        BRANCH: 'Navigate to branch',
        ATM: 'Navigate to ATM'
      },
      SELECT: 'Select',
      SEARCH: 'Search',
      ATM: {
        DETAIL: 'ATM details',
        MORE: 'List more ATMs'
      },
      BRANCH: {
        DETAIL: 'Branch details',
        MORE: 'List more branches'
      },
      MAP: {
        SHOW: 'Show map',
        HIDE: 'Show list',
        ME: 'My position'
      },
      LIST: {
        SHOW: {
          BRANCH: 'Show list of branches',
          ATM: 'Show list of ATMs'
        }
      },
      ANOTHER_DAYS: 'Other days',
      ORDER: {
        PLACEHOLDER: 'Make an appoint',
        CASH: 'At a customer service officer',
        DESK: 'With a banker',
        GEORGE: 'With my banker in George',
        NEXT_WEEK: 'Next week',
        PREV_WEEK: 'Previous week'
      }
    },
    TYPES: {
      BRANCHES: 'Branches',
      ATMS: 'ATMs',
      NEXT: 'Next',
      DEPOSIT_ATM: 'Deposit ATMs',
      TRANSFER_ATM: 'Payment ATMs',
      HC: 'Home loan centres',
      KC: 'Regional corporate centre',
      EXCHANGE: 'Exchange offices',
      OMC: 'Headquarters – sales points',
      COMPANY: 'Branches with company banker',
      PREMIER: 'Erste Premier centres'
    },
    DAYS: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      TOOLTIP: 'Otevírací doba pro den'
    }
  },
  CZK: 'CZK'
}
