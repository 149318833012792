var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesAtmServices', {
    template: require('./places-atm-services.component.tpl.html'),
    controller: PlacesAtmServicesCtrl,
    controllerAs: 'vm',
    bindings: {
      services: '<'
    }
  })

PlacesAtmServicesCtrl.$inject = []

function PlacesAtmServicesCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.limit = 3
  }
}
