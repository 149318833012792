var angular = require('angular')
require('angular-sanitize')
require('aem-libs-angularjs/src/directives/track-element.directive.js')
var placesService = require('../../services/places.service.js').placesService
var qmaticService = require('aem-libs-angularjs/src/services/qmatic.service.js').qmaticService
require('../places-today-closing-in-hours/places-today-closing-in-hours.component.js')
var placesOpeningHoursService = require('../../services/places-opening-hours.service.js').placesOpeningHoursService
var format = require('date-fns/fp/format').default

angular.module('lastCmp', ['TrackElementCmp', 'ngSanitize', 'PlacesTodayClosingInHoursCmp'])
  .factory('placesOpeningHoursService', placesOpeningHoursService)
  .factory('placesService', placesService)
  .factory('qmaticService', qmaticService)
  .component('last', {
    template: require('./last.component.tpl.html'),
    controller: LastCtrl,
    controllerAs: 'vm',
    bindings: {
      widgetParams: '<'
    }
  })

LastCtrl.$inject = ['wgService', 'placesService', '$window', '$filter', 'i18n', 'placesOpeningHoursService']

function LastCtrl (wgService, placesService, $window, $filter, i18n, placesOpeningHoursService) {
  var vm = this

  var lastBranchKey = 'LAST_BRANCH'
  var lastATMKey = 'LAST_ATM'

  vm.$onInit = function () {
    vm.ecb = wgService.getAppName().indexOf('Ecb') !== -1
    vm.csps = wgService.getAppName().indexOf('Csps') !== -1
    vm.today = {}
    vm.validCashDeskLabel = false
    vm.locationHash = $window.location.hash
    vm.branchAppointmentsCrmUrl = null
    vm.isAppointmentsVisibile = isAppointmentsVisibile
    vm.hasCashDesk = false
    vm.cashDeskLabel = ''
    vm.georgeAppointmentUrl = vm.widgetParams && vm.widgetParams.georgeAppointmentUrl

    if (vm.ecb) {
      lastBranchKey += '_ECB'
      lastATMKey += '_ECB'
    } else if (vm.csps) {
      lastBranchKey += '_CSPS'
      lastATMKey = undefined
    }
    loadLast()
  }

  function setCashDeskLabel() {
    if(vm.hasCashDesk){ // má pokladnu
      if(vm.data.cashUntil) { // je vyplněno, do kdy má pokladnu
        var cashUntilTime = Math.floor(new Date(vm.data.cashUntil).getTime() / (1000 * 60 * 60 * 24))
        var toDayTime = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24))
        var differenceInDays = cashUntilTime - toDayTime
        if ((cashUntilTime >= toDayTime) && (differenceInDays <= 30)) { // datum do kdy má pokladnu je v budoucnu max 30 dní
          var label = $filter('translate')('PLACES.QUICK.BRANCH.UNTIL')
          vm.cashDeskLabel = label.toUpperCase() + ' ' + format('d. M. yyyy')(new Date(vm.data.cashUntil) )
        } else { // datum je v minulosti
          vm.cashDeskLabel = ''
        }
      } else { // není vyplněno do kdy má pokladnu
        vm.cashDeskLabel = ''
      }
    } else { // nemá pokladnu
      if(vm.data.cashUntil) { // je vyplněno, do kdy měla pokladnu
        var differenceInDays = (new Date().getTime() - new Date(vm.data.cashUntil).getTime()) / (1000 * 60 * 60 * 24)
        if(differenceInDays <= 14){ // datum do kdy měl pokladnu není starší než 14 dní
          vm.cashDeskLabel = $filter('translate')('PLACES.QUICK.BRANCH.NEWLY')
        } else { // datum je starší než 14 dní
          vm.cashDeskLabel = ''
        }
      } else { // není vyplněno do kdy má pokladnu
        vm.cashDeskLabel = ''
      }
    }
  }

  function getRedirectUrl (hash) {
    var targetUrl = ''
    var targetUrlparam = wgService.getParams().widgetUrl
    if (targetUrlparam && angular.isString(targetUrlparam)) {
      targetUrl = targetUrlparam
    } else if (angular.isObject(targetUrlparam) && targetUrlparam[i18n.langCode]) {
      targetUrl = targetUrlparam[i18n.langCode]
    } else if (angular.isObject(targetUrlparam) && targetUrlparam['cs']) {
      targetUrl = targetUrlparam['cs']
    }
    targetUrl = targetUrl.split('#')[0]
    return targetUrl + hash
  }

  function loadLast () {
    var id = window.localStorage[lastBranchKey]

    const filterOutagesForType = function(types) {
      return vm.data.outages.filter(function(outage) {return types.includes(outage.outageType)})
    }
    if (angular.isDefined(id)) {
      placesService.branchDetail(id).then(function(response) {
        vm.data = response.data
        vm.data = placesService.processBranchObject(vm.data)
        vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, filterOutagesForType(placesOpeningHoursService.outagesTypes), vm.data.accessType)
        vm.placeCashDeskOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, filterOutagesForType(placesOpeningHoursService.outagesCashdeskTypesOnly), vm.data.accessType)
        vm.cashDeskOpeningHours = placesOpeningHoursService.normalize(vm.data.cashdesk_opening_hours, filterOutagesForType(placesOpeningHoursService.outagesCashdeskTypesOnly), vm.data.accessType)
        vm.url = getRedirectUrl(placesService.getDetailUrl(vm.data, $filter('translate')('PLACES.URL.' + vm.data.type.toUpperCase())))
        var gtmServices = []
        for (var i = 0; i < vm.data.services.length; i++) {
          if ((vm.data.services[i].type === 'BRANCH_TYPE' && vm.data.services[i].flag === 'POBOCKY')) {

            vm.validCashDeskLabel = true
          }
        }

        for (var i = 0; i < vm.data.sortedServices.length; i++) {

          gtmServices.push($filter('translate')('PLACES.SERVICE.' + vm.data.sortedServices[i].flag))
          if (vm.data.sortedServices[i].flag === 'CASH_DESK') {
            vm.hasCashDesk = true
            vm.data.sortedServices.splice(i,1)
          }
        }
        setCashDeskLabel()
        setBranchAppointmentsCrmUrl()
      })
    } else if (angular.isDefined(lastATMKey)) {
      id = window.localStorage[lastATMKey]
      if (angular.isDefined(id)) {
        placesService.atmDetail(id).then(function (response) {
          vm.data = response.data
          vm.data = placesService.processATMObject(vm.data)
          vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
          vm.url = getRedirectUrl(placesService.getDetailUrl(vm.data, $filter('translate')('PLACES.URL.' + vm.data.type.toUpperCase())))
          var gtmServices = []
          for (var i = 0; i < vm.data.sortedServices.length; i++) {
            gtmServices.push($filter('translate')('PLACES.SERVICE.' + vm.data.sortedServices[i].flag))
          }
        })
      } else {
        delete vm.data
      }
    }
  }

  function setBranchAppointmentsCrmUrl () {
    var urlToBranchAppointments = vm.widgetParams && vm.widgetParams.branchAppointmentsCrmUrl && vm.widgetParams.branchAppointmentsCrmUrl[i18n.langCode]
    if (urlToBranchAppointments) {
      urlToBranchAppointments += 'branch/' + vm.data.id
    }

    vm.branchAppointmentsCrmUrl = isBranchAppointmentAvailable() && urlToBranchAppointments
  }
  function isBranchAppointmentAvailable () {
    var hasPobockyFlag = vm.data.services && vm.data.services.some(function (service) {
      return service.flag === 'POBOCKY'
    })
    return vm.data.orderToBranch && hasPobockyFlag && vm.data.marketPlaceId
  }

  function isAppointmentsVisibile () {
    return vm.data.qmatic || vm.branchAppointmentsCrmUrl
  }
}
