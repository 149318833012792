exports.cs = {
  PLACES_SEARCH: {
    TYPES: {
      BRANCH: 'Pobočky',
      ATM: 'Bankomaty',
      DEPOSIT_ATM: 'Vkladové bankomaty',
      TRANSFER_ATM: 'Platbomaty',
      HC: 'Hypoteční centra',
      KC: 'Regionální korporátní centra',
      EXCHANGE: 'Směnárny',
      OMC: 'Obchodní místa centrály',
      COMPANY: 'Pobočky s poradenstvím pro podnikání',
      CSP: 'Erste Premier centra',
      PB: 'Erste Private Banking',
      LKC: 'Velké a nadnárodní korporace',
      PERSONAL_SENIOR: 'Pobočky s bankovnictvím Exclusive'
    },
    PLACEHOLDER: 'Zadejte ulici, město nebo PSČ',
    SEARCH: 'Hledat',
    TEXT_SEARCH: 'Hledáte',
    ERROR_REQUIRED: 'Zadejte prosím požadované údaje'
  }
}
