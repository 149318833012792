exports.en = {
  PLACES_TODAY_CLOSING_IN_HOURS: {
    BRANCH: {
      CLOSING_SOON: 'The branch <strong>closes soon</strong>',
      CLOSED: 'The branch <strong>is closed today</strong>',
      CLOSED_NOW: 'The branch <strong>is closed now</strong>',
      PAUSED_NOW: 'We will open the branch <strong>again soon</strong>',
      CLOSED_SHORT: 'Closed',
      EARLY_CLOSING: 'The branch <strong>will close shortly.</strong>',
      WILL_BE_OPEN_STILL:
        'Still open <strong>%{smart_count} hour</strong>. |||| Still open <strong>%{smart_count} hours</strong>.',
      TODAY: 'Open today for'
    },
    ATM: {
      CLOSING_SOON: 'The ATM unavailable soon',
      CLOSED: 'The ATM is unavailable',
      CLOSED_NOW: 'The ATM is unavailable now',
      PAUSED_NOW: 'The ATM will be available soon',
      CLOSED_SHORT: 'Unavailable',
      EARLY_CLOSING: 'The ATM <strong>will be closed soon</strong>',
      AVAILABLE_NONSTOP: 'Available <strong>Nonstop</strong>',
      WILL_BE_OPEN_STILL:
        'Still available <strong>%{smart_count} hour</strong>. |||| Still available <strong>%{smart_count} hours</strong>.',
      TODAY: 'Available today for'
    },
    CASHDESK: {
      CLOSING_SOON: 'The cash desk <strong>closes soon</strong>',
      CLOSED: 'The cash desk <strong>is closed</strong>',
      CLOSED_NOW: 'The cash desk <strong>is closed now</strong>',
      PAUSED_NOW: 'We will open the cash desk <strong>again soon</strong>',
      CLOSED_SHORT: 'Closed',
      EARLY_CLOSING: 'The cash desk <strong>will close shortly.</strong>',
      WILL_BE_OPEN_STILL:
        'Still open <strong>%{smart_count} hour</strong>. |||| Still open <strong>%{smart_count} hours</strong>.',
      TODAY: 'Open today for'
    },
  }
}
