/* global angular */

contentService.$inject = ['$http', 'apiBase', '$q', 'apiKey', 'i18n']

function contentService ($http, apiBase, $q, apiKey, i18n) {
  var endpointPath = apiBase + 'v2/cms'

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  function getBlob (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getStaticBlob (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/cont?web-api-key=' + apiKey),
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'WEB-API-key': undefined
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getBlobUrl (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise,
      transformResponse: appendTransform($http.defaults.transformResponse, toImageUrlTransformResponse)
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getStaticBlobUrl (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/cont?web-api-key=' + apiKey),
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'WEB-API-key': undefined
      },
      transformResponse: appendTransform($http.defaults.transformResponse, toImageUrlTransformResponse)
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function appendTransform (defaults, transform) {
    defaults = angular.isArray(defaults) ? defaults : [defaults]
    return defaults.concat(transform)
  }

  function toImageUrlTransformResponse (blob, headerGetter) {
    var urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(blob)
  }

  function getHtml (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      cache: true,
      timeout: canceller.promise,
      params: {
        format: 'html'
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getStaticHtml (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/cont?web-api-key=' + apiKey),
      method: 'GET',
      cache: true,
      timeout: canceller.promise,
      headers: {
        'WEB-API-key': undefined
      },
      params: {
        format: 'html',
        lang: i18n.langCode
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getMetaTeaser (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/meta'),
      method: 'GET',
      cache: true,
      timeout: canceller.promise,
      params: {
        groups: 'TEASER'
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  return {
    getBlobUrl: getBlobUrl,
    getStaticBlobUrl: getStaticBlobUrl,
    getBlob: getBlob,
    getStaticBlob: getStaticBlob,
    getHtml: getHtml,
    getStaticHtml: getStaticHtml,
    getMetaTeaser: getMetaTeaser
  }
}

exports.contentService = contentService
