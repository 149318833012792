qmaticService.$inject = ['$http', 'apiBase', '$q']

function qmaticService ($http, apiBase, $q) {
  var endpointPath = apiBase + 'v2/qmatic'

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  return {
    services: function (id, lang) {
      return $http({
        url: endpoint('branches/' + id + '/services/' + lang),
        method: 'GET',
        cache: true
      })
    },
    availabilities: function (id, serviceId, date) {
      return $http({
        url: endpoint('branches/' + id + '/appointments/' + serviceId + '/' + date),
        method: 'GET'
      })
    },
    add: function (data) {
      return $http({
        url: endpoint('webappointment'),
        method: 'POST',
        data: data
      })
    },
    appointment: function (hash) {
      return $http({
        url: endpoint('appointments/' + hash),
        method: 'GET'
      })
    },
    cancel: function (hash) {
      return $http({
        url: endpoint('appointments/' + hash),
        method: 'DELETE'
      })
    }
  }
}

exports.qmaticService = qmaticService
