/* global angular */

angular.module('AemBranches')
  .controller('aemBranchesCtrl', ctrl)

ctrl.$inject = ['placesService', 'appLoader', '$timeout', '$location', 'googleMapsService', '$q',
  'widgetParamsService', 'wgService', 'userTracking', 'placesStateService'
]

function ctrl (placesService, appLoader, $timeout, $location, googleMapsService, $q,
  widgetParamsService, wgService, userTracking, placesStateService) {
  var vm = this

  var searchResults = {
    page: 0,
    nextPage: 0,
    totalPages: 0,
    totalCount: 0,
    showNextPage: false
  }

  var searchParams = {
    size: 10,
    sort: 'distance',
    detail: 'FULL'
  }

  vm.$onInit = function () {
    vm.showContent = false
    vm.onSearch = onSearch
    vm.nextPage = nextPage
    vm.showSearch = false
    vm.showSearchResult = false
    vm.showDetail = false
    vm.widgetParams = null
    vm.isErrorBackend = false
    vm.hideResultCount = false
    vm.searchResults = angular.copy(searchResults)
    vm.locationSet = false

    document.title = 'Pobočky a bankomaty | Česká spořitelna'

    $q.all([
      googleMapsService.initPromise(),
      widgetParamsService.get(wgService.getAppName())
    ]).then(function (res) {
      vm.widgetParams = res[1].data
      vm.showContent = true
      onRouteChanged()
    }).catch(function () {
      vm.isErrorBackend = true
    }).finally(appLoader.hide())
  }

  function onRouteChanged () {
    var routeData = $location.search()

    vm.showSearch = false
    vm.showDetail = false
    vm.showOrder = false

    $timeout(function () {
      vm.showSearch = true

      if (routeData.address && (routeData.flag || routeData.type)) {
        vm.showSearchResult = true
      }

      if (routeData.id) {
        vm.showOrder = routeData.order
        vm.showDetail = !routeData.order
        vm.showSearchResult = false
        vm.showSearch = false
      }
    })
  }

  function onSearch () {
    vm.searchResults = angular.copy(searchResults)
    getLocation()
  }

  function nextPage () {
    if (vm.searchResults.nextPage) {
      vm.searchResults.page = vm.searchResults.nextPage
      getList()
    }
  }

  function getList () {
    vm.loading = true
    vm.notFound = false
    var request = angular.copy(searchParams)
    request.page = vm.searchResults.page
    request.lat = vm.lat
    request.lng = vm.lng
    request.types = placesStateService.getState().placeType
    request.flags = placesStateService.getFlags()
    if (request.flags.indexOf('HC') === -1) {
      request.radius = 50000
    }
    vm.bgFilter = request.flags.indexOf('HC') === -1 || request.flags.indexOf('PB') === -1 || request.flags.indexOf('CSP') === -1 || request.flags.indexOf('KC') === -1 || request.flags.indexOf('LKC') === -1
    vm.bgNoFilter = request.flags.indexOf('HC') > -1 || request.flags.indexOf('PB') > -1 || request.flags.indexOf('CSP') > -1 || request.flags.indexOf('KC') > -1 || request.flags.indexOf('LKC') > -1

    if (placesStateService.getState().searchType === 'BRANCH') {
      request.currencies = placesStateService.getCurrencies()
    }
    request.country = 'CZ'

    if (request.flags && ~request.flags.indexOf('OPEN_NOW')) {
      request.openTime = 'now'
      request.flags.splice(request.flags.indexOf('OPEN_NOW'), 1)
    }

    userTracking.onRequest(request)
    placesService.search(request)
      .then(function (response) {
        vm.isErrorBackend = false
        vm.searchResults.showNextPage = false
        for (var i = 0; i < response.data.items.length; i++) {
          response.data.items[i].services = response.data.items[i].services.filter(function (service) {
            return service.flag !== 'BARRIER_FREE_OR_BARRIER_PARTIALY_FREE'
          })
        }
        if (response.data.pageNumber === 0) {
          vm.searchResults.list = response.data.items
        } else {
          vm.searchResults.list = vm.searchResults.list.concat(response.data.items)
        }
        vm.searchResults.page = response.data.pageNumber
        vm.searchResults.nextPage = response.data.nextPage
        vm.searchResults.totalPages = response.data.pageCount
        vm.searchResults.address = placesStateService.getAddress()
        vm.searchResults.totalCount = response.data.totalItemCount
        if (response.data.nextPage) {
          vm.searchResults.showNextPage = true
        }
      })
      .catch(function () {
        vm.isErrorBackend = true
      })
      .finally(function () {
        vm.loading = false
      })
  }

  function getLocation () {
    vm.locationError = false
    var geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({
      address: placesStateService.getAddress(),
      componentRestrictions: {
        country: 'CZ'
      }
    }, function (results, status) {
      // ChIJQ4Ld14-UC0cRb1jb03UcZvg - "Česká republika" Google Place ID
      if (status === window.google.maps.GeocoderStatus.OK && results[0].place_id !== 'ChIJQ4Ld14-UC0cRb1jb03UcZvg') {
        vm.lat = results[0].geometry.location.lat()
        vm.lng = results[0].geometry.location.lng()
        vm.locationSet = true
        getList()
      } else {
        vm.locationError = true
        console.warn('Geocode was not successful for the following reason: ' + status)
      }
    })
  }
}
