exports.cs = {
  PLACES_DETAIL: {
    ATM: {
      NUMBER: 'Číslo bankomatu:',
      SERVICES: {
        TITLE: 'S čím vám tento bankomat pomůže?',
        SHOW_MORE: 'Zobrazit více'
      },
      OUT_OF_ORDER: 'Bankomat je mimo provoz',
      EMPLOYEES: 'Bankomat je dostupný pouze pro zaměstnance s přístupem do objektu.'
    },
    BRANCH: {
      PRODUCTS: {
        TITLE: '<strong>S čím vám naši poradci</strong> na této pobočce pomůžou?',
        SHOW_MORE: 'Zobrazit více'
      },
      SPECIALISTS: {
        TITLE: 'Specialisté',
        SHOW_MORE: 'Zobrazit další',
        SHOW_LESS: 'Zmenšit výpis'
      },
      LKC_SPECIALISTS: {
        TITLE: 'Manažeři',
        SHOW_MORE: 'Zobrazit další',
        SHOW_LESS: 'Zmenšit výpis'
      },
      LKC_MANAGER: {
        POSITION: 'Ředitel korporátní klientely',
        PROFILE: 'Profil'
      },
      RKC_MANAGER: {
        POSITION: 'Ředitel RKC',
        PROFILE: 'Profil'
      },
      MANAGER: {
        TITLE: '<strong>Manažer</strong> pobočky',
        POSITION: 'manažer pobočky',
        PROFILE: 'Profil manažera'
      },
      CALLBOX: {
        TITLE: '<strong>Potřebujete poradit</strong> před návštěvou pobočky?',
        CHAT: 'Spustit chat'
      },
      ORDER: {
        TITLE: '<strong>Objednejte se on-line</strong> a&nbsp;ušetřete čas na pobočce',
        ORDER_TO: 'Objednat se',
        CASH: 'Na pokladnu',
        DESK: 'K bankéři na pobočce',
        GEORGE: 'K mému bankéři v Georgi',
        QMATIC_URL_CASH: 'objednani/hotovost',
        QMATIC_URL_DESK: 'objednani/bezhotovost'
      },
      OUT_OF_ORDER: 'Pobočka je mimo provoz',
      OUTAGE_NEXT_WEEK_1: 'V příštím týdnu je plánovaná',
      OUTAGE_NEXT_WEEK_2: 'změna otevírací doby.',
      CLOSED_NEXT_WEEK: 'Pobočka bude zavřená',
      SHOW: 'Zobrazit',
      HIDE: 'Skrýt'
    },
    NOTES: {
      CASH_WITHDRAW: {
        CASH_LIMIT: 'Potřebujete na této pobočce vybrat větší množství hotovosti?',
        CASH_LIMIT_EXCESS1: 'Ověřte si prosím limity pro výběr a ',
        CASH_LIMIT_EXCESS2: 'nahlaste jej pobočce předem.',
        CASH_LIMIT_EXCESS3: 'Nejbližší možný termín nadlimitního výběru je',
        REPORT: 'Zjistit více'
      },
      SHOW_LIMIT: 'Zobrazit limity',
      REPORT: 'Nahlásit online'
    },
    SERVICES: {
      ACTIVATION_CODE: 'Tisk aktivačního kódu',
      CONTACTLESS_ATM: 'Bezkontaktní bankomat',
      CONTACTLESS_SYSTEM_PILSEN: 'Dobíjení dopravních karet',
      WEEKEND_OFFICE: 'Víkendový provoz',
      WITHDRAW_ATM: 'Výběrový bankomat',
      DEPOSIT_ATM: 'Vkladový bankomat',
      TRANSFER_ATM: 'Platbomat',
      BAR_CODE_READER_ATM: 'Čtečka čárových kódů',
      QR_CODE_READER_ATM: 'Čtečka QR kódů',
      FOR_BLIND_ATM: 'Pro nevidomé',
      ATM_ACCESSIBILITY: 'Bezbariérový přístup',
      HEARING_IMPAIR: 'Online tlumočení/přepis',
      BARRIER_PARTIALY_FREE: 'Částečně bezbariérový přístup',
      BARRIER_FREE: 'Bezbariérový přístup',
      ATM: 'Bankomat v budově',
      CASH_DESK: 'S pokladnou',
      SELF_SERVICE: 'Samoobslužná zóna',
      EQUIPMENT_17: 'Mincomat',
      EQUIPMENT_13: 'Online tlumočení/přepis',
      EQUIPMENT_12: 'Bankomat - vklady a výběry',
      EQUIPMENT_24: 'Vkladový bankomat',
      EQUIPMENT_23: 'Výběrový bankomat',
      EQUIPMENT_25: 'Bankomat - vklady a výběry',
      EQUIPMENT_11: 'Platbomat',
      EQUIPMENT_9: 'Dětský koutek',
      EQUIPMENT_7: 'WiFi',
      EQUIPMENT_6: 'Noční trezor',
      EQUIPMENT_1: 'Bezpečnostní schránky',
      EQUIPMENT_20: 'Obsluha v Aj',
      EQUIPMENT_16: 'Hlasová navigace pro slabozraké a nevidomé',
      EQUIPMENT_18: 'Videokonference',
      EXCHANGE: 'Směnárna',
      EXCHANGE_TOOLTIP: 'Na této pobočce provádíme směnu a hotovostní operace v těchto měnách: ',
      DEPOSIT_TOOLTIP_FULL:
        'Na této pobočce lze vložit nebo vybrat z účtu u ČS cizí měny v hotovosti. Na pobočce jsou běžně k dispozici měny EUR a USD. Pro výběr ostatních měn se předem informujte na pobočce nebo rovnou proveďte objednávku prostřednictvím formuláře pro nadlimitní výběr.',
      DEPOSIT_TOOLTIP_SMALL:
        'Na této pobočce lze vložit nebo vybrat z účtu u ČS tyto cizí měny v hotovosti %{allCurrencies}. Běžně jsou k dispozici měny %{currencies}. U ostatních měn se předem informujte na pobočce nebo rovnou proveďte objednávku prostřednictvím formuláře pro nadlimitní výběr.',
      DEPOSITS: 'Vklady a výběry cizí měny z účtu'
    },
    PRODUCTS: {
      'Akcie, dluhopisy a služby CDCP': 'Akcie, dluhopisy a služby CDCP',
      'Bezpečnostní schránky': 'Bezpečnostní schránky',
      'Dětské a studentské produkty': 'Dětské a studentské produkty',
      'Dětské a studentské produkty (mimo vkladních knížek)': 'Dětské a studentské produkty (mimo vkladních knížek)',
      'Hypotéka Premier': 'Hypotéka Premier',
      Hypotéky: 'Hypotéky',
      'Investiční produkty': 'Investiční produkty',
      'Karty na nákupy': 'Karty na nákupy',
      'Letištní salónky': 'Letištní salónky',
      Mincomat: 'Mincomat',
      'Moje zdravé finance': 'Moje zdravé finance',
      'Účet Premier': 'Účet Premier',
      'Osobní účty': 'Osobní účty',
      'Penzijní připojištění': 'Penzijní připojištění',
      'Platební karty': 'Platební karty',
      'Platební karty /Visa Infinit, MC World Elite/': 'Platební karty /Visa Infinit, MC World Elite/',
      'Podnikatelské termínované vklady': 'Podnikatelské termínované vklady',
      'Podnikatelské účty': 'Podnikatelské účty',
      'Podnikatelské úvěry': 'Podnikatelské úvěry',
      'Pojistné produkty': 'Pojistné produkty',
      'Premier benefit club': 'Premier benefit club',
      Půjčky: 'Půjčky',
      'Směnárenské služby': 'Směnárenské služby',
      'Spoření Premier': 'Spoření Premier',
      'Spořicí a vkladové produkty': 'Spořicí a vkladové produkty',
      'Spořicí a vkladové produkty (mimo vkladních knížek)': 'Spořicí a vkladové produkty (mimo vkladních knížek)',
      'Stavební spoření': 'Stavební spoření',
      'Úvěr Premier': 'Úvěr Premier',
      'Vklady a výběry cizí měny z účtu': 'Vklady a výběry cizí měny z účtu',
      'Vklady a výběry hotovosti': 'Vklady a výběry hotovosti',
      'Vklady a výběry pouze prostřednictvím bankomatu': 'Vklady a výběry hotovosti pouze prostřednictvím bankomatu',
      'Vklady a výběry hotovosti pouze prostřednictvím bankomatu':
        'Vklady a výběry hotovosti pouze prostřednictvím bankomatu',
      'Výběry hotovosti pouze prostřednictvím bankomatu': 'Výběry hotovosti pouze prostřednictvím bankomatu',
      'Výběry pouze prostřednictvím bankomatu': 'Výběry hotovosti pouze prostřednictvím bankomatu',
      'Životní pojištění': 'Životní pojištění',
      'Vklad Z-obalem': 'Vklady hotovosti v uzavřených obalech'
    },
    MAP: {
      SHOW: 'Ukázat na mapě',
      HIDE: 'Skrýt mapu',
      NAVIGATE: 'Navigovat'
    },
    BACK_TO_LIST: 'Zpět na výpis',
    ROUTE_SEARCH: 'vyhledavani',
    BACKEND_ERROR:
      'Omlouváme se, ale aplikace je momentálně nedostupná. Kontaktujte nás na bezplatné lince 800 207 207.'
  }
}
