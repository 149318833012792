var angular = require('angular')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en

angular.module('PlacesTodayClosingInHoursCmp', [])
  .component('placesTodayClosingInHours', {
    template: require('./places-today-closing-in-hours.component.tpl.html'),
    controller: PlacesTodayClosingInHoursCtrl,
    controllerAs: 'vm',
    bindings: {
      placeType: '<',
      placeOpeningHours: '<',
      placeCashDeskOpeningHours: '<'
    }
  })

PlacesTodayClosingInHoursCtrl.$inject = ['i18n']

function PlacesTodayClosingInHoursCtrl (i18n) {
  var vm = this

  vm.$onInit = function () {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
  }
}
