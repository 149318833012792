export const cs = {
  PLACES: {
    NAVIGATE_HTML: {
      BRANCH: 'Navigovat<br>na pobočku',
      ATM: 'Navigovat<br>k bankomatu'
    },
    PFCS: {
      TITLE: 'Pobočky České spořitelny s nabídkou Doplňkového penzijního spoření'
    },
    RKC: {
      LABEL: {
        MANAGER: 'Ředitel RKC',
        MAIN: 'Kde nás najdete',
        SNIPPET_H1: 'Najděte nejbližší',
        SNIPPET_IN: 'v',
        CONTACT: 'Na koho se můžete přímo obrátit',
        PHONE: 'Univerzální číslo na pobočku'
      },
      OPENING: {
        OPEN_SHORT: 'Máme otevřeno',
        OPEN: 'Právě máme otevřeno'
      },
      BTN: {
        DETAIL: 'Zobrazit detail'
      }
    },
    ERROR: {
      LOCATION: 'Požadovaná lokalita nebyla nalezena',
      REQUIRED: 'Zadejte prosím požadované údaje',
      BACKEND: 'Omlouváme se, ale aplikace je momentálně nedostupná. Kontaktujte nás na bezplatné lince 800 207 207.'
    },
    APPT: {
      ALREADY_ORDERED: 'Dnes jste již objednán(a) na jinou pobočku',
      NOT_CREATED: 'Objednání nelze založit'
    },
    URL: {
      SEARCH: 'vyhledavani',
      ATM: 'bankomat',
      BRANCH: 'pobocka',
      ORDER: 'objednani',
      CASH: 'hotovost',
      CASHLESS: 'bezhotovost'
    },
    TITLE: 'Pobočky a bankomaty',
    PLACEHOLDER: 'Zadejte ulici, město nebo PSČ',
    SERVICE: {
      ACTIVATION_CODE: 'Tisk aktivačního kódu',
      CONTACTLESS_ATM: 'Bezkontaktní bankomat',
      CONTACTLESS_SYSTEM_PILSEN: 'Dobíjení dopravních karet',
      WEEKEND_OFFICE: 'Víkendový provoz',
      WITHDRAW_ATM: 'Výběrový bankomat',
      DEPOSIT_ATM: 'Vkladový bankomat',
      TRANSFER_ATM: 'Platbomat',
      BAR_CODE_READER_ATM: 'Čtečka čárových kódů',
      QR_CODE_READER_ATM: 'Čtečka QR kódů',
      FOR_BLIND_ATM: 'Pro nevidomé',
      ATM_ACCESSIBILITY: 'Bezbariérový přístup',
      HEARING_IMPAIR: 'Online tlumočení/přepis',
      BARRIER_PARTIALY_FREE: 'Částečně bezbariérový přístup',
      BARRIER_FREE: 'Bezbariérový přístup',
      ATM: 'Bankomat v budově',
      SELF_SERVICE: 'Samoobslužná zóna',
      CASH_DESK: 'Pobočka s pokladnou',
      EQUIPMENT_17: 'Mincomat',
      EQUIPMENT_13: 'Online tlumočení/přepis',
      EQUIPMENT_12: 'Bankomat - vklady a výběry',
      EQUIPMENT_24: 'Vkladový bankomat v pobočce',
      EQUIPMENT_23: 'Výběrový bankomat v pobočce',
      EQUIPMENT_25: 'Bankomat - vklady a výběry',
      EQUIPMENT_11: 'Platbomat v pobočce',
      EQUIPMENT_9: 'Dětský koutek',
      EQUIPMENT_7: 'WiFi',
      EQUIPMENT_6: 'Noční trezor',
      EQUIPMENT_1: 'Bezpečnostní schránky',
      EQUIPMENT_20: 'Obsluha v Aj',
      EQUIPMENT_16: 'Hlasová navigace pro slabozraké a nevidomé',
      EXCHANGE: 'Směnárna',
      EXCHANGE_TOOLTIP: 'Na této pobočce provádíme směnu a hotovostní operace v těchto měnách: ',
      DEPOSIT_TOOLTIP: 'Na této pobočce lze vybrat nebo vložit na účet hotovost v těchto měnách: ',
      DEPOSITS: 'Vklady a výběry cizí měny z účtu',
      'Akcie, dluhopisy a služby CDCP': 'Akcie, dluhopisy a služby CDCP',
      'Bezpečnostní schránky': 'Bezpečnostní schránky',
      'Dětské a studentské produkty': 'Dětské a studentské produkty',
      'Dětské a studentské produkty (mimo vkladních knížek)': 'Dětské a studentské produkty (mimo vkladních knížek)',
      'Hypotéka Premier': 'Hypotéka Premier',
      Hypotéky: 'Hypotéky',
      'Investiční produkty': 'Investiční produkty',
      'Karty na nákupy': 'Karty na nákupy',
      'Letištní salónky': 'Letištní salónky',
      Mincomat: 'Mincomat',
      'Moje zdravé finance': 'Moje zdravé finance',
      'Účet Premier': 'Účet Premier',
      'Osobní účty': 'Osobní účty',
      'Penzijní připojištění': 'Penzijní připojištění',
      'Platební karty': 'Platební karty',
      'Platební karty /Visa Infinit, MC World Elite/': 'Platební karty /Visa Infinit, MC World Elite/',
      'Podnikatelské termínované vklady': 'Podnikatelské termínované vklady',
      'Podnikatelské účty': 'Podnikatelské účty',
      'Podnikatelské úvěry': 'Podnikatelské úvěry',
      'Pojistné produkty': 'Pojistné produkty',
      'Premier benefit club': 'Premier benefit club',
      Půjčky: 'Půjčky',
      'Směnárenské služby': 'Směnárenské služby',
      'Spoření Premier': 'Spoření Premier',
      'Spořicí a vkladové produkty': 'Spořicí a vkladové produkty',
      'Spořicí a vkladové produkty (mimo vkladních knížek)': 'Spořicí a vkladové produkty (mimo vkladních knížek)',
      'Stavební spoření': 'Stavební spoření',
      'Úvěr Premier': 'Úvěr Premier',
      'Vklady a výběry cizí měny z účtu': 'Vklady a výběry cizí měny z účtu',
      'Vklady a výběry hotovosti': 'Vklady a výběry hotovosti',
      'Vklady a výběry pouze prostřednictvím bankomatu': 'Vklady a výběry hotovosti pouze prostřednictvím bankomatu',
      'Vklady a výběry hotovosti pouze prostřednictvím bankomatu':
        'Vklady a výběry hotovosti pouze prostřednictvím bankomatu',
      'Výběry hotovosti pouze prostřednictvím bankomatu': 'Výběry hotovosti pouze prostřednictvím bankomatu',
      'Výběry pouze prostřednictvím bankomatu': 'Výběry hotovosti pouze prostřednictvím bankomatu',
      'Životní pojištění': 'Životní pojištění',
      'Vklad Z-obalem': 'Vklady hotovosti v uzavřených obalech'
    },
    QUICK: {
      BRANCH: {
        OPEN_NOW: 'Otevřeno nyní',
        CASH_DESK: 'S pokladnou',
        UNTIL: 'pouze do',
        NEWLY: 'NOVĚ',
        NO_CASH_DESK: 'Bez pokladny',
        HANDICAP: 'Pro handicapované',
        BARRIER_FREE: 'Bezbarierový přístup',
        HEARING_IMPAIR: 'Online tlumočení/přepis',
        DIGITAL_VOICE: 'Hlasová navigace pro slabozraké a nevidomé',
        OPEN_WEEKEND: 'Otevřeno o víkendu',
        PARKING: 'Snadné parkování v okolí',
        DEPOSIT: 'Chci vložit peníze',
        PAYMENT: 'Chci provést platbu',
        GOLD: 'S bezpečnostními schránkami',
        OUT_OF_SERVICE: 'Mimo provoz'
      },
      ATM: {
        NEW_ATM: 'Nový bankomat',
        AVAILABLE_NOW: 'Dostupný právě teď',
        HANDICAP: 'Pro handicapované',
        BARRIER_FREE: 'Bezbarierový přístup',
        ATM_ACCESSIBILITY: 'Bezbarierový přístup',
        FOR_BLIND_ATM: 'Bankomat pro nevidomé',
        DEPOSIT: 'Chci vložit peníze',
        PAYMENT: 'Chci provést platbu',
        TRANSFER_ATM: 'Platbomat',
        QR_CODE_READER_ATM: 'Čtečka QR kódu',
        BAR_CODE_READER_ATM: 'Čtečka BAR kódu',
        CONTACTLESS_SYSTEM_PILSEN: 'Dobíjení dopravních karet'
      }
    },
    ORDER: {
      NOT_FOUND: 'Schůzka nebyla nalezena',
      FORM: {
        TITLE: 'Zvolený termín',
        TITLE_CASH: 'Objednání na pokladnu',
        TITLE_CASHLESS: 'Objednání k bankéři',
        TITLE_FORM: 'Zadejte vaše údaje, abychom vás poznali:',
        AT: 'v',
        SERVICE: 'Služba',
        NAME: 'Jméno',
        LASTNAME: 'Příjmení',
        PHONE: 'Telefon',
        PHONE_INFO: 'Pro zaslání pořadového čísla.',
        EMAIL: 'E-mail',
        EMAIL_INFO: 'Pro zaslání potvrzení o objednání a případném zrušení.',
        PHONE_PLACEHOLDER: 'XXX XXX XXX',
        PHONE_ERROR: 'Číslo ve formátu XXX XXX XXX',
        FORM_BTN: 'Objednat na pobočku',
        SUCCESS: {
          TITLE: 'Objednání proběhlo úspěšně. A co dál?',
          PLANNED_AT: 'Zvolený termín',
          ORDER_NUMBER_ON_PHONE:
            'Zašleme vám <strong>pořadové číslo</strong> v SMS zprávě na váš telefon %{phone}, a to nejpozději 30 minut před vaší návštěvou. Nové číslo si již vyvolávat nemusíte.',
          ORDER_CONFIRMATION_ON_EMAIL:
            'Na váš e-mail <strong>%{email}</strong> jsme zaslali potvrzení objednávky. Najdete v něm také odkaz k případnému zrušení.',
          CORRECT_MISTAKE: 'Pokud jste si spletli čas nebo místo, můžete objednávku zrušit, a to přes odkaz níže:',
          CANCEL: 'Zrušit objednání'
        },
        CANCEL: {
          TITLE: 'Zrušení objednání proběhlo úspěšně.',
          ORDER_CANCEL_ON_EMAIL: 'Na váš e-mail <strong>%{email}</strong> jsme zaslali potvrzení o zrušení objednávky.',
          BRANCH_DETAIL: 'Zpět na detail pobočky'
        }
      },
      CANCEL: {
        TITLE: 'Zrušení schůzky',
        SUCCESS: 'Schůzka byla úspěšně zrušena',
        BUTTON: 'Zrušit schůzku'
      },
      TITLE: 'Objednání na pobočku on-line',
      SELECT_TIME: 'Zvolte čas:',
      FULL: 'Obsazené termíny',
      MORNING: 'Ráno',
      NOON: 'Dopoledne',
      AFTERNOON: 'Odpoledne',
      EVENING: 'Večer',
      STATE: {
        FREE: 'Volno',
        NOT_FEASIBLE: 'Nedostupné',
        TIME_NOT_FEASIBLE: 'Obsazeno',
        FREE_APPT_SLOTS: 'Volno',
        NO_APPT_SLOTS: 'Obsazeno',
        BRANCH_CLOSED: 'Zavřeno'
      }
    },
    TEXT: {
      LIST: {
        ACTIVE: 'Máte aktivní |||| Máte aktivní |||| Máte aktivních',
        FILTER: 'filtr |||| filtry |||| filtrů',
        LAST: 'Naposledy jste hledali',
        RESULT_FOUND: 'Nalezeno',
        RESULT_LOCALITY: 've vzdálenosti 50 km od lokality',
        RESULT_ITEMS: 'položka |||| položky |||| položek',
        NOT_FOUND_FILTERS: 'Této kombinaci filtrů neodpovídá žádná pobočka/bankomat'
      },
      DETAIL: {
        NOTE: 'Poznámka',
        STREET_VIEW: 'Street view',
        GALLERY: 'Galerie',
        MANAGER: 'Manažer pobočky',
        READ_MORE: 'číst více...',
        MAP: 'Ukázat na mapě',
        ORDER1: 'Ušetřete si čas',
        ORDER2: 'na pobočce',
        ORDER_CASH: 'Chci jít na pokladnu',
        ORDER_DESK: 'Objednat k bankéři',
        CASH_LIMIT: 'Výběr hotovosti v hodnotě',
        CASH_LIMIT_EXCESS1: 'a vyšší prosím',
        CASH_LIMIT_EXCESS_LINK: 'nahlaste pobočce',
        CASH_LIMIT_EXCESS2: 'nejpozději',
        CASH_LIMIT_EXCESS3: ' před plánovanou návštěvou.',
        PRODUCTS: 'produkty',
        PRE_PRODUCTS: 'Na pobočce Vám poradí s těmito',
        PRODUCTS_LIMIT: 'Zobrazit všechny produkty',
        CALL_BOX:
          '<strong>Rádi vám poradíme</strong> také přes <span class="csas-nobr">e-mail</span> nebo po telefonu.',
        PRE_CALL: 'Potřebujete poradit?',
        CALL_INFO: 'Jsme Vám k dispozici 24h denně, 7 dní v týdnu.',
        CALL: 'Volejte',
        EMAIL: 'nebo napište e-mail na',
        NEXT: 'Zobrazit další',
        SPECIALISTS: 'Specialisté na pobočce',
        ATM_PRODUCTS: 'S čím vám tento bankomat pomůže:',
        ATM_NR: 'Číslo bankomatu:',
        DISTANCE: 'Vzdálenost',
        TODAY: 'Dnes',
        OPEN: 'otevřeno',
        STATUS: {
          BRANCH: {
            CLOSING: 'Pobočka brzy zavírá',
            CLOSING_SHORT: 'Brzy zavírá',
            CLOSED: 'Pobočka je zavřená',
            CLOSED_SHORT: 'Zavřeno',
            OPEN: 'Otevřeno',
            OPEN_FOR:
              'Otevřeno ještě <strong>%{smart_count} hodinu</strong>. |||| Otevřeno ještě <strong>%{smart_count} hodiny</strong>. |||| Otevřeno ještě <strong>%{smart_count} hodin</strong>.',
            OPEN_FOR_SHORT:
              'Ještě <strong>%{smart_count} hodinu</strong>. |||| Ještě <strong>%{smart_count} hodiny</strong>. |||| Ještě <strong>%{smart_count} hodin</strong>.',
            TODAY: 'Dnes otevřeno',
            CASH_DESK_OPEN: 'Provoz pokladny'
          },
          ATM: {
            CLOSING: 'Bankomat bude brzy nedostupný',
            CLOSING_SHORT: 'Brzy bude nedostupný',
            CLOSED: 'Bankomat je nedostupný',
            CLOSED_SHORT: 'Nedostupný',
            OPEN: 'Dostupný',
            OPEN_FOR:
              'Dostupný ještě <strong>%{smart_count} hodinu</strong>. |||| Dostupný ještě <strong>%{smart_count} hodiny</strong>. |||| Dostupný ještě <strong>%{smart_count} hodin</strong>.',
            OPEN_FOR_SHORT:
              'Ještě <strong>%{smart_count} hodinu</strong>. |||| Ještě <strong>%{smart_count} hodiny</strong>. |||| Ještě <strong>%{smart_count} hodin</strong>.',
            TODAY: 'Dnes dostupný'
          },
          EMPLOYEE: 'Pouze <strong>pro zaměstnance</strong>',
          OUT_OF_ORDER: 'Mimo provoz'
        },
        BACK: {
          TO_HOME: 'Zpět na vyhledávání poboček',
          TO_DETAIL: 'Zpět na detail pobočky',
          TO_LIST: 'Zpět na výpis',
          TO_ORDER: 'Zpět na výběr času'
        },
        DB: {
          'bezhotovostní pobočka': 'bezhotovostní pobočka',
          '2 dny, s výčetkou 3 dny': '2 dny, s výčetkou 3 dny',
          '1': '1',
          '2': '2',
          '3': '3',
          '4': '4',
          '5': '5',
          '1 týden': '1 týden',
          '48 hodin': '48 hodin',
          '1 pracovní den': '1 pracovní den',
          '2 pracovní dny': '2 pracovní dny',
          '3 pracovní dny': '3 pracovní dny',
          '4 pracovní dny': '4 pracovní dny',
          '5 pracovních dnů': '5 pracovních dnů',
          '2 dny předem': '2 dny předem',
          '1 den': '1 den',
          '2 dny': '2 dny',
          '3 dny': '3 dny',
          '4 dny': '4 dny',
          '5 dnů': '5 dnů',
          'individualně/mail na pobočku': 'individualně/mail na pobočku'
        }
      }
    },
    BTN: {
      CANCEL_FILTER: 'Zrušit filtry',
      NAVIGATE: {
        BRANCH: 'Navigovat na pobočku',
        ATM: 'Navigovat k bankomatu'
      },
      SELECT: 'Vybrat',
      SEARCH: 'Hledat',
      ATM: {
        DETAIL: 'Detail bankomatu',
        MORE: 'Načíst další bankomaty'
      },
      BRANCH: {
        DETAIL: 'Detail pobočky',
        MORE: 'Načíst další pobočky'
      },
      MAP: {
        SHOW: 'Zobrazit mapu',
        HIDE: 'Zobrazit výpis',
        ME: 'Moje poloha'
      },
      LIST: {
        SHOW: {
          BRANCH: 'Zobrazit seznam poboček',
          ATM: 'Zobrazit seznam bankomatů'
        }
      },
      ANOTHER_DAYS: 'Další dny',
      ORDER: {
        PLACEHOLDER: 'Objednat se',
        CASH: 'Na&nbsp;pokladnu',
        DESK: 'k&nbsp;bankéři na&nbsp;pobočce',
        GEORGE: 'k mému bankéři v&nbsp;Georgi',
        NEXT_WEEK: 'Příští týden',
        PREV_WEEK: 'Předchozí týden'
      }
    },
    TYPES: {
      BRANCHES: 'Pobočky',
      ATMS: 'Bankomaty',
      NEXT: 'Další',
      DEPOSIT_ATM: 'Vkladové bankomaty',
      TRANSFER_ATM: 'Platbomaty',
      HC: 'Hypoteční centra',
      KC: 'Regionální korporátní centra',
      EXCHANGE: 'Směnárny',
      OMC: 'Obchodní místa centrály',
      COMPANY: 'Pobočky s firemním bankéřem',
      PREMIER: 'Erste Premier centra'
    },
    DAYS: {
      MONDAY: 'Pondělí',
      TUESDAY: 'Úterý',
      WEDNESDAY: 'Středa',
      THURSDAY: 'Čtvrtek',
      FRIDAY: 'Pátek',
      SATURDAY: 'Sobota',
      SUNDAY: 'Neděle',
      TOOLTIP: 'Otevírací doba pro den'
    }
  },
  CZK: 'Kč'
}
