exchangeWebService.$inject = ['$http', 'apiBase', '$q']

function exchangeWebService($http, apiBase) {
  var endpointPath = apiBase + 'v2/rates'

  function endpoint(endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  return {
    listAllCurrency: function (lang) {
      return $http({
        url: endpoint('web/currencies/EXCHANGE/NONCASH'),
        method: 'GET',
        params: { lang: lang }
      })
    }
  }
}

exports.exchangeWebService = exchangeWebService
