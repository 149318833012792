var angular = require('angular')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en
require('aem-libs-angularjs/src/directives/track-element.directive.js')
var widgetParamsService = require('aem-libs-angularjs/src/services/widgetParams.service.js').widgetParamsService
require('../places-week-opening-hours/places-week-opening-hours.component.js')
var placesOpeningHoursService = require('../../services/places-opening-hours.service.js').placesOpeningHoursService
require('aem-libs-angularjs/src/filters/phone-formatter.filter.js')
var iconssvg = require('!svg-inline-loader!./img/icons-svg.svg') // eslint-disable-line import/no-webpack-loader-syntax
require('aem-libs-angularjs/src/directives/tooltip-positioner.directive.js')
var dropdown = require('angular-ui-bootstrap/src/dropdown')
require('aem-libs-angularjs/src/components/wcm-html-content/wcm-html-content.component.js')
var contentService = require('aem-libs-angularjs/src/services/content.service.js').contentService
var placesStateService = require('../../services/places-state.service').placesStateService
var appointmentsTrackingDataservice = require('aem-libs-places-angularjs/src/services/appointments-tracking.dataservice').appointmentsTrackingDataservice

angular.module('PlacesDetailCmp', ['TrackElementCmp', 'PlacesWeekOpeningHoursCmp',
  'phoneFormatterCmp', 'TooltipPositionerCmp', dropdown, 'WcmHtmlContentCmp'])
  .factory('widgetParamsService', widgetParamsService)
  .factory('placesOpeningHoursService', placesOpeningHoursService)
  .factory('contentService', contentService)
  .factory('placesStateService', placesStateService)
  .factory('appointmentsTrackingDataservice', appointmentsTrackingDataservice)
  .component('placesDetail', {
    template: require('./places-detail.component.tpl.html'),
    controller: PlacesDetailCtrl,
    controllerAs: 'vm'
  })

require('./component.css')
require('./component-redesign.css')
require('./components/places-detail-branch/places-detail-branch.component.js')
require('./components/places-detail-atm/places-detail-atm.component.js')

PlacesDetailCtrl.$inject = ['i18n', 'placesStateService', '$window', 'placesService', 'appLoader', '$location', '$routeParams']

function PlacesDetailCtrl (i18n, placesStateService, $window, placesService, appLoader, $location, $routeParams) {
  var vm = this
  document.getElementById('iconssvg').insertAdjacentHTML('beforeend', iconssvg)

  vm.$onInit = function () {
    vm.urlReady = false
    if ($window.location.hash.indexOf('marketplace') !== -1) {
      vm.marketPlaceId = $routeParams.id
      appLoader.show()
      placesService.narketPlaceBranchDetail(vm.marketPlaceId)
        .then(function (response) {
          var branchIdUrl = $location.url()
          branchIdUrl = branchIdUrl.replace('marketplace/', '').replace($routeParams.id, response.data.id)
          $location.url(branchIdUrl)
          $location.replace()
        })
        .catch(function (err) {
          vm.backendError = err
        })
        .finally(function () {
          vm.loaded = true
          appLoader.hide()
        })
    } else {
      vm.urlReady = true
      if ($window.location.hash.indexOf('pobocka') !== -1) {
        vm.type = 'BRANCH'
      } else if ($window.location.hash.indexOf('bankomat') !== -1) {
        vm.type = 'ATM'
      } else {
        vm.type = placesStateService.getState().placeType
      }
      vm.goBack = goBack
    }
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
  }

  function goBack () {
    let searchLang = window.location.pathname === '/cs/' ? 'vyhledavani' : 'search'
    let linkBack = window.location.origin + window.location.pathname + '#/' + searchLang
    window.location.href = linkBack
  }
}
