var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesBackgroundMap', {
    template: require('./places-background-map.component.tpl.html'),
    controller: PlacesBackgroundMapCtrl,
    controllerAs: 'vm',
    bindings: {
      inlineAddress: '<'
    }
  })

PlacesBackgroundMapCtrl.$inject = ['placesService', '$window', 'appConfig']

function PlacesBackgroundMapCtrl (placesService, $window, appConfig) {
  var vm = this

  vm.$onInit = function () {
    vm.style = {}

    var url = [
      'https://maps.googleapis.com/maps/api/staticmap',
      [
        'center=' + $window.encodeURIComponent(vm.inlineAddress),
        'zoom=12',
        'scale=2',
        'size=800x600',
        'maptype=roadmap',
        'style=saturation:-100',
        'format=png',
        'visual_refresh=true'
      ].join('&')
    ].join('?')
    placesService.signUrl(url, appConfig.googleMaps.apiKey).then(function (res) {
      vm.style['background-image'] = 'url(' + res.data.signedUrl + ')'
    })
  }
}
