var angular = require('angular')
var startOfDay = require('date-fns/fp/startOfDay').default
var addDays = require('date-fns/fp/addDays').default
var parseISO = require('date-fns/fp/parseISO').default
var endOfWeek = require('date-fns/fp/endOfWeek').default
var endOfDay = require('date-fns/fp/endOfDay').default
var getDay = require('date-fns/fp/getDay').default
var format = require('date-fns/fp/format').default
require('../places-branch-products/places-branch-products.component.js')
require('../places-services/places-services.component.js')
require('../places-branch-specialists/places-branch-specialists.component.js')
require('../places-branch-specialists-mortgage/places-branch-specialists-mortgage.component')
require('../places-branch-manager/places-branch-manager.component.js')
require('../places-branch-callbox/places-branch-callbox.component.js')
require('../places-branch-order/places-branch-order.component.js')
require('../places-background-map/places-background-map.component.js')
require('../places-detail-map/places-detail-map.component.js')
require('../places-detail-note/places-detail-note.component.js')
require('../places-opening-hours/places-opening-hours.component.js')

angular.module('PlacesDetailCmp').component('placesDetailBranch', {
  template: require('./places-detail-branch.component.tpl.html'),
  controller: PlacesDetailBranchCtrl,
  controllerAs: 'vm'
})

PlacesDetailBranchCtrl.$inject = [
  'wgService',
  'appLoader',
  'placesService',
  '$filter',
  '$window',
  '$routeParams',
  '$anchorScroll',
  'userTracking',
  'i18n',
  'widgetParamsService',
  '$q',
  'placesOpeningHoursService',
  'placesStateService'
]

function PlacesDetailBranchCtrl(
  wgService,
  appLoader,
  placesService,
  $filter,
  $window,
  $routeParams,
  $anchorScroll,
  userTracking,
  i18n,
  widgetParamsService,
  $q,
  placesOpeningHoursService
) {
  var vm = this
  var lastBranchKey = 'LAST_BRANCH'
  var lastATMKey = 'LAST_ATM'
  const NEXT_OPENING_DAYS = 14

  vm.$onInit = function () {
    vm.loaded = false
    vm.lang = i18n.langCode
    vm.ecb = ~wgService.getAppName().indexOf('Ecb')
    vm.pfcs = ~wgService.getAppName().indexOf('Csps')
    vm.encs = wgService.getAppName() === 'AemEncsBranches'
    vm.preferRealOpeningHours = wgService.getParams().preferRealOpeningHours
    vm.equals = angular.equals
    vm.backendError = null
    vm.detail = null
    vm.placeOpeningHours = null
    vm.placeCashDeskOpeningHours = null
    vm.nextDaysOpeningHours = []
    vm.nextDaysCashdeskOpeningHours = []
    vm.branchHasCashdesk = false
    vm.cashDeskUntil = ''
    vm.branchId = $routeParams.id
    vm.branchRelativeUrl = null
    vm.branchAppointmentsCrmUrl = null
    vm.overdraftUrl = null
    vm.georgeAppointmentUrl = null
    vm.isHC = false
    vm.isOpen = null
    vm.showMore = false
    vm.overdraftLinkToPDF = null
    if (vm.ecb) {
      lastBranchKey += '_ECB'
      lastATMKey += '_ECB'
    }
    if (vm.pfcs) {
      lastBranchKey += '_CSPS'
      lastATMKey = undefined
    }

    vm.scrollToMap = scrollToMap
    vm.searchNearest = searchNearest

    initBranch()

    vm.toggle = function () {
      vm.isOpen = !vm.isOpen
    }
  }

  function getCurrenciesString(currenciesList) {
    if (currenciesList && currenciesList.length) {
      return currenciesList.join(', ')
    }

    return ''
  }

  function getCurrenciesTip(currencies, needOrderCurrencies) {
    const maxCurrenciesLength = 12
    const currenciesString = getCurrenciesString(currencies)
    const needOrderCurrenciesString = getCurrenciesString(needOrderCurrencies)

    const fullTip = $filter('translate')('PLACES_DETAIL.SERVICES.DEPOSIT_TOOLTIP_FULL')
    const smallTip = $filter('translate')('PLACES_DETAIL.SERVICES.DEPOSIT_TOOLTIP_SMALL', {
      allCurrencies: `${currenciesString}, ${needOrderCurrenciesString}`,
      currencies: currenciesString
    })

    if (currencies.length + needOrderCurrencies.length < maxCurrenciesLength) {
      return smallTip
    }

    return fullTip
  }

  function initBranch() {
    $window.scrollTo(0, 0)
    var exchangeService = false
    var depositsAndWithdrawals
    appLoader.show()
    placesService
      .branchDetail(vm.branchId)
      .then(function (response) {
        response.data.services = response.data.services.filter(function (element) {
          return element.flag !== 'BARRIER_FREE_OR_BARRIER_PARTIALY_FREE'
        })
        vm.isHC = response.data.services.filter(function (element) {
          return element.flag === 'HC'
        }).length
        vm.detail = response.data

        const branchHasCashdesk = vm.detail.services.filter(function (service) {
          return service.flag === 'CASH_DESK'
        })
        if (branchHasCashdesk.length) {
          vm.branchHasCashdesk = true
        }

        vm.nextDaysOpeningHours = placesOpeningHoursService.getNextDaysOpeningHours(
          vm.detail.openingHours,
          vm.detail.outages,
          NEXT_OPENING_DAYS,
          placesOpeningHoursService.outagesTypes
        )
        if (vm.branchHasCashdesk && !vm.detail['cashdesk_opening_hours']) {
          vm.nextDaysCashdeskOpeningHours = vm.nextDaysOpeningHours
        } else {
          vm.nextDaysCashdeskOpeningHours = placesOpeningHoursService.getNextDaysOpeningHours(
            vm.detail['cashdesk_opening_hours'],
            vm.detail.outages,
            NEXT_OPENING_DAYS,
            placesOpeningHoursService.outagesCashdeskTypes
          )
        }
        const filterOutagesForType = function (types) {
          return vm.detail.outages.filter(function (outage) {
            return types.includes(outage.outageType)
          })
        }
        vm.placeOpeningHours = placesOpeningHoursService.normalize(
          vm.detail.openingHours,
          filterOutagesForType(placesOpeningHoursService.outagesTypes),
          vm.detail.accessType,
          vm.preferRealOpeningHours
        )
        vm.placeCashDeskOpeningHours = placesOpeningHoursService.normalize(
          vm.detail.openingHours,
          filterOutagesForType(placesOpeningHoursService.outagesCashdeskTypes),
          vm.detail.accessType,
          vm.preferRealOpeningHours
        )

        const branchHours = vm.placeOpeningHours.openingHours.map(function (i) {
          return i.real
        })
        const cashDeskHours = vm.placeCashDeskOpeningHours.openingHours.map(function (i) {
          return i.real
        })

        vm.isCashDeskOpeningHoursDifferent = !_.isEqual(branchHours, cashDeskHours)

        vm.detail.isEcb = !!vm.detail.services.filter(function (service) {
          return service.flag === 'KC'
        })[0]

        vm.detail.isLkc = !!vm.detail.services.filter(function (service) {
          return service.flag === 'LKC'
        })[0]

        var pfcsIndex = -1
        var pfcsItem
        angular.forEach(vm.detail.branchProducts, function (item, index) {
          if (vm.pfcs && item.id === 4) {
            pfcsIndex = index
            pfcsItem = item
          }

          if (item.id === 15) {
            exchangeService = true
          }

          if (item.id === 30) {
            depositsAndWithdrawals = item.name
          }
        })

        if (pfcsIndex > -1 && pfcsItem) {
          vm.detail.branchProducts.splice(pfcsIndex, 1)
          vm.detail.branchProducts.unshift(pfcsItem)
        }

        if (vm.detail.outages) {
          var nextMonday = startOfDay(addDays(2)(endOfWeek(new Date())))
          var nextFriday = endOfDay(addDays(4)(nextMonday))
          var weekDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
          var outageInfo = []
          vm.hasOutageNextWeek = false
          for (var i = 0; i < vm.detail.outages.length; i++) {
            var outageDates = parseISO(vm.detail.outages[i].outageDate)
            var outageType = vm.detail.outages[i].outageType
            var morningChangedHours = vm.detail.outages[i].morningStart
            var afternoonChangedHours = vm.detail.outages[i].afternoonStart
            if (outageDates >= nextMonday && outageDates <= nextFriday && vm.detail.state !== 'OUT_OF_ORDER') {
              vm.hasOutageNextWeek = true
              var outageDate = format('d. M. yyyy')(outageDates)
              var weekDayIndex = getDay(outageDates)
              var closed
              var morningOpeningHours
              var afternoonOpeningHours
              if (outageType === 'CLOSED') {
                closed = $filter('translate')('PLACES_DETAIL.BRANCH.CLOSED_NEXT_WEEK')
                morningOpeningHours = undefined
                afternoonOpeningHours = undefined
              } else if (outageType === 'CHANGED_OPENING_HOURS') {
                closed = undefined
                if (morningChangedHours) {
                  morningOpeningHours =
                    format('HH:mm')(parseISO(vm.detail.outages[i].morningStart)) +
                    ' - ' +
                    format('HH:mm')(parseISO(vm.detail.outages[i].morningEnd))
                }
                if (afternoonChangedHours) {
                  afternoonOpeningHours =
                    format('HH:mm')(parseISO(vm.detail.outages[i].afternoonStart)) +
                    ' - ' +
                    format('HH:mm')(parseISO(vm.detail.outages[i].afternoonEnd))
                }
              }
              outageInfo[i] = {
                date: outageDate,
                day: weekDays[weekDayIndex],
                closed: closed,
                morning: morningOpeningHours,
                afternoon: afternoonOpeningHours
              }
            }
          }
          vm.outageInfo = outageInfo
        }

        if (vm.encs && vm.detail.branchSpecialist) {
          for (var s = 0; s < vm.detail.branchSpecialist.length; s++) {
            if (vm.detail.branchSpecialist[s].type.id === 'NONFINANCE') {
              vm.detail.branchSpecialist[s].name =
                vm.detail.branchSpecialist[s].firstName + ' ' + vm.detail.branchSpecialist[s].lastName
              vm.detail.manager = vm.detail.branchSpecialist[s]
            } else {
              vm.detail.manager = undefined
            }
          }
        }

        checkCashWithdrawal()
        vm.detail = placesService.processBranchObject(vm.detail)
        vm.branchRelativeUrl = placesService.getDetailUrl(vm.detail, $filter('translate')('PLACES.URL.BRANCH'))
        userTracking.onResponse(vm.detail)

        if (exchangeService && !vm.encs) {
          var tooltip = $filter('translate')('PLACES.SERVICE.EXCHANGE_TOOLTIP')
          if (response.data.currencies) {
            var currencies = response.data.currencies.join(', ')
            tooltip = tooltip + currencies + '.'
          }
          vm.detail.sortedServices.push({ type: 'EXCHANGE', flag: 'EXCHANGE', tooltip: tooltip })
        }
        if (depositsAndWithdrawals && !vm.encs) {
          const tip = getCurrenciesTip(response.data.currencies, response.data.needOrderCurrencies)
          vm.detail.sortedServices.push({ type: 'DEPOSITS', flag: 'DEPOSITS', tooltip: tip })
        }
        angular
          .element(document.getElementsByClassName('csas-google-mtd-area'))
          .html(placesService.getGoogleMetadata(vm.detail))
        if (placesService.isLocalStorageEnabled()) {
          window.localStorage.setItem(lastBranchKey, vm.detail.id)
          if (lastATMKey) {
            window.localStorage.removeItem(lastATMKey)
          }
        }
        setAtmLink()
        if (response.data.cashUntil) {
          vm.cashDeskUntil = response.data.cashUntil
        }
        return widgetParamsService.get(wgService.getAppName())
      })
      .then(function (widgetParamsResponse) {
        setUrlService(widgetParamsResponse)
        setBranchAppointmentsCrmUrl(widgetParamsResponse)
        setOverdraftUrl(widgetParamsResponse)
      })
      .catch(function (err) {
        vm.backendError = err
      })
      .finally(function () {
        vm.loaded = true
        appLoader.hide()
      })
  }

  function setUrlService(widgetParamsResponse) {
    var service = widgetParamsResponse.data.allBrancheServicesToFaqUrlMapping || []

    vm.detail.sortedServices = vm.detail.sortedServices.map((item) => {
      const labelItem = service.find((itemService) => itemService.flag === item.flag)
      if (labelItem) {
        return { ...item, url: labelItem.faqUrl }
      }
      return item
    })
  }

  function setBranchAppointmentsCrmUrl(widgetParamsResponse) {
    var urlToBranchAppointments =
      widgetParamsResponse.data.branchAppointmentsCrmUrl &&
      widgetParamsResponse.data.branchAppointmentsCrmUrl[i18n.langCode]
    if (urlToBranchAppointments) {
      urlToBranchAppointments += 'branch/' + vm.detail.id
    }

    vm.georgeAppointmentUrl = widgetParamsResponse.data.georgeAppointmentUrl
    vm.branchAppointmentsCrmUrl = isBranchAppointmentAvailable() && urlToBranchAppointments
  }

  function isBranchAppointmentAvailable() {
    var hasPobockyFlag =
      vm.detail.services &&
      vm.detail.services.some(function (service) {
        return service.flag === 'POBOCKY'
      })
    return vm.detail.orderToBranch && hasPobockyFlag && vm.detail.marketPlaceId
  }

  function setOverdraftUrl(widgetParamsResponse) {
    if (widgetParamsResponse.data.overdraftUrl && widgetParamsResponse.data.overdraftUrl[i18n.langCode]) {
      vm.overdraftUrl = widgetParamsResponse.data.overdraftUrl[i18n.langCode].replace('%{id}', vm.detail.id)
      vm.overdraftLinkToPDF = widgetParamsResponse.data.overdraftLinkToPDF[i18n.langCode]
    }
  }

  function setAtmLink() {
    if (vm.pfcs || vm.encs) {
      return
    }
    var urlAtmPart = ''
    var address = vm.detail.address + ', ' + vm.detail.city + ', ' + vm.detail.postCode
    var urlFlags = ['EQUIPMENT_23', 'EQUIPMENT_11', 'EQUIPMENT_12', 'EQUIPMENT_25']
    const replaceFlags = {
      EQUIPMENT_23: 'ATM',
      EQUIPMENT_11: 'EQUIPMENT_11',
      EQUIPMENT_12: 'EQUIPMENT_12',
      EQUIPMENT_25: 'ATM'
    }
    var promises = []
    if (vm.lang === 'cs') {
      urlAtmPart = 'bankomat'
    } else {
      urlAtmPart = 'ATM'
    }

    for (var i = 0; i < vm.detail.sortedServices.length; i++) {
      if (urlFlags.indexOf(vm.detail.sortedServices[i].flag) > -1) {
        var params = {
          q: address,
          flags: vm.detail.sortedServices[i].flag,
          types: 'ATM'
        }
        promises.push(placesService.search(params))
      }
    }

    $q.all(promises).then(function (callback) {
      var address = window.location.hash.split('/')
      address = address[address.length - 1]
      var realAddress = vm.detail.address + ', ' + vm.detail.postCode + ' ' + vm.detail.city
      var legitInfo = []
      var counter = 0
      for (var i = 0; i < vm.detail.sortedServices.length; i++) {
        if (urlFlags.indexOf(vm.detail.sortedServices[i].flag) > -1) {
          vm.detail.sortedServices[i].flag = replaceFlags[vm.detail.sortedServices[i].flag]
          legitInfo = []
          for (var x = 0; x < callback[counter].data.items.length; x++) {
            if (callback[counter].data.items[x].type === 'ATM') {
              legitInfo.push(callback[counter].data.items[x])
            }
          }
          counter++
          if (legitInfo.length === 1) {
            vm.detail.sortedServices[i].url = '#/' + legitInfo[0].id + '/' + urlAtmPart + '/' + address
          } else {
            vm.detail.sortedServices[i].url =
              '#/' +
              $filter('translate')('PLACES.URL.SEARCH') +
              '?type=' +
              translateFlag(vm.detail.sortedServices[i].flag) +
              '&address=' +
              encodeURIComponent(realAddress)
          }
        }
      }
    })
  }

  function translateFlag(flag) {
    if (flag === 'EQUIPMENT_11') {
      return 'TRANSFER_ATM'
    } else if (flag === 'EQUIPMENT_12') {
      return 'DEPOSIT_ATM'
    } else {
      return flag
    }
  }

  function searchNearest(flag) {
    var urlFlags = ['ATM', 'EQUIPMENT_11', 'EQUIPMENT_12', 'EQUIPMENT_25']
    if (urlFlags.indexOf(flag) > -1) {
      var cookieName = 'AEM_BRANCHES_SEARCH_PARAMS'
      if (vm.ecb) {
        cookieName += '_ECB'
      }
      if (vm.pfcs) {
        cookieName += '_CSPS'
      }

      var address = vm.detail.address + ', ' + vm.detail.city + ', ' + vm.detail.postCode
      var object = {
        type: 'ATM',
        quick: false,
        address: encodeURIComponent(address),
        scroll: true
      }

      if (flag === 'ATM') {
        object.quick = undefined
      } else if (flag === 'EQUIPMENT_11') {
        object.flag = ['TRANSFER_ATM']
      } else if (flag === 'EQUIPMENT_12') {
        object.flag = ['DEPOSIT_ATM']
      }

      window.localStorage.setItem(cookieName, JSON.stringify(object))
    }
  }

  function scrollToMap() {
    $anchorScroll('map')
  }

  function checkCashWithdrawal() {
    vm.showCashWithdrawalInfo = false
    if (
      vm.detail.cashWithdrawal &&
      vm.detail.cashWithdrawal.limit &&
      vm.detail.cashWithdrawal.limit !== 0 &&
      vm.detail.cashWithdrawal.excessDeadline &&
      vm.detail.cashWithdrawal.excessDeadline !== '0' &&
      vm.detail.cashWithdrawal.firstPossibleDate
    ) {
      vm.showCashWithdrawalInfo = true
      vm.nearestWithdrawalDate = parseISO(vm.detail.cashWithdrawal.firstPossibleDate)
    }
  }

  vm.showDetail = function () {
    vm.showMore = !vm.showMore
  }
}
