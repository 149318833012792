var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesBranchManagerModal', {
    template: require('./places-branch-manager-modal.component.tpl.html'),
    controller: PlacesBranchManagerModalCtrl,
    controllerAs: 'vm',
    bindings: {
      objectName: '<',
      onClose: '&'
    }
  })

PlacesBranchManagerModalCtrl.$inject = ['contentService']

function PlacesBranchManagerModalCtrl (contentService) {
  var vm = this

  vm.$onInit = function () {
    vm.fileContent = null
    contentService.getStaticHtml('internet', vm.objectName).promise.then(function (res) {
      vm.fileContent = res.data
    })
  }
}
