/* global angular */

angular.module('siteMapCmp', [])
  .component('siteMap', {
    template: require('./sitemap.component.tpl.html'),
    controller: SiteMapCtrl,
    controllerAs: 'vm',
    scope: true
  })

SiteMapCtrl.$inject = ['placesService', '$filter']
function SiteMapCtrl (placesService, $filter) {
  var vm = this
  vm.init = init

  function init () {
    var request = {}
    request.country = 'CZ'
    request.detail = 'NORMAL'
    request.types = 'BRANCH'
    request.sort = 'id.asc'

    placesService.search(request).then(function (response) {
      vm.branches = response.data.items
      request.types = 'ATM'
      placesService.search(request).then(function (response) {
        vm.atms = response.data.items
      })
    })
  }

  vm.getUrl = function (data) {
    return placesService.getDetailUrl(data, $filter('translate')('PLACES.URL.' + data.type.toUpperCase()))
  }
}
