var addDays = require('date-fns/fp/addDays').default
var format = require('date-fns/fp/format').default
var isWithinInterval = require('date-fns/fp/isWithinInterval').default
var isSameMinute = require('date-fns/fp/isSameMinute').default
var isBefore = require('date-fns/fp/isBefore').default
var isAfter = require('date-fns/fp/isAfter').default
var differenceInHours = require('date-fns/fp/differenceInHours').default
var isSameDay = require('date-fns/fp/isSameDay').default
var parseISO = require('date-fns/fp/parseISO').default
var setDayWithOptions = require('date-fns/fp/setDayWithOptions').default
var differenceInDays = require('date-fns/fp/differenceInDays').default
var addWeeks = require('date-fns/fp/addWeeks').default
var startOfDay = require('date-fns/fp/startOfDay').default
var getDay = require('date-fns/fp/getDay').default

placesOpeningHoursService.$inject = []
const DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
const OUTAGE_TYPES = {
  CHANGED: 'CHANGED_OPENING_HOURS',
  CLOSED: 'CLOSED'
}
const OUTAGE_TYPES_CASHDESK = {
  CHANGED: 'CHANGED_OPENING_HOURS_CASHDESK',
  CLOSED: 'CLOSED_CASHDESK'
}

function placesOpeningHoursService () {
  const outagesTypes = Object.values(OUTAGE_TYPES)
  const outagesCashdeskTypes = Object.values(OUTAGE_TYPES_CASHDESK).concat(outagesTypes)
  var self = {
    normalize: normalize,
    normalizeToDate: normalizeToDate,
    isDateInOpeningInterval: isDateInOpeningInterval,
    getStartOfHourMinuteDate: getStartOfHourMinuteDate,
    getEndOfHourMinuteDate: getEndOfHourMinuteDate,
    getNextDaysOpeningHours: getNextDaysOpeningHours,
    outagesTypes: outagesTypes,
    outagesCashdeskTypes: outagesCashdeskTypes,
    outagesCashdeskTypesOnly: Object.values(OUTAGE_TYPES_CASHDESK)
  }

  function getNextDaysOpeningHours(responseOpeningHours, responseOutages, nextDaysCount, allowedOutagesTypes) {
    const nextDaysOpeningHours = []

    const startDate = new Date()

    for(i = 0; i< nextDaysCount; i++) {
      const currentDate = addDays(i)(startDate)
      const currentDay = DAYS[getDay(currentDate)]

      const finalDayItem = {
        weekDay: currentDay,
        date: format('d. M.')(currentDate),
        openingHours: getDateIntervals(currentDate, currentDay, responseOpeningHours, responseOutages, allowedOutagesTypes)
      }
      nextDaysOpeningHours.push(finalDayItem)
    }
    return nextDaysOpeningHours
  }

  function getDateIntervals (date, day, responseOpeningHours, responseOutages, allowedOutagesTypes) {
    let dayIntervals = []
    const dayOpeningHours = responseOpeningHours.filter(function(oh) {
      return oh.weekday === day
    })[0]

    const currentDayOutage = getOutageByDate(date, responseOutages, allowedOutagesTypes)
    if(currentDayOutage) {
      // evaluate outage opening hours
      dayIntervals = parseOutageIntervals(currentDayOutage)
    } else {
      // evaluate standard opening hours
      if(dayOpeningHours) {
        dayIntervals = dayOpeningHours.intervals
      }
    }

    return dayIntervals
  }

  function parseOutageIntervals (outage) {
    let outageIntervals = []
    if(outage.outageType === OUTAGE_TYPES.CLOSED || outage.outageType === OUTAGE_TYPES_CASHDESK.CLOSED) {
      outageIntervals = []
    } else {
      // get interval limits - return array [{from: isodate, to: isodate}]
      const outageIntervalLimits = getIntervalLimits(outage)
      outageIntervalLimits.forEach(function(interval) {
        outageIntervals.push(createIntervalFromISO(interval.from, interval.to))
      })
    }

    return outageIntervals
  }

  function getIntervalLimits (outage) {
    let intervals = []

    if(outage.morningEnd === outage.afternoonStart) {
      intervals.push({
        from: outage.morningStart,
        to: outage.afternoonEnd
      })
      return intervals
    }

    if(outage.morningStart && outage.morningEnd) {
      intervals.push({
        from: outage.morningStart,
        to: outage.morningEnd
      })
    }

    if(outage.afternoonStart && outage.afternoonEnd) {
      intervals.push({
        from:  outage.afternoonStart,
        to: outage.afternoonEnd
      })
    }
    return intervals
  }

  function createIntervalFromISO (startISO, endISO) {
    return {
      from: format('HH:mm')(parseISO(startISO)),
      to: format('HH:mm')(parseISO(endISO)),
    }
  }

  function getOutageByDate (date, responseOutages, allowedOutagesTypes) {
    return responseOutages.filter(function(otg) {
      return isSameDay(parseISO(otg.outageDate))(date) && allowedOutagesTypes.includes(otg.outageType)
    })[0]
  }

  function normalizeToDate (openingHours, outages, accessType, date, preferRealOpeningHours) {
    var realOpeningHours = JSON.parse(JSON.stringify(openingHours))

    openingHours = evaluateAndSetDate(openingHours, date)
    realOpeningHours = evaluateAndSetDate(realOpeningHours, date)

    openingHours = setOutagesTooltips(openingHours, outages)
    realOpeningHours = getRealOpeningHours(realOpeningHours, outages)
    var realToDate = new DayOpeningHours(realOpeningHours, date)
    var realTomorrow = new DayOpeningHours(realOpeningHours, addDays(1)(date))
    var todayOpeningHours = realOpeningHours.find(function(item) {
      return item.weekday === DAYS[getDay(date)]
    }) || {}
    return {
      openingHours: openingHours.map(function(day, index) {
        return {
          date: day.date,
          isNextWeekDate: day.isNextWeekDate,
          weekday: day.weekday,
          regular: {
            intervals: openingHours[index].intervals,
            tooltips: openingHours[index].tooltips
          },
          real: {
            intervals: realOpeningHours[index].intervals,
            tooltips: realOpeningHours[index].tooltips
          }
        }
      }),
      isClosedByOutage: [OUTAGE_TYPES_CASHDESK.CLOSED, OUTAGE_TYPES.CLOSED].includes(todayOpeningHours.outageType),
      isOpenNow: realToDate.isOpen,
      todayOpening: realToDate.relativeToOpening,
      todayClosingInHours: realToDate.endOfDayClosingInHours,
      hasAnyUpcomingOpeningToday: !!(realToDate.relativeToDayUpcomingIntervals && realToDate.relativeToDayUpcomingIntervals.length),
      hasAnyPastOpeningToday: !!(realToDate.relativeToDayPastIntervals && realToDate.relativeToDayPastIntervals.length),
      hasAnyOpeningTomorrow: !!(realTomorrow.relativeToOpening && realTomorrow.relativeToOpening.intervals && realTomorrow.relativeToOpening.intervals.length),
      isAtmNonstop: accessType === 'nepřetržitě',
      preferRealOpeningHours: preferRealOpeningHours
    }
  }

  function normalize (openingHours, outages, accessType, preferRealOpeningHours) {
    return normalizeToDate(openingHours, outages, accessType, new Date(), preferRealOpeningHours)
  }

  function DayOpeningHours (openingHours, relativeToDate) {
    var _relativeToUpperCaseDayName = format('iiii')(relativeToDate).toUpperCase()
    this.relativeToOpening = openingHours.filter(function (item) {
      return item.weekday === _relativeToUpperCaseDayName
    })[0]
    var _relativeToOpeningInterval = this.relativeToOpening && (this.relativeToOpening.intervals || [])
      .filter(function (item) {
        return isWithinInterval({
          start: getStartOfHourMinuteDate(relativeToDate, item.from),
          end: getEndOfHourMinuteDate(relativeToDate, item.to) })(relativeToDate)
      })[0]
    var _relativeToLastOpeningInterval = this.relativeToOpening && this.relativeToOpening.intervals &&
      this.relativeToOpening.intervals[this.relativeToOpening.intervals.length - 1]
    this.relativeToDayUpcomingIntervals = this.relativeToOpening && (this.relativeToOpening.intervals || [])
      .filter(function (interval) {
        return isSameMinute(getStartOfHourMinuteDate(relativeToDate, interval.from))(relativeToDate) ||
          isBefore(getStartOfHourMinuteDate(relativeToDate, interval.from))(relativeToDate)
      })
    this.relativeToDayPastIntervals = this.relativeToOpening && (this.relativeToOpening.intervals || [])
      .filter(function (interval) {
        return isAfter(getEndOfHourMinuteDate(relativeToDate, interval.from))(relativeToDate)
      })
    this.isOpen = !!_relativeToOpeningInterval
    this.closingInHours = this.isOpen &&
      differenceInHours(relativeToDate)(getEndOfHourMinuteDate(relativeToDate, _relativeToOpeningInterval.to))
    this.endOfDayClosingInHours = _relativeToLastOpeningInterval &&
      differenceInHours(relativeToDate)(getEndOfHourMinuteDate(relativeToDate, _relativeToLastOpeningInterval.to))
  }

  function getRealOpeningHours (openingHours, outages) {
    return openingHours.map(function (day) {
      day.tooltips = outages && outages
        .filter(function (outage) {
          return isSameDay(parseISO(outage.outageDate))(day.date)
        })
        .map(function (outage) {
          var realIntervals = []
          var regularIntervals = day.intervals

          if (outage.outageType === OUTAGE_TYPES.CHANGED || outage.outageType === OUTAGE_TYPES_CASHDESK.CHANGED) {
            if (outage.morningEnd === outage.afternoonStart) {
              realIntervals.push({
                from: format('HH:mm')(parseISO(outage.morningStart)),
                to: format('HH:mm')(parseISO(outage.afternoonEnd))
              })
            } else {
              if (outage.morningStart && outage.morningEnd) {
                realIntervals.push({
                  from: format('HH:mm')(parseISO(outage.morningStart)),
                  to: format('HH:mm')(parseISO(outage.morningEnd))
                })
              }

              if (outage.afternoonStart && outage.afternoonEnd) {
                realIntervals.push({
                  from: format('HH:mm')(parseISO(outage.afternoonStart)),
                  to: format('HH:mm')(parseISO(outage.afternoonEnd))
                })
              }
            }
          }

          day.intervals = realIntervals
          day.outageType = outage.outageType
          return {
            type: outage.outageType,
            date: format('d. M. yyyy')(day.date),
            intervals: regularIntervals.map(function (interval) {
              return interval.from + ' - ' + interval.to
            }).join(', '),
            notes: {}
          }
        }) || []
      return day
    })
  }

  function evaluateAndSetDate (openingHours, date) {
    var ISO_WEEKDAY = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

    return openingHours.map(function (day) {
      var weekdate = setDayWithOptions({weekStartsOn: 1})(ISO_WEEKDAY.indexOf(day.weekday))(date)

      if (differenceInDays(date)(weekdate) < 0 ) {
        day.date = startOfDay(addWeeks(1)(weekdate))
        day.isNextWeekDate = true
      } else {
        day.date = startOfDay(weekdate)
        day.isNextWeekDate = false
      }
      return day
    })
  }

  function setOutagesTooltips (openingHours, outages) {
    return openingHours.map(function (day) {
      day.tooltips = (outages && outages
        .filter(function (outage) {
          return isSameDay(day.date)(parseISO(outage.outageDate))
        })
        .map(function (outage) {
          var intervals = []

          if (outage.outageType === 'CHANGED_OPENING_HOURS') {
            if (outage.morningEnd === outage.afternoonStart) {
              intervals.push({
                from: format('HH:mm')(parseISO(outage.morningStart)),
                to: format('HH:mm')(parseISO(outage.afternoonEnd))
              })
            } else {
              if (outage.morningStart && outage.morningEnd) {
                intervals.push({
                  from: format('HH:mm')(parseISO(outage.morningStart)),
                  to: format('HH:mm')(parseISO(outage.morningEnd))
                })
              }

              if (outage.afternoonStart && outage.afternoonEnd) {
                intervals.push({
                  from: format('HH:mm')(parseISO(outage.afternoonStart)),
                  to: format('HH:mm')(parseISO(outage.afternoonEnd))
                })
              }
            }
          }

          return {
            type: outage.outageType,
            date: format('d. M. yyyy')(day.date),
            intervals: intervals.map(function (interval) {
              return interval.from + ' - ' + interval.to
            }).join(', '),
            notes: {
              cs: outage.publicNoteCs,
              en: outage.publicNoteEn
            }
          }
        })) || []
      return day
    })
  }

  function isDateInOpeningInterval (date, intervals) {
    return !!intervals.filter(function (interval) {
      return isWithinInterval({
        start: getStartOfHourMinuteDate(date, interval.from),
        end: getEndOfHourMinuteDate(date, interval.to) })(date)
    }).length
  }

  function getStartOfHourMinuteDate (date, hourMinute) {
    var splitted = hourMinute.split(':')
    return new Date(new Date(date.getTime()).setHours(splitted[0], splitted[1], 0, 0))
  }

  function getEndOfHourMinuteDate (date, hourMinute) {
    var splitted = hourMinute.split(':')
    return new Date(new Date(date.getTime()).setHours(splitted[0], splitted[1], 59, 999))
  }

  return self
}

exports.placesOpeningHoursService = placesOpeningHoursService
