/* global angular MarkerClusterer google */

angular.module('mapCmp', [])
  .component('map', {
    template: require('./map.component.tpl.html'),
    controller: MapCtrl,
    controllerAs: 'vm',
    scope: true,
    bindings: {
      showFullDesc: '<',
      detail: '<',
      list: '<',
      type: '<',
      lng: '<',
      lat: '<',
      zoom: '=',
      large: '='
    }
  })

MapCtrl.$inject = ['wgService', 'placesService', '$filter', 'basePath', '$timeout', '$compile', '$scope', 'placesStateService']

function MapCtrl (wgService, placesService, $filter, basePath, $timeout, $compile, $scope, placesStateService) {
  var vm = this
  var data

  // init scope properties
  vm.ecb = wgService.getAppName().indexOf('Ecb') !== -1
  vm.pfcs = wgService.getAppName().indexOf('Csps') !== -1
  vm.encs = wgService.getAppName().indexOf('Encs') !== -1

  var clusterPath = basePath + 'img/cluster.png'
  var pinPath = basePath + 'img/map_pin.png'
  var bigPinPath = basePath + 'img/big_pin_red.png'
  var mePath = basePath + 'img/location.png'

  var clusterStyles = [
    {
      textColor: 'rgb(0, 154, 226)',
      textSize: 16,
      fontWeight: 700,
      url: clusterPath,
      height: 36,
      width: 37
    },
    {
      textColor: 'rgb(0, 154, 226)',
      textSize: 16,
      fontWeight: 700,
      url: clusterPath,
      height: 36,
      width: 37
    },
    {
      textColor: 'rgb(0, 154, 226)',
      textSize: 16,
      fontWeight: 700,
      url: clusterPath,
      height: 36,
      width: 37
    }]

  var mapStyle = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        { saturation: -100 }
      ]
    }
  ]

  // functions
  vm.init = init
  vm.addMarkers = addMarkers
  vm.addMe = addMe

  function initMap () {
    vm.mapType = new window.google.maps.StyledMapType(mapStyle, { name: 'Grayscale' })

    var mapElement = document.getElementById('map')
    vm.map = new window.google.maps.Map(mapElement, {
      zoom: vm.zoom,
      center: { lat: vm.lat, lng: vm.lng },
      scrollwheel: false,
      fullscreenControl: true,
      streetViewControl: false
    })

    vm.map.mapTypes.set('grayscale', vm.mapType)
    vm.map.setMapTypeId('grayscale')

    addMarkers()
    checkIfNavigatorIsAllowed()
  }

  function checkIfNavigatorIsAllowed () {
    return new Promise(function (resolve, reject) {
      return navigator.permissions ? navigator.permissions.query({
        name: 'geolocation'
      }).then(function (permission) {
        return permission.state === 'granted' ? addMe() : resolve(null)
      }) : reject(new Error('Permission API is not supported'))
    })
  }

  function addMe () {
    navigator.geolocation.getCurrentPosition(function (position) {
      var myPosition = { lat: position.coords.latitude, lng: position.coords.longitude }
      if (vm.me) {
        vm.me.setMap(null)
      }
      vm.me = new window.google.maps.Marker({
        position: myPosition,
        title: $filter('translate')('PLACES.BTN.MAP.ME'),
        icon: mePath
      })
      vm.me.setMap(vm.map)
    }, function (error) {
      console.error('Unable to get current geolocation: ' + error.message)
    })
  }

  function addMarkers () {
    var infowindows = []
    angular.forEach(vm.markers, function (marker) {
      marker.setMap(null)
    })
    vm.markers = []
    if (angular.isDefined(vm.claster)) {
      vm.claster.clearMarkers()
    }

    angular.forEach(data, function (item, index) {
      var marker = null

      if (vm.detail && vm.detail.id === item.id) {
        marker = new window.google.maps.Marker({
          position: item.location,
          map: vm.map,
          title: item.name,
          icon: bigPinPath
        })
      } else {
        marker = new window.google.maps.Marker({
          position: item.location,
          map: vm.map,
          title: item.name,
          icon: pinPath
        })
      }

      marker.addListener('click', function () {
        var branch = angular.copy(item)
        if (branch.type === 'BRANCH') {
          branch = placesService.processBranchObject(branch)
        } else {
          branch = placesService.processATMObject(branch)
        }
        if (!infowindows[branch.id]) {
          infowindows[branch.id] = new window.google.maps.InfoWindow({
            content: '<marker-content full-detail="' + angular.isUndefined(vm.detail) + '" id="marker-content-' + branch.id + '" branch=\'' + JSON.stringify(branch) + '\'></marker-content>'
          })
          window.google.maps.event.addListener(infowindows[branch.id], 'domready', function () {
            $scope.$evalAsync(function () {
              $compile(document.getElementById('marker-content-' + branch.id))($scope)
            })
          })
        }

        if (!infowindows[branch.id].map) {
          infowindows[branch.id].open(vm.map, marker)
        } else {
          infowindows[branch.id].close()
          infowindows[branch.id] = undefined
        }

        for (var i = 0; i < infowindows.length; i++) {
          if (infowindows[i] && i !== branch.id) {
            infowindows[i].close()
            infowindows[i] = undefined
          }
        }
      })

      vm.markers.push(marker)

      if (index === data.length - 1) {
        vm.claster = new MarkerClusterer(vm.map, vm.markers, {
          minimumClusterSize: 5,
          imagePath: window.location.origin,
          styles: clusterStyles
        })
      }
    })

    // fit first ten places and searched location (except my position)
    var bounds = new google.maps.LatLngBounds()
    bounds.extend({ lat: vm.lat, lng: vm.lng })
    for (var i = 0, l = Math.min(vm.markers.length, 2); i < l; i++) {
      bounds.extend(vm.markers[i].getPosition())
    }
    vm.map.fitBounds(bounds)
    var fitBoundsListener = google.maps.event.addListener(vm.map, 'bounds_changed', function () {
      google.maps.event.removeListener(fitBoundsListener)
      if (vm.map.getZoom() > 16) {
        vm.map.setZoom(16)
      }
    })
  }

  function init () {
    load(vm.zoom, vm.lat, vm.lng)
    vm.markers = []
  }

  function load (zoom, lat, lng) {
    var request = {}

    data = []
    if (angular.isDefined(vm.detail) && !vm.list) {
      data = [vm.detail]
      $timeout(function () {
        initMap()
      }, 0)
    } else {
      request.country = 'CZ'
      request.detail = 'FULL'
      request.lat = lat
      request.lng = lng
      request.types = placesStateService.getState().placeType
      request.flags = placesStateService.getFlags()
      if (request.flags.indexOf('HC') === -1) {
        request.radius = 50000
      }
      if (placesStateService.getState().searchType === 'EXCHANGE') {
        request.currencies = placesStateService.getCurrencies()
      }
      if (vm.encs) {
        var undefFlag = request.flags.indexOf(undefined)
        var nullFlag = request.flags.indexOf(null)
        if (undefFlag !== -1) {
          request.flags.splice(undefFlag, 1)
        }
        if (nullFlag !== -1) {
          request.flags.splice(nullFlag, 1)
        }
        if (request.flags && request.flags.indexOf('VIDEOCONFERENCE') === -1 && request.flags.indexOf('NONFINANCE') === -1 &&
     request.flags.indexOf('VIDEOCONFERENCE_OR_NONFINANCE') === -1) {
          request.flags.push('VIDEOCONFERENCE_OR_NONFINANCE')
        }
      }

      if (angular.isDefined(request.flags) && request.flags.indexOf('OPEN_NOW') > -1) {
        request.openTime = 'now'
        request.flags.splice(request.flags.indexOf('OPEN_NOW'), 1)
      }
      
      if ((request.flags && (~request.flags.indexOf('KC') || ~request.flags.indexOf('LKC')))) {
        delete request.radius
        delete request.size
      }

      placesService.search(request)
        .then(function (response) {
          vm.isErrorBackend = false
          data = response.data.items
          $timeout(function () {
            initMap()
          }, 0)
        })
        .catch(function () {
          vm.isErrorBackend = true
        })
    }
  }
}
