/* global angular */

require('../../services/content.service.js')

angular.module('WcmImageCmp', [])
  .component('wcmImage', {
    template: require('./wcm-image.component.tpl.html'),
    controller: WcmImageCtrl,
    controllerAs: 'vm',
    bindings: {
      portal: '@',
      objectName: '@'
    }
  })

WcmImageCtrl.$inject = ['contentService']

function WcmImageCtrl (contentService) {
  var vm = this

  vm.$onInit = function () {
    vm.blobUrl = null
    contentService.getStaticBlobUrl(vm.portal, vm.objectName).promise
      .then(function (res) {
        vm.blobUrl = res.data
      })
  }

  vm.$onDestroy = function () {
    var urlCreator = window.URL || window.webkitURL
    urlCreator.revokeObjectURL(vm.blobUrl)
  }
}
