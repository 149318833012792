exports.en = {
  PLACES_DETAIL: {
    ATM: {
      NUMBER: 'ATM number:',
      SERVICES: {
        TITLE: 'This ATM can help you with:',
        SHOW_MORE: 'Show more'
      },
      OUT_OF_ORDER: 'Out of order',
      EMPLOYEES: 'ATM is available only for employees with access to the building.'
    },
    BRANCH: {
      PRODUCTS: {
        TITLE: 'Our advisors at the branch <strong>will help you with</strong>',
        SHOW_MORE: 'Show more'
      },
      SPECIALISTS: {
        TITLE: 'Specialists',
        SHOW_MORE: 'Show more',
        SHOW_LESS: 'Show less'
      },
      LKC_SPECIALISTS: {
        TITLE: 'Managers',
        SHOW_MORE: 'Show more',
        SHOW_LESS: 'Show less'
      },
      LKC_MANAGER: {
        POSITION: 'LCC Director',
        PROFILE: 'Profile'
      },
      RKC_MANAGER: {
        POSITION: 'RCC Director',
        PROFILE: 'Profile'
      },
      MANAGER: {
        TITLE: '<strong>Branch</strong> Manager',
        POSITION: 'Branch Manager',
        PROFILE: 'Manager Profile'
      },
      CALLBOX: {
        TITLE: '<strong>Do you need advice</strong> before visiting the branch?',
        CHAT: 'Chat'
      },
      ORDER: {
        TITLE: '<strong>Make an appointment on-line</strong> and save time at the branch',
        ORDER_TO: 'Appointment',
        CASH: 'At cash desk',
        DESK: 'With a banker',
        GEORGE: 'With my banker in George',
        QMATIC_URL_CASH: 'appointment/cash',
        QMATIC_URL_DESK: 'appointment/cashless'
      },
      OUT_OF_ORDER: 'Out of order',
      OUTAGE_NEXT_WEEK_1: 'Opening hours will be',
      OUTAGE_NEXT_WEEK_2: 'changed next week.',
      CLOSED_NEXT_WEEK: 'Branch will be closed',
      SHOW: 'Show',
      HIDE: 'Hide'
    },
    NOTES: {
      CASH_WITHDRAW: {
        CASH_LIMIT: 'Do you need to withdraw a larger amount of cash at this branch?',
        CASH_LIMIT_EXCESS1: 'Please check the withdrawal limits and',
        CASH_LIMIT_EXCESS2: 'notify the branch in advance.',
        CASH_LIMIT_EXCESS3: 'The\u00A0nearest possible date for over\u00A0limit withdrawal is',
        REPORT: 'Find out more'
      },
      SHOW_LIMIT: 'View limits',
      REPORT: 'Notify online'
    },
    SERVICES: {
      ACTIVATION_CODE: 'Activation code print',
      CONTACTLESS_ATM: 'Contactless',
      CONTACTLESS_SYSTEM_PILSEN: 'Topping up transport cards',
      WEEKEND_OFFICE: 'Weekend hours',
      WITHDRAW_ATM: 'ATM for withdrawals',
      DEPOSIT_ATM: 'ATM for deposits',
      TRANSFER_ATM: 'ATM for transfers',
      BAR_CODE_READER_ATM: 'Barcode reader',
      QR_CODE_READER_ATM: 'QR code reader',
      FOR_BLIND_ATM: 'For the visually impaired',
      ATM_ACCESSIBILITY: 'Barrier-free access',
      HEARING_IMPAIR: 'Equipment for the hearing impaired',
      BARRIER_PARTIALY_FREE: 'Partially barrier-free access',
      BARRIER_FREE: 'Barrier-free access',
      ATM: 'ATM in building',
      CASH_DESK: 'With cash desk',
      SELF_SERVICE: 'Self-service zone',
      EQUIPMENT_17: 'Mincomat coin deposit machine',
      EQUIPMENT_13: 'Special application for the hearing-impaired',
      EQUIPMENT_24: 'ATM for deposits at the branch',
      EQUIPMENT_12: 'Dual ATM (deposits and withdrawals)',
      EQUIPMENT_25: 'Dual ATM (deposits and withdrawals)',
      EQUIPMENT_23: 'ATM for withdrawals at the branch',
      EQUIPMENT_11: 'ATM for payments at the branch',
      EQUIPMENT_9: 'Children’s corner',
      EQUIPMENT_7: 'WiFi',
      EQUIPMENT_6: 'Night safe',
      EQUIPMENT_1: 'Safety deposit box',
      EQUIPMENT_20: 'Offers English language',
      EQUIPMENT_16: 'For the visually impaired',
      EQUIPMENT_18: 'Videoconference',
      EXCHANGE: 'Exchange',
      EXCHANGE_TOOLTIP: 'At this branch we exchange these currencies: ',
      DEPOSIT_TOOLTIP_FULL:
        'At this branch, you can deposit or withdraw foreign currency from your CS account. EUR and USD are the currencies most commonly available at the branch. To withdraw other currencies, contact your branch for information about availability or place an order using the form for above-limit withdrawals.',
      DEPOSIT_TOOLTIP_SMALL:
        'At this branch, you can deposit or withdraw these foreign currencies from your CS account %{allCurrencies}. The  most commonly available currencies at the branch are %{currencies}. To withdraw other currencies, contact your branch for information about the availability of these currencies or place an order using the form for above-limit withdrawals.',
      DEPOSITS: 'Foreign Currency Deposits/Withdrawals'
    },
    PRODUCTS: {
      'Akcie, dluhopisy a služby CDCP': 'Shares, Bonds and CDCP Services',
      'Bezpečnostní schránky': 'Safe Deposit Boxes',
      'Dětské a studentské produkty': 'Products for Children and Students',
      'Dětské a studentské produkty (mimo vkladních knížek)':
        'Products for Children and Students (excluding passbooks)',
      'Hypotéka Premier': 'Premier Home Loan',
      Hypotéky: 'Mortgages',
      'Investiční produkty': 'Investment Products',
      'Karty na nákupy': 'Cards for Purchases',
      'Letištní salónky': 'Airport Lounges',
      Mincomat: 'Mincomat Coin Deposit Machine',
      'Moje zdravé finance': 'My Healthy Finances',
      'Účet Premier': 'Premier Account',
      'Osobní účty': 'Personal Accounts',
      'Penzijní připojištění': 'Supplementary Pension Scheme',
      'Platební karty': 'Payment Cards',
      'Platební karty /Visa Infinit, MC World Elite/': 'Payment Cards (Visa Infinite, MC World Elite)',
      'Podnikatelské termínované vklady': 'Business Term Deposits',
      'Podnikatelské účty': 'Business Accounts',
      'Podnikatelské úvěry': 'Business Loans',
      'Pojistné produkty': 'Insurance Products',
      'Premier benefit club': 'Premier Benefit Club',
      Půjčky: 'Loans',
      'Směnárenské služby': 'Foreign Exchange Services',
      'Spoření Premier': 'Premier Savings Account',
      'Spořicí a vkladové produkty': 'Savings and Deposit Products',
      'Spořicí a vkladové produkty (mimo vkladních knížek)': 'Savings and Deposit Products (excluding passbooks)',
      'Stavební spoření': 'Building Savings Account',
      'Úvěr Premier': 'Premier Loan',
      'Vklady a výběry cizí měny z účtu': 'Foreign Currency Deposits/Withdrawals',
      'Vklady a výběry hotovosti': 'Cash Deposits and Withdrawals',
      'Vklady a výběry pouze prostřednictvím bankomatu': 'Cash deposit and withdrawal via ATMs only',
      'Vklady a výběry hotovosti pouze prostřednictvím bankomatu': 'Cash deposit and withdrawal via ATMs only',
      'Výběry hotovosti pouze prostřednictvím bankomatu': 'Cash withdrawals Only via ATM',
      'Výběry pouze prostřednictvím bankomatu': 'Cash withdrawals Only via ATM',
      'Životní pojištění': 'Life Insurance',
      'Vklad Z-obalem': 'Cash deposit in sealed packages'
    },
    MAP: {
      SHOW: 'Show on map',
      HIDE: 'Hide map',
      NAVIGATE: 'Navigate'
    },
    BACK_TO_LIST: 'Back to list',
    ROUTE_SEARCH: 'search',
    BACKEND_ERROR:
      'We apologise, but the application is momentarily unavailable. Please contact us toll free on 800 207 207.'
  }
}
