exports.cs = {
  PLACES_WEEK_OPENING_HOURS: {
    DAY_NAME: {
      MONDAY: 'Pondělí',
      TUESDAY: 'Úterý',
      WEDNESDAY: 'Středa',
      THURSDAY: 'Čtvrtek',
      FRIDAY: 'Pátek',
      SATURDAY: 'Sobota',
      SUNDAY: 'Neděle'
    },
    BRANCH: {
      CLOSED: 'Pobočka je zavřená',
      CLOSED_NOW: 'Pobočka je nyní zavřená'
    },
    ATM: {
      CLOSED: 'Bankomat je nedostupný',
      CLOSED_NOW: 'Bankomat je nyní nedostupný'
    },
    TOOLTIP: {
      REGULAR: {
        CHANGED_OPENING_HOURS: '%{day} má pobočka výjimečně změněnou otevírací dobu. Obvyklá otevírací doba pro tento den je %{intervals}.',
        CLOSED: 'Pobočka je dne %{day} výjimečně uzavřená. Obvyklá otevírací doba pro tento den je %{intervals}.'
      },
      OUTAGE: {
        CHANGED_OPENING_HOURS: '%{day} bude mít pobočka výjimečně změněnou otevírací dobu. Otevírací doba pro tento den bude %{intervals}.',
        CLOSED: 'Pobočka bude dne %{day} výjimečně uzavřená.'
      },
      PREFER_REAL: {
        CHANGED_OPENING_HOURS: 'Výjimečná otevírací doba pouze pro %{day}.',
        CLOSED: '%{day} má pobočka výjimečně zavřeno.'
      }
    }
  }
}
