exports.cs = {
  PLACES_LIST: {
    ITEM: {
      NAVIGATE: {
        BRANCH: 'Navigovat<br>na pobočku',
        ATM: 'Navigovat<br>k bankomatu'
      }
    },
    FILTER: {
      BRANCH: {
        OPEN_NOW: 'Otevřeno nyní',
        OPEN_WEEKEND: 'Otevřeno o víkendu',
        HANDICAP: 'Pro handicapované',
        BARRIER_FREE: 'Bezbarierový přístup',
        HEARING_IMPAIR: 'Online tlumočení/přepis',
        DIGITAL_VOICE: 'Hlasová navigace pro slabozraké a nevidomé',
        CASH_DESK: 'S pokladnou',
        CURR_WITHDRAW: 'Chci vložit/vybrat cizí měnu',
        GOLD: 'S bezpečnostními schránkami',
        NONFINANCE: 'Specialista nefinančních služeb',
        VIDEOCONFERENCE: 'Videokonference'
      },
      ATM: {
        AVAILABLE_NOW: 'Dostupný právě teď',
        HANDICAP: 'Pro handicapované',
        ATM_ACCESSIBILITY: 'Bezbarierový přístup',
        FOR_BLIND_ATM: 'Bankomat pro nevidomé',
        DEPOSIT: 'Chci vložit peníze',
        PAYMENT: 'Chci provést platbu',
        PAYMENT_METHOD: 'Způsob platby',
        TRANSFER_ATM: 'Platbomat',
        BAR_CODE_READER_ATM: 'Čtečka QR kódu',
        QR_CODE_READER_ATM: 'Čtečka BAR kódu',
        FEATURES: 'Další funkce',
        CONTACTLESS_SYSTEM_PILSEN: 'Dobíjení dopravních karet',
        CONTACTLESS_ATM: 'Bezkontaktní bankomat',
        ACTIVATION_CODE: 'Tisk aktivačního kódu pro George klíč'
      },
      EXCHANGES: {
        COUNTRY: 'Česká republika',
        LONG_NAME: 'česká koruna',
        NAME: 'koruna',
        CURRENCIES: 'Měna, kterou chcete směnit',
        USD: 'Americký dolar',
        CHF: 'Švýcarský frank',
        HKD: 'Hongkongský dolar',
        EUR: 'Euro',
        DKK: 'Dánská koruna',
        CAD: 'Kanadský dolar',
        BGN: 'Bulharský lev',
        ZAR: 'Jihoafrický rand',
        NOK: 'Norská koruna',
        TND: 'Tuniský dinar',
        AUD: 'Australský dolar',
        RON: 'Rumunský lei',
        JPY: 'Japonský jen',
        PLN: 'Polský zlotý',
        GBP: 'Britská libra',
        HUF: 'Maďarský forint',
        SEK: 'Švédská koruna',
        NZD: 'Novozélandský dolar',
        TRY: 'Turecká lira',
        RUB: 'Ruský rubl'
      },
      COUNT: 'Filtrovat'
    },
    TAB: {
      BRANCH: 'Seznam poboček',
      ATM: 'Seznam bankomatů',
      DEPOSIT_ATM: 'Seznam vkladových bankomatů',
      TRANSFER_ATM: 'Seznam platbomatů',
      HC: 'Seznam hypotečních center',
      KC: 'Seznam regionálních korporátních center',
      EXCHANGE: 'Seznam směnáren',
      OMC: 'Seznam obchodních míst centrály',
      COMPANY: 'Seznam poboček',
      CSP: 'Seznam Erste Premier center',
      MAP: 'Mapa',
      PB: 'Seznam Erste Private Banking',
      ENCS: 'Seznam poboček',
      MOBILE: 'Seznam',
      LKC: 'Seznam poboček',
      PERSONAL_SENIOR: 'Seznam poboček s bankovnictvím Exclusive'
    }
  }
}
