/* global angular */

angular.module('AemBranches')
  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider
      .when('/', {
        template: require('./view/home.html')
      })
      .when('/sitemap', {
        template: require('./view/sitemap.html')
      })
      .when('/vyhledavani', {
        template: require('./view/search.html')
      })
      .when('/search', {
        template: require('./view/search.html')
      })
      .when('/vyhledavani/:address/:type', {
        template: require('./view/search.html')
      })
      .when('/objednani/zruseni/:hash', {
        template: require('./view/cancel.html')
      })
      .when('/order/cancel/:hash', {
        template: require('./view/cancel.html')
      })
      .when('/search/:address/:type', {
        template: require('./view/search.html')
      })
      .when('/marketplace/:id/:type', {
        template: require('./view/detail.html')
      })
      .when('/:id/:type/:string', {
        template: require('./view/detail.html')
      })
      .when('/:id/:type', {
        template: require('./view/detail.html')
      })
      .when('/:id/:type/:string/objednani', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/objednani', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/:string/appointment', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/appointment', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/:string/objednani/:service', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/:string/appointment/:service', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/objednani/:service', {
        template: require('./view/order.html')
      })
      .when('/:id/:type/appointment/:service', {
        template: require('./view/order.html')
      })
      .otherwise({
        redirectTo: '/'
      })
  }])
