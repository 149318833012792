/* global angular */

require('../order-form-success/order-form-success.component.js')
require('../order-form-cancel/order-form-cancel.component.js')

angular.module('orderCmp')
  .component('orderForm', {
    template: require('./order-form.component.tpl.html'),
    controller: OrderFormCtrl,
    controllerAs: 'vm',
    bindings: {
      selectedDate: '<',
      selectedTime: '<',
      branchId: '<',
      serviceId: '<',
      branchDetailUrl: '<'
    }
  })

OrderFormCtrl.$inject = ['appLoader', 'helperService', 'qmaticService', 'i18n', 'userTracking', '$filter']

function OrderFormCtrl (appLoader, helperService, qmaticService, i18n, userTracking, $filter) {
  var vm = this

  vm.$onInit = function () {
    // PLANNING | CREATED | CANCELLED
    vm.appointmentState = 'PLANNING'
    vm.appointment = null

    vm.showButton = true
    vm.phoneCodes = ['+420', '+421']
    vm.errorMsg = null
    vm.form = {
      notification_time: 15,
      lang: i18n.langCode,
      branch_id: vm.branchId,
      service_id: vm.serviceId,
      phoneCode: vm.phoneCodes[0]
    }
    vm.send = send
    vm.onAppointmentCancelled = onAppointmentCancelled
  }

  function send () {
    delete vm.formError
    vm.errorMsg = null
    vm.form.planned = helperService.getRestDateTime($filter('date')(vm.selectedDate, 'dd.MM.yyyy'), vm.selectedTime)
    validate()
  }

  function validate () {
    var errorFields = []
    var error = false
    vm.formError = {}
    if (angular.isUndefined(vm.form.firstname) || vm.form.firstname.length === 0) {
      vm.formError.firstname = true
      error = true
      errorFields.push('Jméno')
      userTracking.onValidityFailure('firstname')
    }
    if (angular.isUndefined(vm.form.lastname) || vm.form.lastname.length === 0) {
      vm.formError.lastname = true
      error = true
      errorFields.push('Příjmení')
      userTracking.onValidityFailure('lastname')
    }
    if (angular.isUndefined(vm.form.phone) || vm.form.phone.length < 5) {
      vm.formError.phone = true
      error = true
      errorFields.push('Telefon')
      userTracking.onValidityFailure('phone')
    } else {
      var number = vm.form.phone
      number = number.trim()
      number = number.split(' ').join('')
      var reg = /^\d+$/
      if (number.length === 9 && reg.test(number)) {
        // OK
      } else {
        vm.formError.phone = true
        error = true
        errorFields.push('Telefon')
      }
    }
    if (angular.isUndefined(vm.form.email) || vm.form.email.length === 0) {
      vm.formError.email = true
      error = true
      errorFields.push('Email')
      userTracking.onValidityFailure('email')
    } else {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(vm.form.email)) {
        vm.formError.email = true
        error = true
        errorFields.push('Email')
      }
    }

    if (error) {
      userTracking.onResponse(null, 'failed')
    } else {
      appLoader.show()
      vm.showButton = false
      var request = angular.copy(vm.form)
      request.phone = request.phone.split(' ').join('')
      request.phone = request.phone.replace('+420', '')
      request.system = 'EMIL'
      userTracking.onRequest(request)
      qmaticService.add(request)
        .then(function (response) {
          userTracking.onResponse(response)
          vm.appointmentState = 'CREATED'
          vm.appointment = response.data
        })
        .catch(function (error) {
          userTracking.onResponse(null, 'failed')
          if (error.status === 420) {
            vm.errorMsg = 'PLACES.APPT.ALREADY_ORDERED'
          }
          if (error.status === 503 || error.status === 500) {
            vm.errorMsg = 'PLACES.APPT.NOT_CREATED'
          }
          vm.showButton = true
        })
        .finally(function () {
          appLoader.hide()
        })
    }
  }

  function onAppointmentCancelled () {
    vm.appointmentState = 'CANCELLED'
  }
}
