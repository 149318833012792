var angular = require('angular')
var format = require('date-fns/fp/format').default
require('aem-libs-places-angularjs/src/constants/places-atms-sorting.js')
var placesAtmsSorting = require('aem-libs-places-angularjs/src/constants/places-atms-sorting.js').placesAtmsSorting

angular.module('PlacesDetailCmp')
  .factory('placesAtmsSorting', placesAtmsSorting)
  .component('placesServices', {
    template: require('./places-services.component.tpl.html'),
    controller: PlacesServicesCtrl,
    controllerAs: 'vm',
    bindings: {
      services: '<',
      cashDeskUntil: '<',
      serviceLabelUrlMap: '<'
    }
  })

PlacesServicesCtrl.$inject = ['$filter', 'placesAtmsSorting']

function PlacesServicesCtrl ($filter, placesAtmsSorting) {
  var vm = this

  vm.$onInit = function () {
    vm.servicesIcoMap = {
      EQUIPMENT_20: 'pobo-eng-obsluha',
      DEPOSIT_ATM: 'atm-vkladovy',
      WITHDRAW_ATM: 'atm-vyberovy',
      ATM: 'atm-vyberovy',
      EQUIPMENT_23: 'atm-vyberovy',
      EQUIPMENT_12: 'atm-vkladovy',
      EQUIPMENT_24: 'atm-vkladovy',
      EQUIPMENT_25: 'atm-vkladovy',
      TRANSFER_ATM: 'pobo-platbomat',
      EQUIPMENT_11: 'pobo-platbomat',
      EQUIPMENT_7: 'pobo-wifi',
      EQUIPMENT_13: 'atm-neslysici',
      EQUIPMENT_16: 'atm-nevidomi',
      SELF_SERVICE: 'pobo-samoobsluzna-zona',
      EQUIPMENT_17: 'pobo-mincomat',
      WEEKEND_OFFICE: 'pobo-vikendova',
      EQUIPMENT_6: 'nocni-trezor',
      EQUIPMENT_1: 'pobo-bezp-schranky',
      EQUIPMENT_9: 'pobo-detsky-koutek',
      EXCHANGE: 'pobo-smenarna',
      DEPOSITS: 'pobo-smenarna',
      ATM_ACCESSIBILITY: 'pobo-bezbarier',
      BARRIER_PARTIALY_FREE: 'pobo-castecne-bezbarier',
      BARRIER_FREE: 'pobo-bezbarier',
      FOR_BLIND_ATM: 'atm-nevidomi',
      HEARING_IMPAIR: 'atm-neslysici',
      ACTIVATION_CODE: 'atm-identita-aktivacni-kod',
      BAR_CODE_READER_ATM: 'atm-ctecka-bar',
      QR_CODE_READER_ATM: 'atm-ctecka-qr',
      CONTACTLESS_SYSTEM_PILSEN: 'atm-dopravni-karty',
      CONTACTLESS_ATM: 'atm-contactless',
      EQUIPMENT_18: 'video-conf',
      CASH_DESK: 'cash-desk'
    }

    if(vm.cashDeskUntil){
      var cashUntilTime = Math.floor(new Date(vm.cashDeskUntil).getTime() / (1000 * 60 * 60 * 24))
      var toDayTime = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24))
      var differenceInDays = cashUntilTime - toDayTime
      if ((cashUntilTime >= toDayTime) && (differenceInDays <= 30)) { // datum do kdy má pokladnu je v budoucnu max 30 dní
        var label = $filter('translate')('PLACES.QUICK.BRANCH.UNTIL')
        vm.cashDeskLabel = label + ' ' + format('d. M. yyyy')(new Date(vm.cashDeskUntil) )

      }
    }
    const filteredServices = ['ATM']
    vm.services = vm.services.filter(function(service) {
      return filteredServices.indexOf(service.flag) < 0
    }).map(function (service) {
      service.label = $filter('translate')('PLACES_DETAIL.SERVICES.' + service.flag)
      var mapping = vm.serviceLabelUrlMap && vm.serviceLabelUrlMap.filter(function (element) {
        return service.label === element.label
      })[0]
      if (mapping) {
        service.url = mapping.faqUrl
      }

      return service
    })
    function priorityOrdering () {
      angular.forEach(vm.services, function (service, i) {
        var index = placesAtmsSorting.ATM_ORDERED_SERVICES.indexOf(service.flag)
        if (index !== -1) {
          service.index = index
        }
      })
    }

    priorityOrdering();
  }
}
