var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesBranchSpecialists', {
    template: require('./places-branch-specialists.component.tpl.html'),
    controller: PlacesBranchSpecialistsCtrl,
    controllerAs: 'vm',
    bindings: {
      branchId: '<',
      specialists: '<',
      isLkc: '<'
    }
  })

PlacesBranchSpecialistsCtrl.$inject = []

function PlacesBranchSpecialistsCtrl () {
  var vm = this
  var specialistIcoMap = {
    PERSONAL: 'osobni',
    MORTGAGE: 'hypo',
    BUSINESS: 'podnik',
    COMPANY: 'korporatni',
    INSURANCE: 'pojisteni',
    INVESTMENT: 'invest'
  }

  vm.$onChanges = function (changeObj) {
    vm.specialists = changeObj.specialists.currentValue
    if(!vm.isLkc) {
      specialistFilter()
    }
  }

  vm.$onInit = function () {
    vm.equals = angular.equals
    if(!vm.isLkc) {
      specialistFilter()
    }
  }

  function specialistFilter () {
    if (vm.specialists.length) {
      vm.specialists = vm.specialists.filter(function (specialist) {
        return specialist.type.id !== 'PUBLIC_NONPROFIT' && specialist.type.id !== 'NONFINANCE'
      }).reduce(function (acc, specialist) {
        acc[specialist.type.id] = {
          name: specialist.type.name,
          ico: specialistIcoMap[specialist.type.id]
        }
        return acc
      }, {})
    }
  }
}
