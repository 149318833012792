/* global angular */

angular.module('phoneFormatterCmp', [])
  .filter('phone', function () {
    return function (input) {
      if (!input) {
        return input
      }

      var ret = input.toString().replace('.', ',')
      var pattern = /(-?\d+)(\d{3})/

      while (pattern.test(ret)) {
        ret = ret.replace(pattern, '$1 $2')
      }
      return ret
    }
  })
