/* global angular */

var userTracking = require('../services/user-tracking.provider.js').userTracking

angular.module('TrackElementCmp', [])
  .provider('userTracking', userTracking)
  .directive('trackElement', ['userTracking', function (userTracking) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs, ctrl) {
        var trackId = attrs.trackElement
        var lowerTagName = element[0].tagName.toLowerCase()

        if (!trackId) {
          console.debug('Possible untracked element: ' + element[0].tagName + ' class: ' + element[0].className)
        }

        if (lowerTagName === 'div' && hasClass(element, 'rzslider')) {
          element[0].addEventListener('mouseup', function () {
            userTracking.onSlideEnd(trackId)
          })
          element[0].addEventListener('touchend', function () {
            userTracking.onSlideEnd(trackId)
          })
        } else if (lowerTagName === 'button' && hasClass(element, 'csas-calc-controls--plus')) {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId, 'plus')
          })
        } else if (lowerTagName === 'button' && hasClass(element, 'csas-calc-controls--minus')) {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId, 'minus')
          })
        } else if (lowerTagName === 'button' && hasClass(element, 'csas-close')) {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId, 'remove')
          })
        } else if (lowerTagName === 'button') {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId)
          })
        } else if (lowerTagName === 'a' && hasClass(element, 'csas-click')) {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId)
          })
        } else if (lowerTagName === 'a') {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId)
          })
        } else if (lowerTagName === 'input' && attrs.type === 'radio') {
          element[0].addEventListener('click', function () {
            userTracking.onRadioChange(trackId)
          })
        } else if (lowerTagName === 'input' && attrs.type === 'submit') {
          element[0].addEventListener('click', function () {
            userTracking.onClick(trackId)
          })
        } else if (lowerTagName === 'input' && attrs.type !== 'radio') {
          element[0].addEventListener('change', function () {
            userTracking.onInputChange(trackId)
          })
        } else if (lowerTagName === 'number-input' || lowerTagName === 'number-input-plus-minus' || lowerTagName === 'number-input-plus-minus-slider') {
          console.debug('Untracked element')
        } else {
          console.debug('Possible untracked element: ' + element[0].tagName + ' class: ' + element[0].className)
        }
      }
    }

    function hasClass (angularElement, className) {
      return angularElement[0].className && angularElement[0].className.indexOf(className) > -1
    }
  }])
