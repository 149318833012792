var angular = require('angular')
var lodashUniq = require('lodash/uniq')

placesStateService.$inject = ['$location', '$window', 'wgService', 'appointmentsTrackingDataservice']

function placesStateService($location, $window, wgService, appointmentsTrackingDataservice) {
  var _stateMap = [{
      searchType: 'BRANCH',
      translateKey: 'BRANCHES',
      cookieType: 'BRANCH',
      cookieFlag: ['POBOCKY'],
      routeType: 'BRANCH',
      placeType: 'BRANCH'
    },
    {
      searchType: 'ATM',
      translateKey: 'ATMS',
      cookieType: 'ATM',
      cookieFlag: ['ATM'],
      routeType: 'ATM',
      placeType: 'ATM'
    },
    {
      searchType: 'DEPOSIT_ATM',
      translateKey: 'DEPOSIT_ATM',
      cookieType: null,
      cookieFlag: ['DEPOSIT_ATM'],
      routeType: null,
      placeType: 'ATM'
    },
    {
      searchType: 'TRANSFER_ATM',
      translateKey: 'TRANSFER_ATM',
      cookieType: null,
      cookieFlag: ['TRANSFER_ATM'],
      routeType: null,
      placeType: 'ATM'
    },
    {
      searchType: 'HC',
      translateKey: 'HC',
      cookieType: null,
      cookieFlag: ['HC'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'EXCHANGE',
      translateKey: 'EXCHANGE',
      cookieType: null,
      cookieFlag: ['EXCHANGE_SERVICE_OR_CURR_WITHDRAW'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'CSP',
      translateKey: 'PREMIER',
      cookieType: null,
      cookieFlag: ['CSP'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'PB',
      translateKey: 'PB',
      cookieType: null,
      cookieFlag: ['PB'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'KC',
      translateKey: 'KC',
      cookieType: null,
      cookieFlag: ['KC'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'OMC',
      translateKey: 'OMC',
      cookieType: null,
      cookieFlag: ['OMC'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'COMPANY',
      translateKey: 'COMPANY',
      cookieType: null,
      cookieFlag: ['COMPANY'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'ENCS',
      translateKey: 'BRANCHES',
      cookieType: null,
      cookieFlag: [],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'LKC',
      translateKey: 'BRANCHES',
      cookieType: null,
      cookieFlag: ['LKC'],
      routeType: null,
      placeType: 'BRANCH'
    },
    {
      searchType: 'PERSONAL_SENIOR',
      translateKey: 'BRANCHES',
      cookieType: null,
      cookieFlag: ['PERSONAL_SENIOR'],
      routeType: null,
      placeType: 'BRANCH'
    }
  ]
  var _self = {
    cookieName: 'AEM_BRANCHES_SEARCH_PARAMS',
    shownTab: 'SHOWN_TAB',
    setStateBySearchParamsType: setStateBySearchParamsType,
    setState: setState,
    getState: getState,
    setAddress: setAddress,
    getAddress: getAddress,
    getFlags: getFlags,
    setFlags: setFlags,
    validateFlags: validateFlags,
    setCurrencies: setCurrencies,
    getCurrencies: getCurrencies,
    getShownTab: getShownTab,
    setShownTab: setShownTab,
  }
  var _state = _stateMap[0]
  var _address = null
  // possible flags from places-list-filter component for branch and ATM
  var branchFlags = ['OPEN_NOW', 'WEEKEND_OFFICE', 'BARRIER_FREE_OR_BARRIER_PARTIALY_FREE', 'HEARING_IMPAIR', 'DIGITAL_VOICE', 'CASH_DESK', 'SAFE_BOX',
    'CSP', 'KC', 'OMC', 'COMPANY', 'POBOCKY', 'EXCHANGE_SERVICE_OR_CURR_WITHDRAW', 'HC', 'PB', 'VIDEOCONFERENCE', 'NONFINANCE'
  ]
  var atmFlags = ['OPEN_NOW', 'DEPOSIT_ATM', 'FOR_BLIND_ATM', 'ATM_ACCESSIBILITY', 'TRANSFER_ATM', 'BAR_CODE_READER_ATM', 'QR_CODE_READER_ATM',
    'CONTACTLESS_SYSTEM_PILSEN', 'ACTIVATION_CODE', 'CONTACTLESS_ATM'
  ]
  var typeFlags = ['HC', 'PB', 'POBOCKY', 'CSP', 'KC', 'COMPANY', 'EXCHANGE_SERVICE_OR_CURR_WITHDRAW']

  init()

  function init() {
    setCookieNameByAppName()
    if (appointmentsTrackingDataservice) {
      appointmentsTrackingDataservice.storeSourceIdAndReplaceQueryParam()
    }
    _address = guessSearchAddress()
    _state = guessState()
    updateStateFlags()
  }

  /* nastavuje parametry (flagy, typ, adresu) do URL, volá se při každém setu těchto parametrů z příslušné fce  */
  function setStateToUrl() {
    var undefFlag = _state.cookieFlag.indexOf(undefined)
    var nullFlag = _state.cookieFlag.indexOf(null)
    if (undefFlag !== -1) {
      _state.cookieFlag.splice(undefFlag, 1)
    }
    if (nullFlag !== -1) {
      _state.cookieFlag.splice(nullFlag, 1)
    }
    var flagsFromURL = $location.search().flags
    if(flagsFromURL){
   var urlParamsSetURL = (angular.isArray(flagsFromURL)  ? flagsFromURL : flagsFromURL.split())
    for (var i = 0; i < urlParamsSetURL.length; i++) {
      if(_state.cookieFlag.indexOf(urlParamsSetURL[i]) === -1){
        _state.cookieFlag.push(urlParamsSetURL[i])
      }
    }
  }
    var stateParams = {
      type: _state.searchType,
      flags: validateFlags(_state.cookieFlag),
      address: _address,
      currencies: getCurrencies(),
      tab: getShownTab()
    }

    $location.search(stateParams)
  }

  function setStateType() {
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    params.type = _state.searchType
    $window.localStorage.setItem(_self.cookieName, JSON.stringify(params))
  }

  /* validuje flagy proti setu flagů daného typu (BRANCH, ATM) */
  function validateFlags(flags) {

    var validFlags = []
    for (var i = 0; i < flags.length; i++) {
      if (_state.placeType === 'BRANCH') {
        if (branchFlags.indexOf(flags[i]) !== -1) {
          validFlags.push(flags[i])
        }
      } else if (_state.placeType === 'ATM') {
        if (atmFlags.indexOf(flags[i]) !== -1) {
          validFlags.push(flags[i])
        }
      }
    }
    var resolvedFlags = resolveFlagColision(validFlags)

    return resolvedFlags
  }

  function resolveFlagColision(flags) {
    var validFlags = []
    for (var i = 0; i < flags.length; i++) {
      if (typeFlags.indexOf(flags[i]) === -1) {
        validFlags.push(flags[i])
      }
    }
    validFlags.push(getState().cookieFlag[0])
    return lodashUniq(validFlags)
  }

  function getShownTab() {
    var params = JSON.parse($window.localStorage.getItem(_self.shownTab)) || {}
    if (params.timeStamp > (Date.now() - (1000 * 60 * 60 * 24)) && params.tab && params.tab.length > 0) {
      return params.tab
    } else {
      return []
    }
  }

  /* setuje otevřeny tab - Seznam poboček nebo Mapa */
  function setShownTab(tab) {

    var params = JSON.parse($window.localStorage.getItem(_self.shownTab)) || {}
    params.tab = tab
    params.timeStamp = Date.now()
    $window.localStorage.setItem(_self.shownTab, JSON.stringify(params))
    _state.tab = tab
    setStateToUrl()
  }

  /* bere flagy primárně z URL, když nejsou tak ze sessionStorage */
  function getFlags() {
    if(angular.isDefined($location.search().flags))
    {
      var urlParams = $location.search().flags
      urlParams = angular.isArray(urlParams) ? urlParams : urlParams.split()
      var validFlags = validateFlags(urlParams)
      return validFlags
  } else {
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    if (params.timeStamp > (Date.now() - (1000 * 60 * 60 * 24)) && params.flag && params.flag.length > 0) {
      return params.flag
    } else {
      return _state.cookieFlag || []
    }
  }
  }

  /* nastavuje flagy jak do URL tak do sessionStorage na základě vybraných položek filtru v seznamu poboček*/
  function setFlags(flags) {
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    params.flag = flags
    params.timeStamp = Date.now()
    $window.localStorage.setItem(_self.cookieName, JSON.stringify(params))
    var flagsToUrl = flags
    if(flags.length === 0){
      if (_state.placeType === 'BRANCH') {
        flagsToUrl.push('POBOCKY')
      } else if (_state.placeType === 'ATM') {
          validFlags.push('ATM')
      }
    }
    $location.search().flags = flagsToUrl
    _state.cookieFlag  = flags
    setStateToUrl()
  }

  /* nastavuje měny na základě výběru měn v sub-filtru v položce S pokladnou */
  function setCurrencies(currencies) {
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    params.currencies = currencies
    params.timeStamp = Date.now()
    $window.localStorage.setItem(_self.cookieName, JSON.stringify(params))
    _state.currencies = currencies
    setStateToUrl()
  }

  function getCurrencies() {
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    if (_state.searchType === 'BRANCH' && params.timeStamp > (Date.now() - (1000 * 60 * 60 * 24)) && params.currencies && params.currencies.length > 0) {
      return params.currencies
    } else {
      return []
    }
  }

  function getAddress() {
    return _address
  }

  function setAddress(address) {
    _address = address
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    params.address = encodeURIComponent(address)
    $window.localStorage.setItem(_self.cookieName, JSON.stringify(params))

    setStateToUrl()
  }

  function guessSearchAddress() {
    var address = null
    // by route
    if (angular.isDefined($location.search().address)) {
      address = decodeURIComponent($location.search().address)
      var storedData = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
      storedData.address = encodeURIComponent(address)
      $window.localStorage.setItem(_self.cookieName, JSON.stringify(storedData))
    }
    // by cookie
    if (!address) {
      var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
      if (angular.isDefined(params.address)) {
        address = decodeURIComponent(params.address)
      }
    }
    return address
  }

  function setCookieNameByAppName() {
    var appName = wgService.getAppName()
    if (~appName.indexOf('Ecb')) {
      _self.cookieName += '_ECB'
    }
    if (~appName.indexOf('Csps')) {
      _self.cookieName += '_CSPS'
    }
    if (~appName.indexOf('Encs')) {
      _self.cookieName += '_ENCS'
    }
  }

  function getState() {
    return _state
  }

  function setState(state) {
    _state = state
    var params = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    params.flag = state.cookieFlag
    $window.localStorage.setItem(_self.cookieName, JSON.stringify(params))
  }

  function guessState() {
    var storedState = JSON.parse($window.localStorage.getItem(_self.cookieName)) || {}
    var state = null
    if (!state && ~wgService.getAppName().indexOf('Encs')) {
      state = _stateMap.filter(function (element) {
        return element.searchType === 'ENCS'
      })[0]
    }
    // by search type
    if (!state) {
      state = _stateMap.filter(function (element) {
        return element.searchType === $location.search().type
      })[0]
    }
    // by stored type
    if (!state) {
      state = storedState.type && _stateMap.filter(function (element) {
        return element.searchType === storedState.type
      })[0]
    }
    // by inlet name
    if (!state && ~wgService.getAppName().indexOf('Ecb')) {
      state = _stateMap.filter(function (element) {
        return element.searchType === 'COMPANY'
      })[0]
    }
    // by default
    if (!state) {
      state = _stateMap[0]
    }

    setStateType()
    return state
  }

  function updateStateFlags() {
    var urlParams = $location.search().flags
    // by route flag
    if (urlParams) {
      urlParams = angular.isArray(urlParams) ? urlParams : urlParams.split()
      var validFlags = validateFlags(urlParams)
      setFlags(validFlags)
      // by stored values
    } else {
      var validStoredFlags = validateFlags(getFlags())
      setFlags(validStoredFlags)
    }
  }

  function setStateBySearchParamsType(type) {
    var state = _stateMap.filter(function (element) {
      return element.searchType === type
    })[0] || _stateMap[0]
    setState(state)
    setStateType()
    setStateToUrl()
  }

  return _self
}

exports.placesStateService = placesStateService
