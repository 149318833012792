/* global angular */

angular.module('orderCmp')
  .filter('humanizePhoneNumber', function () {
    return function (input, prefix) {
      if (!input) {
        return input
      }

      var ret = prefix + input
      var pattern = /(-?\d+)(\d{3})/

      while (pattern.test(ret)) {
        ret = ret.replace(pattern, '$1 $2')
      }
      return ret
    }
  })
