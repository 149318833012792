var angular = require('angular')
var ExpiredStorage = require('expired-storage')

appointmentsTrackingDataservice.$inject = ['$location']

function appointmentsTrackingDataservice ($location) {
  var _sessionStorage = new ExpiredStorage(window.sessionStorage)
  var _STORAGE_KEY_SOURCE_ID = 'csasBranchesAppointmentsSourceId'
  var self = {
    storeSourceIdAndReplaceQueryParam: storeSourceIdAndReplaceQueryParam,
    getSourceId: getSourceId,
    removeSessionStorage: removeSessionStorage
  }

  function storeSourceIdAndReplaceQueryParam () {
    var sourceId = $location.search().sourceId
    $location.search('sourceId', null)
    angular.isDefined(sourceId) && _sessionStorage.setItem(_STORAGE_KEY_SOURCE_ID, sourceId, 3600)
    return angular.isDefined(sourceId)
  }

  function getSourceId () {
    return _sessionStorage.getItem(_STORAGE_KEY_SOURCE_ID)
  }

  function removeSessionStorage () {
    try {
      _sessionStorage.removeItem(_STORAGE_KEY_SOURCE_ID)
    } catch (e) {}
  }

  return self
}

exports.appointmentsTrackingDataservice = appointmentsTrackingDataservice
