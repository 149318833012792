var angular = require('angular')

placesAtmsSorting.$inject = []

function placesAtmsSorting () {
    var self = {
        DAYS: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
        BRANCH_ORDERED_SERVICES: ['EQUIPMENT_12', 'ATM', 'WEEKEND_OFFICE', 'BARRIER_FREE', 'BARRIER_PARTIALY_FREE', 'EQUIPMENT_9', 'EQUIPMENT_11', 'SELF_SERVICE', 'EQUIPMENT_7', 'CASH_DESK'],
        ATM_ORDERED_SERVICES: ['WITHDRAW_ATM', 'DEPOSIT_ATM', 'TRANSFER_ATM', 'ATM_CONTACTLESS', 'BARRIER_FREE', 'FOR_BLIND_ATM', 'QR_CODE_READER_ATM', 'ACTIVATION_CODE', 'BAR_CODE_READER_ATM', 'CONTACTLESS_SYSTEM_PILSEN'],
        DEPOSIT_ATM_ORDERED_SERVICES: ['DEPOSIT_ATM', 'BARRIER_FREE', 'BARRIER_PARTIALY_FREE', 'FOR_BLIND_ATM', 'ACTIVATION_CODE'],
        TRANSFER_ATM_ORDERED_SERVICES: ['TRANSFER_ATM', 'BAR_CODE_READER_ATM', 'QR_CODE_READER_ATM', 'BARRIER_FREE', 'FOR_BLIND_ATM'],
        HC_ORDERED_SERVICES: ['BARRIER_FREE', 'BARRIER_PARTIALY_FREE'],
        EXCHANGE_ORDERED_SERVICES: ['EXCHANGE', 'Směnárenské služby', 'Vklady a výběry cizí měny z účtu', 'BARRIER_FREE', 'BARRIER_PARTIALY_FREE', 'ATM', 'EQUIPMENT_11', 'EQUIPMENT_12', 'EQUIPMENT_7'],
        CSP_ORDERED_SERVICES: ['BARRIER_FREE', 'BARRIER_PARTIALY_FREE', 'ATM', 'EQUIPMENT_11', 'EQUIPMENT_12', 'EQUIPMENT_7'],
        COMPANY_ORDERED_SERVICES: ['EQUIPMENT_12', 'ATM', 'WEEKEND_OFFICE', 'BARRIER_FREE', 'BARRIER_PARTIALY_FREE', 'EQUIPMENT_9', 'EQUIPMENT_11', 'SELF_SERVICE', 'EQUIPMENT_7'],
        ENCS_ORDERED_SERVICES: ['BARRIER_FREE', 'BARRIER_PARTIALY_FREE', 'EQUIPMENT_7', 'EQUIPMENT_9', 'EQUIPMENT_18']
    }
    return self
}

exports.placesAtmsSorting = placesAtmsSorting
