var angular = require('angular')

require('../places-branch-manager-modal/places-branch-manager-modal.component.js')

angular.module('PlacesDetailCmp')
  .component('placesBranchManager', {
    template: require('./places-branch-manager.component.tpl.html'),
    controller: PlacesBranchManagerCtrl,
    controllerAs: 'vm',
    bindings: {
      branchId: '<',
      manager: '<',
      isEcb: '<',
      isLkc: '<'
    }
  })

PlacesBranchManagerCtrl.$inject = ['placesService', 'wgService']

function PlacesBranchManagerCtrl (placesService, wgService) {
  var vm = this

  vm.$onInit = function () {
    vm.encs = wgService.getAppName() === 'AemEncsBranches'
    vm.managerPhoto = null
    vm.isOpen = null
    if (vm.manager.empId && !vm.encs) {
      placesService.branchManagerPhoto(vm.manager.empId).then(function (res) {
        if (res.status === 200) {
          vm.managerPhoto = res.data
        }
      })
    }
    if (vm.isEcb) {
      vm.isOpen = true
    } else {
      vm.isOpen = false
    }
  }
  vm.toggle = function () {
    if (!vm.isEcb) {
      vm.isOpen = !vm.isOpen
    }
  }
}
