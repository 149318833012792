widgetParamsService.$inject = ['$http', 'apiBase']

function widgetParamsService ($http, apiBase) {
  var endpointPath = apiBase + 'v1/portalComponents/aem/params'

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  return {
    get: function (widgetName) {
      return $http({
        url: endpoint(widgetName),
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        cache: true
      })
    }
  }
}

exports.widgetParamsService = widgetParamsService
