exports.en = {
  PLACES_WEEK_OPENING_HOURS: {
    DAY_NAME: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday'
    },
    BRANCH: {
      CLOSED: 'The branch is closed',
      CLOSED_NOW: 'The branch is closed now'
    },
    ATM: {
      CLOSED: 'The ATM is unavailable',
      CLOSED_NOW: 'The ATM is unavailable now'
    },
    TOOLTIP: {
      REGULAR: {
        CHANGED_OPENING_HOURS: 'On %{day}, the branch has an exceptionally changed opening time. The usual opening hours are %{intervals}.',
        CLOSED: 'On %{day}, the branch is exceptionally closed. The usual opening hours are %{intervals}.'
      },
      OUTAGE: {
        CHANGED_OPENING_HOURS: 'On %{day}, the branch will have an exceptionally changed opening time. The opening hours for this day will be %{intervals}.',
        CLOSED: 'On %{day}, the branch will be exceptionally closed.'
      },
      PREFER_REAL: {
        CHANGED_OPENING_HOURS: 'Exceptional opening hours valid only on %{day}.',
        CLOSED: '%{day} is this branch exceptionally closed.'
      }
    }
  }
}
