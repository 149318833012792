/* global angular */

require('../../filters/humanize-phone-number.filter.js')

angular.module('orderCmp')
  .component('orderFormSuccess', {
    template: require('./order-form-success.component.tpl.html'),
    controller: OrderFormSuccessCtrl,
    controllerAs: 'vm',
    bindings: {
      hash: '<',
      plannedDatetime: '<',
      phonePrefix: '<',
      phoneNumber: '<',
      email: '<',
      onCancelled: '&'
    }
  })

OrderFormSuccessCtrl.$inject = ['appLoader', 'qmaticService', '$filter', 'basePath']

function OrderFormSuccessCtrl (appLoader, qmaticService, $filter, basePath) {
  var vm = this

  vm.$onInit = function () {
    vm.yesIco = basePath + 'img/order-yes.svg'
    vm.backendError = null
    vm.cancelHash = null
    vm.cancelClick = cancelClick
    vm.humanizedPhoneNumber = $filter('humanizePhoneNumber')(vm.phoneNumber, vm.phonePrefix)
    vm.phoneStyle = {
      background: '0px center no-repeat',
      'background-image': 'url(' + basePath + 'img/order-ico-phone.svg)',
      'background-size': '50px 50px'
    }
    vm.emailStyle = {
      background: '0px center no-repeat',
      'background-image': 'url(' + basePath + 'img/order-ico-email.svg)',
      'background-size': '60px 60px'
    }
  }

  function cancelClick () {
    appLoader.show()
    qmaticService.cancel(vm.hash)
      .then(function () {
        vm.onCancelled()
      })
      .catch(function (err) {
        vm.backendError = new Error(err)
      })
      .finally(function () {
        appLoader.hide()
      })
  }
}
