var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesBranchProducts', {
    template: require('./places-branch-products.component.tpl.html'),
    controller: PlacesBranchProductsCtrl,
    controllerAs: 'vm',
    bindings: {
      products: '<'
    }
  })

PlacesBranchProductsCtrl.$inject = []

function PlacesBranchProductsCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.limit = 3
  }
}
