exports.en = {
  PLACES_WEEK_OPENING_HOURS: {
    DAY_NAME: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday'
    },
    BRANCH: {
      CLOSED: 'Closed'
    },
    SHOW_MORE: "Show more",
    SHOW_LESS: "Show less",
    TAB_BRANCH: "Branch",
    TAB_CASHDESK: "Cash desk"
  }
}
