/* global angular */

angular.module('cancelCmp', [])
  .component('cancel', {
    template: require('./cancel.component.tpl.html'),
    controller: CancelCtrl,
    controllerAs: 'vm',
    scope: true
  })

CancelCtrl.$inject = ['appLoader', 'placesService', 'qmaticService', '$filter', '$sce', 'helperService', '$q', '$window', '$timeout', '$route', '$routeParams', '$location']
function CancelCtrl (appLoader, placesService, qmaticService, $filter, $sce, helperService, $q, $window, $timeout, $route, $routeParams, $location) {
  var vm = this
  vm.back = back
  vm.cancel = cancel

  appLoader.show()

  $window.scrollTo(0, 0)

  vm.homePath = '#/'
  vm.currentUrl = '#' + $location.$$path

  qmaticService.appointment($routeParams.hash).then(function (response) {
    vm.appointment = response.data
    placesService.branchDetail(response.data.branch_id).then(function (response) {
      vm.detail = response.data
      vm.detail = placesService.processBranchObject(vm.detail)

      vm.detailUrl = placesService.getDetailUrl(vm.detail, $filter('translate')('PLACES.URL.' + vm.detail.type.toUpperCase()))
      if (!vm.detail.qmatic) {
        vm.showError = true
      }
      vm.showButton = true
      window.localStorage.setItem('LAST_BRANCH', vm.detail.id)
    })
  }, function (error) {
    if (error.status === 404) {
      vm.notFound = true
    }
  }).finally(function () {
    if (angular.isUndefined(vm.serviceId)) {
      vm.loaded = true
      appLoader.hide()
    }
  })

  function cancel () {
    appLoader.show()
    qmaticService.cancel($routeParams.hash)
      .then(function (response) {
        vm.showButton = false
      })
      .finally(function () {
        appLoader.hide()
      })
  }

  function back () {
    $location.path(vm.detailUrl)
    $window.scrollTo(0, 0)
  }
}
