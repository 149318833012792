var angular = require('angular')
require('angular-sanitize')

require('./backend-error.component.css')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en

/*
  This component shows back-end error message
 */
angular.module('BackEndErrorComp', ['ngSanitize'])
  .component('backEndError', {
    template: require('./backend-error.component.tpl.html'),
    controller: BackEndErrorCtrl,
    controllerAs: 'vm',
    bindings: {}
  })

BackEndErrorCtrl.$inject = ['i18n', '$filter', '$sce']

function BackEndErrorCtrl (i18n, $filter, $sce) {
  var vm = this

  vm.$onInit = function () {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
    vm.messageText = function () { return $sce.trustAsHtml($filter('translate')('BACKEND_ERROR.MESSAGE_TEXT')) }
  }
}
