googleMapsService.$inject = ['i18n', '$q', 'appConfig']

function googleMapsService (i18n, $q, appConfig) {
  var self = {
    initPromise: initPromise
  }

  function initPromise () {
    return $q(function (resolve, reject) {
      try {
        var element = document.createElement('script')
        element.type = 'text/javascript'
        element.async = true
        element.defer = true
        element.src = 'https://maps.googleapis.com/maps/api/js?libraries=places,geometry' +
          '&key=' + appConfig.googleMaps.apiKey + '&language=' + i18n.langCode + '&callback=canUseGoogleApis'
        document.body.appendChild(element)
      } catch (e) {
        reject()
      }

      global.canUseGoogleApis = function () {
        resolve(element)
      }
    })
  }

  return self
}

exports.googleMapsService = googleMapsService
