/* global webchat */

var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesBranchCallbox', {
    template: require('./places-branch-callbox.component.tpl.html'),
    controller: PlacesBranchCallboxCtrl,
    controllerAs: 'vm',
    bindings: {
      phones: '<',
      isEcb: '<',
      isLkc: '<',
      email: '<'
    }
  })

PlacesBranchCallboxCtrl.$inject = []

function PlacesBranchCallboxCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.isChatAvailable = isChatAvailable
    vm.showChat = showChat
  }

  function isChatAvailable () {
    return !!document.getElementById('webchat')
  }

  function showChat () {
    webchat('postBack', 'start');
  }
}
