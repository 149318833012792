/* global angular */

require('../../directives/bind-html-compile.directive.js')
require('../wcm-image/wcm-image.component.js')

angular.module('WcmHtmlContentCmp', ['bindHtmlCompileCmp', 'WcmImageCmp'])
  .component('wcmHtmlContent', {
    template: require('./wcm-html-content.component.tpl.html'),
    controller: WcmHtmlContentCtrl,
    controllerAs: 'vm',
    bindings: {
      fileContent: '<',
      wcmHrefContext: '<',
      objectContentRf: '@'
    }
  })

WcmHtmlContentCtrl.$inject = []

function WcmHtmlContentCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.content = new FileContent(vm.fileContent).getInnerBody().replaceImgs().replaceInnerHrefs().toString()
  }

  vm.$onChanges = function (changesObj) {
    if (changesObj.fileContent) {
      vm.content = new FileContent(vm.fileContent).getInnerBody().replaceImgs().replaceInnerHrefs().toString()
    }
  }

  function FileContent (fileContent) {
    this.fileContent = fileContent

    this.toString = function () {
      return this.fileContent
    }

    this.getInnerBody = function () {
      var re = /<body[^>]*>((.|[\n\r])*)<\/body>/im
      var content = this.fileContent && re.exec(this.fileContent)[1]
      return new FileContent(content)
    }

    this.replaceImgs = function () {
      var content = this.fileContent && this.fileContent.replace(/<img.*?>/g, function (imgTag) {
        var re = /src="(.*?)"/g
        var objectName = re.exec(imgTag)[1]
        return '<wcm-image portal="internet" object-name="' + objectName + '"></wcm-image>'
      })
      return new FileContent(content)
    }

    this.replaceInnerHrefs = function () {
      var content = this.fileContent && this.fileContent.replace(/href="(.*?)"/gmi, function (href, hrefContent) {
        if (!hrefContent.match(/^http/gmi) && vm.wcmHrefContext) {
          hrefContent = vm.wcmHrefContext + '?documentId=' + hrefContent
        }
        return 'href="' + hrefContent + '"'
      })
      return new FileContent(content)
    }
  }
}
