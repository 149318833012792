/* global angular */

require('aem-libs-angularjs/src/directives/track-element.directive.js')
require('../places-today-closing-in-hours/places-today-closing-in-hours.component.js')
var placesAtmsSorting = require('aem-libs-places-angularjs/src/constants/places-atms-sorting.js').placesAtmsSorting
var placesOpeningHoursService = require('../../services/places-opening-hours.service.js').placesOpeningHoursService
var format = require('date-fns/fp/format').default
var differenceInCalendarDays = require('date-fns/fp/differenceInCalendarDays').default
var parseISO = require('date-fns/fp/parseISO').default

angular.module('listItemCmp', ['TrackElementCmp', 'PlacesTodayClosingInHoursCmp'])
  .factory('placesOpeningHoursService', placesOpeningHoursService)
  .factory('placesAtmsSorting', placesAtmsSorting)
  .component('listItem', {
    template: require('./list-item.component.tpl.html'),
    controller: ListItemCtrl,
    controllerAs: 'vm',
    bindings: {
      index: '<',
      data: '<',
      widgetParams: '<'
    }
  })


ListItemCtrl.$inject = ['wgService', 'placesService', '$filter', 'placesOpeningHoursService',
  'i18n', 'appConfig', 'placesStateService', 'placesAtmsSorting']

function ListItemCtrl (wgService, placesService, $filter, placesOpeningHoursService,
  i18n, appConfig, placesStateService, placesAtmsSorting) {
  var vm = this
  vm.$onInit = function () {
    vm.ecb = wgService.getAppName() === 'AemEcbBranches'
    vm.encs = wgService.getAppName() === 'AemEncsBranches'
    vm.exchange = placesStateService.getState().searchType === 'EXCHANGE'
    vm.validCashDeskLabel = placesStateService.getState().searchType === 'BRANCH' || placesStateService.getState().searchType === 'COMPANY'
    vm.hasCashDesk = false
    vm.loaded = false
    vm.shownServices = []
    vm.cashDeskLabel = ''
    vm.url = placesService.getDetailUrl(vm.data, $filter('translate')('PLACES.URL.' + vm.data.type.toUpperCase()))
    vm.branchAppointmentsCrmUrl = null
    vm.isAppointmentsVisibile = isAppointmentsVisibile
    vm.isNewAtm = isNewAtm
    vm.georgeAppointmentUrl = vm.widgetParams && vm.widgetParams.georgeAppointmentUrl

    const filterOutagesForType = function(types) {
      return vm.data.outages.filter(function(outage) {return types.includes(outage.outageType)})
    }

    if (placesStateService.getState().searchType === 'ATM') {
      vm.data = placesService.processATMObject(vm.data)
      priorityOrdering(placesAtmsSorting.ATM_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
    } else if (placesStateService.getState().searchType === 'DEPOSIT_ATM') {
      vm.data = placesService.processATMObject(vm.data)
      priorityOrdering(placesAtmsSorting.DEPOSIT_ATM_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
    } else if (placesStateService.getState().searchType === 'TRANSFER_ATM') {
      vm.data = placesService.processATMObject(vm.data)
      priorityOrdering(placesAtmsSorting.TRANSFER_ATM_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
    } else if (placesStateService.getState().searchType === 'HC') {
      vm.data = placesService.processBranchObject(vm.data)
      vm.data.sortedServices.sort(compare)
      priorityOrdering(placesAtmsSorting.HC_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
      setBranchAppointmentsCrmUrl()
    } else if (placesStateService.getState().searchType === 'EXCHANGE') {
      vm.data = placesService.processBranchObject(vm.data)
      vm.data.sortedServices.sort(compare)
      priorityOrdering(placesAtmsSorting.EXCHANGE_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
      setBranchAppointmentsCrmUrl()
    } else if (placesStateService.getState().searchType === 'CSP') {
      vm.data = placesService.processBranchObject(vm.data)
      vm.data.sortedServices.sort(compare)
      priorityOrdering(placesAtmsSorting.CSP_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
      setBranchAppointmentsCrmUrl()
    } else if (placesStateService.getState().searchType === 'COMPANY') {
      vm.data = placesService.processBranchObject(vm.data)
      vm.data.sortedServices.sort(compare)
      priorityOrdering(placesAtmsSorting.COMPANY_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
      setBranchAppointmentsCrmUrl()
    } else if (vm.encs) {
      vm.data = placesService.processBranchObject(vm.data)
      vm.data.sortedServices.sort(compare)
      priorityOrdering(placesAtmsSorting.ENCS_ORDERED_SERVICES)
      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, vm.data.outages, vm.data.accessType)
    } else {
      vm.data = placesService.processBranchObject(vm.data)
      vm.data.sortedServices.sort(compare)
      priorityOrdering(placesAtmsSorting.BRANCH_ORDERED_SERVICES)

      vm.placesOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, filterOutagesForType(placesOpeningHoursService.outagesTypes), vm.data.accessType)
      vm.placeCashDeskOpeningHours = placesOpeningHoursService.normalize(vm.data.openingHours, filterOutagesForType(placesOpeningHoursService.outagesCashdeskTypesOnly), vm.data.accessType)
      vm.cashDeskOpeningHours = placesOpeningHoursService.normalize(vm.data.cashdesk_opening_hours, filterOutagesForType(placesOpeningHoursService.outagesCashdeskTypesOnly), vm.data.accessType)
      setBranchAppointmentsCrmUrl()

      if (vm.ecb) {
        placesService.branchPhotos(vm.data.id).then(function (response) {
          if (response.data.length) {
            placesService.branchPhoto(vm.data.id, response.data[0].id).then(function (resp) {
              vm.photo = resp.data
              vm.loaded = true
            })
          } else {
            var address = vm.data.address + ',' + vm.data.city
            var lng = vm.data.location.lng
            var lat = vm.data.location.lat
            var url = placesService.streetViewUrl(lat, lng, encodeURIComponent(address))
            placesService.signUrl(url, appConfig.googleMaps.apiKey).then(function (resp) {
              vm.photo = resp.data.signedUrl
              vm.loaded = true
            })
          }
        })
      }
    }
  }

  function setBranchAppointmentsCrmUrl () {
    var urlToBranchAppointments = vm.widgetParams && vm.widgetParams.branchAppointmentsCrmUrl && vm.widgetParams.branchAppointmentsCrmUrl[i18n.langCode]
    if (urlToBranchAppointments) {
      urlToBranchAppointments += 'branch/' + vm.data.id
    }

    vm.branchAppointmentsCrmUrl = isBranchAppointmentAvailable() && urlToBranchAppointments
  }

  function isBranchAppointmentAvailable () {
    var hasPobockyFlag = vm.data.services && vm.data.services.some(function (service) {
      return service.flag === 'POBOCKY'
    })
    return vm.data.orderToBranch && hasPobockyFlag && vm.data.marketPlaceId
  }

  function compare (a, b) {
    if (a.index < b.index) {
      return -1
    }
    if (a.index > b.index) {
      return 1
    }
    return 0
  }

  function priorityOrdering (type) {
    var activeFilter = placesStateService.getFlags()
    if (activeFilter.indexOf('DIGITAL_VOICE') !== -1) {
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(0, 0, 'EQUIPMENT_16')
    }
    if (activeFilter.indexOf('HEARING_IMPAIR') !== -1) {
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(0, 0, 'EQUIPMENT_13')
    }
    if (activeFilter.indexOf('HEARING_IMPAIR') !== -1 && activeFilter.indexOf('DIGITAL_VOICE') !== -1) {
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(0, 1, 'EQUIPMENT_16')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(1, 1, 'EQUIPMENT_13')
    }
    if (activeFilter.indexOf('BARRIER_FREE_OR_BARRIER_PARTIALY_FREE') !== -1 && activeFilter.indexOf('DIGITAL_VOICE') !== -1) {
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(0, 1, 'BARRIER_FREE')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(1, 1, 'BARRIER_PARTIALY_FREE')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(2, 1, 'EQUIPMENT_16')
    }
    if (activeFilter.indexOf('BARRIER_FREE_OR_BARRIER_PARTIALY_FREE') !== -1 && activeFilter.indexOf('HEARING_IMPAIR') !== -1) {
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(0, 1, 'BARRIER_FREE')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(1, 1, 'BARRIER_PARTIALY_FREE')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(2, 1, 'EQUIPMENT_13')
    }
    if (activeFilter.indexOf('BARRIER_FREE_OR_BARRIER_PARTIALY_FREE') !== -1 && activeFilter.indexOf('HEARING_IMPAIR') !== -1 &&
    activeFilter.indexOf('DIGITAL_VOICE') !== -1) {
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(0, 1, 'BARRIER_FREE')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(1, 1, 'BARRIER_PARTIALY_FREE')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(2, 1, 'EQUIPMENT_16')
      placesAtmsSorting.BRANCH_ORDERED_SERVICES.splice(3, 1, 'EQUIPMENT_13')
    }
    angular.forEach(vm.data.sortedServices, function (service, i) {
      var index = type.indexOf(service.flag)
      if (index !== -1) {
        service.index = index
      }

      if (service.flag !== 'CASH_DESK') {
        vm.shownServices.push(service)
      } else {
        vm.hasCashDesk = true
      }
    })
    setCashDeskLabel()
  }

  function isNewAtm() {
    if(!vm.data.installDate) {
      return false
    }

    const maxDiff = 30
    const parsedDate = parseISO(vm.data.installDate)
    const daysCount = differenceInCalendarDays(parsedDate)(new Date())

    return vm.data.type === 'ATM' && daysCount <= maxDiff
  }

  function isAppointmentsVisibile () {
    return vm.data.qmatic || vm.branchAppointmentsCrmUrl
  }

  function setCashDeskLabel () {
    if (vm.hasCashDesk) { // má pokladnu
      if (vm.data.cashUntil) { // je vyplněno, do kdy má pokladnu
        var cashUntilTime = Math.floor(new Date(vm.data.cashUntil).getTime() / (1000 * 60 * 60 * 24))
        var toDayTime = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24))
        var differenceInDays = cashUntilTime - toDayTime
        if ((cashUntilTime >= toDayTime) && (differenceInDays <= 30)) { // datum do kdy má pokladnu je v budoucnu max 30 dní
          var label = $filter('translate')('PLACES.QUICK.BRANCH.UNTIL')
          vm.cashDeskLabel = label.toUpperCase() + ' ' + format('d. M. yyyy')(new Date(vm.data.cashUntil))
        } else { // datum je v minulosti
          vm.cashDeskLabel = ''
        }
      } else { // není vyplněno do kdy má pokladnu
        vm.cashDeskLabel = ''
      }
    } else { // nemá pokladnu
      if (vm.data.cashUntil) { // je vyplněno, do kdy měla pokladnu
        var differenceInDays = (new Date().getTime() - new Date(vm.data.cashUntil).getTime()) / (1000 * 60 * 60 * 24)
        if (differenceInDays <= 14) { // datum do kdy měl pokladnu není starší než 14 dní
          vm.cashDeskLabel = $filter('translate')('PLACES.QUICK.BRANCH.NEWLY')
        } else { // datum je starší než 14 dní
          vm.cashDeskLabel = ''
        }
      } else { // není vyplněno do kdy má pokladnu
        vm.cashDeskLabel = ''
      }
    }
  }
}
