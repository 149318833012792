/* global $ lightGallery */

var angular = require('angular')
require('lightslider')
require('lightslider/dist/css/lightslider.css')
require('lightgallery.js')
require('lightgallery.js/dist/css/lightgallery.css')
require('lg-thumbnail.js')

angular.module('galleryCmp', [])
  .component('gallery', {
    template: require('./gallery.component.tpl.html'),
    controller: GalleryCtrl,
    controllerAs: 'vm',
    scope: true,
    bindings: {
      lat: '=',
      lng: '=',
      address: '@'
    }
  })
  .directive('lightSliderGallery', lightSliderGalleryDirective)

require('./gallery.css')

lightSliderGalleryDirective.$inject = []
function lightSliderGalleryDirective () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var thumbItem = 3
      if (element.parent().find('li').length === parseInt(attrs.lastindex)) {
        // ng-repeat is completed
        setTimeout(function () {
          var parentEl = element.parent()
          lightGallery(parentEl[0], {
            thumbnail: true,
            enableDrag: false
          })
          $(parentEl).lightSlider({
            gallery: true,
            pager: parseInt(attrs.lastindex) > 1,
            controls: true,
            item: 1,
            loop: false,
            slideMargin: 0,
            enableDrag: false,
            thumbItem: thumbItem,
            onSliderLoad: function (el) {
              lightGallery(el[0], {
                selector: '#' + parentEl.id + ' .lslide'
              })
            }
          })
        }, 10)
      }
    }
  }
}

GalleryCtrl.$inject = ['wgService', '$routeParams', 'placesService', '$timeout']
function GalleryCtrl (wgService, $routeParams, placesService, $timeout) {
  var vm = this
  vm.ecb = wgService.getAppName().indexOf('Ecb') > -1

  var id = $routeParams.id
  var type
  if (!vm.ecb) {
    if (vm.address) {
      vm.address = encodeURIComponent(vm.address)
    }
  }
  if ($routeParams.type === 'pobocka' || $routeParams.type === 'branch') {
    type = 'BRANCH'
  } else {
    type = 'ATM'
  }

  if (type === 'BRANCH') {
    placesService.branchPhotos(id).then(function (response) {
      vm.photos = []
      vm.count = response.data.length
      angular.forEach(response.data, function (item, index) {
        var photo = {}
        photo = item
        vm.photos[index] = photo
        placesService.branchPhoto(id, item.id).then(function (resp) {
          vm.photos[index].data = resp.data
          if (index + 1 === response.data.length) {
            $timeout(function () {
              vm.loaded = true
            }, 10)
          }
        })
      })
    })
  }
}
