var angular = require('angular')
require('../places-atm-services/places-atm-services.component.js')
require('../places-services/places-services.component.js')
require('../places-background-map/places-background-map.component.js')
require('../places-detail-map/places-detail-map.component.js')

angular.module('PlacesDetailCmp')
  .component('placesDetailAtm', {
    template: require('./places-detail-atm.component.tpl.html'),
    controller: PlacesDetailAtmCtrl,
    controllerAs: 'vm'
  })

PlacesDetailAtmCtrl.$inject = ['wgService', 'appLoader', 'placesService', '$window',
  '$routeParams', 'widgetParamsService', '$q', 'placesOpeningHoursService']

function PlacesDetailAtmCtrl (wgService, appLoader, placesService, $window,
  $routeParams, widgetParamsService, $q, placesOpeningHoursService) {
  var vm = this
  var lastBranchKey = 'LAST_BRANCH'
  var lastATMKey = 'LAST_ATM'
  if (~wgService.getAppName().indexOf('Ecb')) {
    lastBranchKey += '_ECB'
    lastATMKey += '_ECB'
  }
  if (~wgService.getAppName().indexOf('Csps')) {
    lastBranchKey += '_CSPS'
    lastATMKey = undefined
  }

  vm.$onInit = function () {
    vm.backendError = null
    vm.placeOpeningHours = null
    vm.allAtmServicesToFaqUrlMapping = []
    vm.resolved = false

    initATM()
  }

  function initATM () {
    $window.scrollTo(0, 0)
    appLoader.show()
    placesService.atmDetail($routeParams.id)
      .then(function (response) {
        vm.detail = response.data
        vm.placeOpeningHours = placesOpeningHoursService.normalize(vm.detail.openingHours, vm.detail.outages, vm.detail.accessType)
        vm.detail = placesService.processATMObject(vm.detail)
        angular.element(document.getElementsByClassName('.csas-google-mtd-area')).html(placesService.getGoogleMetadata(vm.detail))
        if (placesService.isLocalStorageEnabled()) {
          window.localStorage.removeItem(lastBranchKey)
          if (lastATMKey) {
            window.localStorage.setItem(lastATMKey, vm.detail.id)
          }
        }
        return $q.all([
          widgetParamsService.get(wgService.getAppName()),
          placesService.atmServices($routeParams.id)
        ])
      })
      .then(function (res) {
        vm.allAtmServicesToFaqUrlMapping = res[0].data.allAtmServicesToFaqUrlMapping || []
        var atmServices = res[1].data
        // this part maps URLs from dynamic property to the corresponding ATM services (if available)
        vm.detail.services = atmServices.map(function (service) {
          var mapping = vm.allAtmServicesToFaqUrlMapping.filter(function (element) {
            return service === element.label
          })[0]
          return {
            label: service,
            url: mapping && mapping.faqUrl
          }
        })
      })
      .catch(function (err) {
        vm.backendError = err
      })
      .finally(function () {
        vm.resolved = true
        appLoader.hide()
      })
  }
}
