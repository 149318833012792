var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesDetailNote', {
    template: require('./places-detail-note.component.tpl.html'),
    controller: PlacesDetailNoteCtrl,
    controllerAs: 'vm',
    bindings: {
      stringifiedNote: '<'
    }
  })

PlacesDetailNoteCtrl.$inject = ['contentService']

function PlacesDetailNoteCtrl (contentService) {
  var vm = this

  vm.$onInit = function () {
    vm.notes = (vm.stringifiedNote && JSON.parse(vm.stringifiedNote)) || []
    vm.getContent = getContent
  }

  function getContent (note) {
    var objectName = note.objectName.trim()
    if (/\.xml$/gm.test(objectName)) {
      contentService.getStaticHtml('internet', objectName).promise.then(function (res) {
        note.content = res.data
      })
    } else {
      note.content = objectName
    }
  }
}
