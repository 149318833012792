var angular = require('angular')

angular.module('PlacesDetailCmp')
  .component('placesDetailMap', {
    template: require('./places-detail-map.component.tpl.html'),
    controller: PlacesBackgroundMapCtrl,
    controllerAs: 'vm',
    bindings: {
      name: '<',
      inlineAddress: '<',
      lat: '<',
      lng: '<'
    }
  })

PlacesBackgroundMapCtrl.$inject = ['basePath']

function PlacesBackgroundMapCtrl (basePath) {
  var vm = this

  vm.$onInit = function () {
    var map = new window.google.maps.Map(document.getElementById('mapa'), {
      center: { lat: vm.lat, lng: vm.lng },
      zoom: 15,
      streetViewControl: false
    })
    var marker = new window.google.maps.Marker({
      position: { lat: vm.lat, lng: vm.lng },
      title: vm.name,
      map: map,
      icon: basePath + 'img/big_pin_red.png'
    })
    var infowindow = new window.google.maps.InfoWindow({
      content: '<div class="csas-map-popup"><address><h3><a>' + vm.name + '</a></h3>' + vm.inlineAddress + '</address></div>'
    })

    marker.addListener('click', function () {
      infowindow.open(map, marker)
    })
  }
}
