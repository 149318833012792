var angular = require('angular')
var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en
var placesStateService = require('../../services/places-state.service.js').placesStateService
var isMobile = require('aem-libs/src/isMobile')
var appointmentsTrackingDataservice = require('aem-libs-places-angularjs/src/services/appointments-tracking.dataservice').appointmentsTrackingDataservice

angular.module('PlacesListCmp', [])
  .factory('placesStateService', placesStateService)
  .factory('appointmentsTrackingDataservice', appointmentsTrackingDataservice)
  .component('placesList', {
    template: require('./places-list.component.tpl.html'),
    controller: PlacesListCtrl,
    controllerAs: 'vm',
    bindings: {
      widgetParams: '<',
      searchResults: '<',
      loading: '<',
      hideResultCount: '<',
      loadNextPage: '&',
      lng: '<',
      lat: '<'
    }
  })

require('./component.css')
require('./components/places-list-filter/places-list-filter.component.js')

PlacesListCtrl.$inject = ['wgService', '$timeout', 'placesStateService', 'i18n']
function PlacesListCtrl (wgService, $timeout, placesStateService, i18n) {
  var vm = this

  vm.$onChanges = function (searchResults) {
    vm.list = vm.searchResults.list
    vm.enableLoad = vm.searchResults.showNextPage
  }

  vm.$onInit = function () {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
    vm.showMap = placesStateService.getShownTab() === 'MAP'
    vm.setShownTab = placesStateService.setShownTab
    vm.searchType = placesStateService.getState().searchType
    vm.type = placesStateService.getState().placeType
    vm.loadMore = loadMore
    vm.enableLoad = vm.searchResults.showNextPage
    vm.flags = placesStateService.getFlags()
    vm.currencies = placesStateService.getCurrencies()
    vm.filterChanged = filterChanged
    vm.currencyChanged = currencyChanged
    vm.notFound = false
    vm.isMobile = isMobile

    vm.list = vm.searchResults.list ? vm.searchResults.list : []
    if (!vm.list.length) {
      vm.notFound = true
    }
  }

  function filterChanged (flags) {
    placesStateService.setFlags(flags)
  }

  function currencyChanged (currencies) {
    placesStateService.setCurrencies(currencies)
  }

  function loadMore () {
    if (vm.enableLoad) {
      vm.loadNextPage()
    }
  }
}
