var angular = require('angular')
require('./places-branch-specialists-mortgage.component.css')

angular.module('PlacesDetailCmp')
  .component('placesBranchSpecialistsMortgage', {
    template: require('./places-branch-specialists-mortgage.component.tpl.html'),
    controller: PlacesBranchSpecialistsMortgageCtrl,
    controllerAs: 'vm',
    bindings: {
      branchId: '<',
      specialists: '<'
    }
  })

PlacesBranchSpecialistsMortgageCtrl.$inject = []

function PlacesBranchSpecialistsMortgageCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.limit = 3
    vm.specialists = vm.specialists
      .filter(function (specialist) {
        return specialist.type.id === 'MORTGAGE'
      })
      .map(function (specialist) {
        return {
          typeIco: 'hypo',
          typeName: specialist.type.name,
          name: specialist.firstName + ' ' + specialist.lastName,
          email: specialist.email
        }
      })
  }
}
