var angular = require('angular')
var langCs = require('../../i18n/cs.js').cs
var langEn = require('../../i18n/en.js').en

angular.module('PlacesSearchCmp')
  .component('placesSearchTypes', {
    template: require('./places-search-types.component.tpl.html'),
    controller: PlacesSearchTypesCtrl,
    controllerAs: 'vm',
    bindings: {
      topTypes: '<',
      lowTypes: '<',
      type: '<',
      onSelected: '&'
    }
  })

PlacesSearchTypesCtrl.$inject = ['wgService', 'i18n', '$filter']

function PlacesSearchTypesCtrl (wgService, i18n, $filter) {
  var vm = this

  vm.$onChanges = function (changesObj) {
    if (changesObj.type) {
      onSelect(changesObj.type.currentValue)
    }
  }

  vm.$onInit = function () {
    vm.onSelect = onSelect
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
    vm.smallTopTypes = false
    vm.topTypes.forEach(function (el) {
      if ($filter('translate')('PLACES_SEARCH.TYPES.' + el).length > 25) {
        vm.smallTopTypes = true
      }
    })
  }

  function onSelect (type) {
    vm.type = type
    vm.onSelected({ type: vm.type })
  }
}
